import * as React from "react";
import { TextField } from "@mui/material";
import { useUserAware } from "src/auth/Auth";
import ProNotice from "../ProNotice";

type ParticipantsNumberFieldProps = {
    maxGolfers?: number;
    disabled: boolean;
    maxWidth: number;
    inputRef?: React.RefObject<HTMLDivElement>;
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, absMaxGolfers: number) => void;
};

export function ParticipantsNumberField(props: ParticipantsNumberFieldProps) {
    const { maxGolfers, disabled, maxWidth, inputRef, onChange } = props;
    const userAware = useUserAware();
    return <>
        <TextField
            variant="standard"
            id="course"
            disabled={disabled}
            ref={inputRef}
            label="Maximum number of participants"
            value={maxGolfers || ''}
            placeholder={String(userAware.absMaxGolfers)}
            onChange={e => onChange(e, userAware.absMaxGolfers)}
            InputLabelProps={{ shrink: true }}
            style={{ maxWidth }} />
        <div style={{ color: disabled ? 'rgba(0, 0, 0, 0.38)' : undefined, marginTop: 6, maxWidth }}>
            {!userAware.hasPro &&
                <ProNotice disabled={disabled}>
                    To create an event with more than 100 participants, upgrade to the Events Pro
                </ProNotice>}
        </div>
    </>;
}
