import * as React from 'react';
import {
    List, ListItem, ListItemButton, TextField, Typography, Divider, Checkbox, Radio, RadioGroup, MenuItem,
    FormControlLabel, Accordion, AccordionSummary, AccordionDetails, DialogContent, FormControl, DialogActions
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import ArrowDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpIcon from '@mui/icons-material/ArrowDropUp';
import SortIcon from '@mui/icons-material/ArrowDownward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { WithStyles } from '@mui/styles';
import DataTable, { TableColumn, Alignment, TableStyles } from 'react-data-table-component';
import axios, { AxiosResponse } from 'axios';
import { CSVLink } from 'react-csv';
import * as Backend from '../util/firebase';
import { EVENT_DATETIME_FORMAT_DLG, MONTH_DATE_FORMAT, TEST_SERVER, Urls } from '../util/config';
import { showProgress } from '../redux/ReduxConfig';
import AppButton from '../common/components/AppButton';
import ButtonBar from '../common/components/ButtonBar';
import { UserAware, WithUserAware, useUserAware } from '../auth/Auth';
import { pushUrl } from '../redux/ReduxConfig';
import { useAppStyles, styles } from '../styles';
import * as Utils from '../util/utility';
import { processEnterKey } from '../util/react_utils';
import { Blue, Container, Green, Grey, Item, Red, Spacing, TitledLink } from '../common/Misc';
import { showAlert } from '../redux/ReduxConfig';
import { EventLog, Event, EventStats, UserStats, Announcement, EventsUser, EventType, friendlyEventType } from '../types/EventTypes';
import ChangelogDetailsDialog from '../event/tabs/tools/ChangelogDetailsDialog';
import RecentCoursesSelectionDialog from '../event/tabs/settings/general/course/RecentCoursesSelectionDialog';
import { DateRangeStatisticsInfo, RangeStatisticsContents } from './DateRangeStatisticsDialog';
import { errMsg, setWithMergePromise, toArray } from '../util/firebase';
import { BkCloseButton } from 'src/common/Icons';
import { XSMobileDialog } from '../common/dialog/MobileDialog';
import MaterialDate from '../common/MaterialDate';
import DialogAppBar from '../common/dialog/DialogAppBar';
import { AppColors } from '../main/Theme';
import RichTextQuill from 'src/common/form/richtext/RichTextQuill';
import AnnouncementAlert from 'src/main/AnnouncementAlert';
import { Navigate, Route, Routes } from 'react-router-dom';
import { tabName } from 'src/event/tabs/common/LinkTabs';
import { BQ_QUERIES, QueryParam, getQuery } from './Queries';
import { formatDateUniversal } from 'src/event/Event';
import { withProgress } from 'src/util/ProgressPromise';

const USERS_PER_PAGE = 50;
const EVENTS_PER_PAGE = 250;
const USERS_PER_REQUEST = 1000;

const TEXT_FIELD_SIZE = 'small';
const TEXT_FIELD_VARIANT = 'filled';

export type SupMode = 'users' | 'events' | 'contacts' | 'logs' | 'active_admins' | 'fbquery' | 'bigquery' | 'announcement';
export const SupModes: Array<SupMode> = ['users', 'events', 'contacts', 'logs', 'active_admins', 'fbquery', 'bigquery', 'announcement'];
export const SupModeLabels = ['Users', 'Events', 'Contacts', 'Change logs', 'Active Admins', 'FirebaseQuery', 'BigQuery', 'Announcement'];

const collGroup = true;

const FB_COLLECTIONS = [
    { name: 'events' },
    { name: 'event-competitions' },
    { name: 'competitions', collGroup },
    { name: 'event-golfers' },
    { name: 'contacts', collGroup },
    { name: 'groups', collGroup },
    { name: 'event-logs' },
    { name: 'logs', collGroup },
    { name: 'event-mapping' },
    { name: 'event-payments' },
    { name: 'event-scores' },
    { name: 'scores', collGroup },
    { name: 'event-stats' },
    { name: 'images' },
    { name: 'portals' },
    { name: 'reported-scores' },
    { name: 'user-invites' },
    { name: 'user-stats' },
    { name: 'users' },
    { name: 'users-pub' },
];

interface Dataset {
    id: string;
}

export const ExpandedComponent = ({ data }: { data: any }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

enum PageChangedType {
    NEXT,
    PREVIOUS,
    FIRST,
    LAST
}

enum PageState {
    FIRST,
    LAST,
    BOTH
}

type EventsListPageState = {
    pageState: PageState,
    pageOffset: number,
    maxOffset?: number
};

class DataRow extends Array<string> {
    static readonly fromObj = (obj: any, props: Array<string>): DataRow => {
        return props.map(prop => toCell(obj[prop]));
    }
}

class DataAndHeaders {
    public headers = new DataRow;
    public data = new Array<DataRow>;
};

const cellStyle = {
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    borderRightColor: '#d3d3d3'
};

export const dataTableStyle = {
    header: {
        style: {
            minHeight: 40
        },
    },
    headRow: {
        style: {
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            borderColor: '#a9a9a9'
        },
    },
    headCells: {
        style: {
            '&:not(:last-of-type)': cellStyle,
            paddingLeft: 6,
            paddingRight: 6
        },
    },
    cells: {
        style: {
            '&:not(:last-of-type)': cellStyle,
            fontFamily: 'roboto',
            alignItems: 'center',
            paddingLeft: 6,
            paddingRight: 6
        },
    },
} as TableStyles;

const eventTableColumns: TableColumn<EventData>[] = [
    {
        name: 'Event date',
        right: true,
        width: '110px',
        sortable: true,
        selector: row => Utils.formatDateDashed1(row.date),
        sortFunction: (a, b) => a.date - b.date
    },
    {
        name: <>Last<br /> update</>,
        right: true,
        width: '110px',
        sortable: true,
        selector: row => Utils.formatDateDashed1(row.lastModified ?? 0),
        sortFunction: (a, b) => (a.lastModified ?? 0) - (b.lastModified ?? 0)
    },
    {
        name: 'Name',
        sortable: true,
        selector: row => row.name,
        sortFunction: (a, b) => a.name.localeCompare(b.name)
    },
    {
        name: 'Type',
        width: '120px',
        sortable: true,
        selector: row => row.type,
        format: row => friendlyEventType(row.type),
        sortFunction: (a, b) => a.type.localeCompare(b.type)
    },
    {
        name: 'UID',
        width: '200px',
        sortable: false,
        selector: row => row.userId
    },
    {
        name: 'E-mail',
        sortable: true,
        cell: row => row.userEmail ?? ''
    },
    {
        name: 'Event ID',
        sortable: false,
        width: '80px',
        cell: row => <TitledLink onClick={() => openEventById(row.eventId)}>{row.eventId}</TitledLink>
    },
    {
        name: 'Event site',
        sortable: false,
        width: '80px',
        cell: row => <TitledLink href={row.eventPortal} target="_blank">Event site</TitledLink>
    },
    {
        name: <># invites</>,
        center: true,
        width: '80px',
        wrap: true,
        sortable: true,
        selector: row => row.invites,
        sortFunction: (a, b) => a.invites - b.invites
    },
    {
        name: <># golfers/teams</>,
        selector: row => row.participants,
        center: true,
        width: '80px',
        wrap: true,
        sortable: true,
        sortFunction: (a, b) => a.participants - b.participants
    },
    {
        name: <># scores</>,
        center: true,
        width: '80px',
        wrap: true,
        sortable: true,
        selector: row => row.scores,
        sortFunction: (a, b) => a.scores - b.scores
    },
    {
        name: <># app scores</>,
        center: true,
        width: '80px',
        wrap: true,
        sortable: true,
        selector: row => row.appScores,
        sortFunction: (a, b) => a.appScores - b.appScores
    }
];

function toCell(f: any): string {
    return Utils.objProps(f ?? '', 1, 100, true);
}

async function findEventPub(pubEventId: string) {
    if (!pubEventId) {
        return Promise.reject('Event ID not specified!');
    }
    try {
        const doc = await Backend.getDocument(Backend.eventMappingDb, pubEventId.trim());
        if (doc.exists() && doc.data()!.eventId) {
            return Promise.resolve(doc.data()!.eventId);
        } else {
            return Promise.reject(`Event ${pubEventId} not found!`);
        }
    }
    catch (_err) {
        return Promise.reject(`Failed to find Event by pub ID ${pubEventId}!`);
    }
}

function openEventById(publicEventId: string) {
    if (publicEventId) {
        findEventPub(publicEventId)
            .then(findEventPri)
            .catch(_err => findEventPri(publicEventId));
    }
}

async function findEventPri(eventId: string) {
    if (!eventId) {
        return;
    }
    try {
        const doc = await Backend.getDoc(Backend.eventFields(eventId));
        if (doc.data()) {
            localStorage.setItem('setEID', doc.data()!.userId);
            window.open(`${window.location.origin}/events/${eventId}`, '_blank');
        } else {
            alert(`Event not found: ${eventId}`);
        }
    } catch (err: any) {
        alert(`Failed to find event: ${eventId} - ${err.message}`);
    }
}

function getDataAndHeaders(entities: Array<any>): DataAndHeaders {
    const set = new Set<string>();
    entities.forEach(ent => Object.keys(ent).forEach(p => {
        if (p !== 'id') {
            set.add(p);
        }
    }));
    const hasId = entities.length > 0 && Boolean(entities[0].id);
    const headers = (hasId ? ['id'] : []).concat(Array.from(set));
    const data = entities.map(ent => DataRow.fromObj(ent, headers));
    return {
        headers,
        data
    };
}

function getColumns(entities: Array<any>, onClick?: (p: string, v: any) => void): Array<TableColumn<any>> {
    const set = new Set<string>();
    entities.forEach(ent => Object.keys(ent).forEach(p => {
        if (p !== 'id') {
            set.add(p);
        }
    }));
    const clickableCell = (p: string, row: any) => onClick ?
        <span style={{ cursor: 'pointer' }} onClick={e => {
            e.preventDefault();
            onClick(p, row[p]);
        }}>
            {toCell(row[p])}
        </span> : toCell(row[p]);
    const hasId = entities.length > 0 && Boolean(entities[0].id);
    const res = hasId ? [{
        name: 'id',
        cell: row => clickableCell('id', row)
    } as TableColumn<any>] : [];
    return res.concat(Array.from(set).map(p => {
        return {
            name: p,
            sortable: true,
            cell: row => clickableCell(p, row)
        }
    }));
}

type UserStatusDialogProps = {
    user: EventsUser;
    userAware: UserAware;
    onClose: () => void;
    onGrantChanged: (grant: number) => void;
};

function SelectGrantDateDialog(props: UserStatusDialogProps) {
    const { user, onClose, onGrantChanged } = props;
    const [grantDate, setGrantDate] = React.useState(new Date(user.grant || Utils.getUserDateNextMonth(1)));
    async function onGrant() {
        console.log(`Setting grant data: ${grantDate.getTime()} - ${formatDateUniversal(grantDate.getTime(), MONTH_DATE_FORMAT)}`);
        await withProgress(setWithMergePromise(Backend.accessDb, { grant: grantDate.getTime() }, user.uid));
        onGrantChanged(grantDate.getTime());
    }
    return (
        <XSMobileDialog open fullWidth maxWidth="xs">
            <DialogAppBar label="Grant end date" close={onClose} />
            <DialogContent>
                <MaterialDate
                    icon
                    autoFocus
                    disablePast
                    enableUnderline
                    popperPlacement="bottom"
                    value={grantDate.getTime()}
                    onChange={setGrantDate}
                    label="Select grant end date"
                    format={EVENT_DATETIME_FORMAT_DLG}
                    style={{ width: '100%', maxWidth: 320 }}
                />
            </DialogContent>
            <DialogActions>
                <AppButton onClick={onClose} color="info">Cancel</AppButton>
                <AppButton onClick={onGrant} color="primary">Grant</AppButton>
            </DialogActions>
        </XSMobileDialog>
    );
}

function UserStatusDialog(props: UserStatusDialogProps) {
    const { user, userAware, onClose, onGrantChanged } = props;
    const [showGrantDialog, setShowGrantDialog] = React.useState(false);
    const cusLnk = user.customer ?
        `https://dashboard.stripe.com/${TEST_SERVER ? 'test/' : ''}customers/${user.customer}` : '';
    const onRevokePro = async () => {
        showAlert(`Confirm to REVOKE PRO for ${user.uid} ${user.email ?? ''} ?`, [{
            title: 'Cancel'
        }, {
            title: 'OK', color: 'secondary', action: async () => {
                await setWithMergePromise(Backend.accessDb, { grant: 0 }, user.uid);
                onGrantChanged(0);
            }
        }]);
    };
    const onSyncPro = async () => {
        const hideProgress = showProgress('UserList handleLoad');
        try {
            await axios.post(Urls.handleEvent, { id: '0', userId: user.uid, type: 'client.test' });
            hideProgress();
        } catch (err) {
            hideProgress('Failed to sync: ' + Utils.getServerErrorMessage(err));
        }
    }
    function openUser() {
        showAlert(`Confirm to OPEN ${user.uid} ${user.email ?? ''} ?`, [{
            title: 'Cancel'
        }, {
            title: 'OK', color: 'secondary', action: () => {
                onClose();
                userAware.setEffectiveUserId(user.uid);
                pushUrl('/events');
            }
        }]);
    }
    function LabelValue({ label, children }: { label: string, children?: React.ReactNode }) {
        return (
            <Typography>
                {label}: <span style={{ fontWeight: 600 }}>{children}</span>
            </Typography>
        )
    }
    return (
        <XSMobileDialog open fullWidth maxWidth="xs">
            <DialogAppBar label="User Summary" close={onClose} />
            <DialogContent>
                <FormControl>
                    <LabelValue label="User ID">
                        {user.uid}
                    </LabelValue>
                    <LabelValue label="Name">
                        {user.name}
                    </LabelValue>
                    <LabelValue label="E-mail">
                        {user.email}
                    </LabelValue>
                    <span>
                        <AppButton color="info" onClick={openUser}>Open User as SUP</AppButton>
                    </span>
                </FormControl>
                <Spacing />
                <Divider />
                <Spacing height={8} />
                <Typography variant='h6'>Subscription information:</Typography>
                <FormControl>
                    <LabelValue label='Status'>
                        {user.status}
                    </LabelValue>
                    <LabelValue label='End date'>
                        {user.exp ? formatDateUniversal(user.exp, MONTH_DATE_FORMAT) : 'N/A'}
                    </LabelValue>
                    {cusLnk && <LabelValue label='Stripe customer'>
                        <a href={cusLnk} target='_blank' style={{ color: AppColors.webBlue400 }}>{user.customer}</a>
                    </LabelValue>}
                    <br />
                    <span>
                        <AppButton color="info" onClick={onSyncPro}>Sync</AppButton>
                    </span>
                </FormControl>
                <Spacing />
                <Divider />
                <Spacing height={8} />
                <Typography variant='h6'>Grant information:</Typography>
                <FormControl>
                    <LabelValue label='End date'>
                        {user.grant && user.grant > 0 ? formatDateUniversal(user.grant, MONTH_DATE_FORMAT) : 'N/A'}
                        {user.grant && user.grant < Date.now() ? <Red> (EXPIRED)</Red> : ''}
                    </LabelValue>
                    <Spacing height={8} />
                    <span>
                        <AppButton color="info" onClick={() => setShowGrantDialog(true)}>Select date and grant Pro...</AppButton>
                        &nbsp;&nbsp;&nbsp;
                        <AppButton color="info" onClick={onRevokePro} disabled={!user.grant}>Revoke grant</AppButton>
                    </span>
                </FormControl>
                <DialogActions>
                    <AppButton onClick={() => onClose()}>Close</AppButton>
                </DialogActions>
            </DialogContent>
            {showGrantDialog && <SelectGrantDateDialog
                user={user}
                userAware={userAware}
                onClose={() => setShowGrantDialog(false)}
                onGrantChanged={grant => {
                    setShowGrantDialog(false)
                    onGrantChanged(grant);
                }}
            />}
        </XSMobileDialog>
    );
};

type UserItemHeaderProps = {
    sortCol: string;
    onColumnSort: (col: keyof EventsUser) => void;
    asc: boolean;
    sortDisabled: boolean;
};

const UserItemHeader = (props: UserItemHeaderProps) => {
    const { sortCol, asc, sortDisabled } = props;
    const classes = useAppStyles();
    const icon = sortDisabled ? '' : asc ? <ArrowDownIcon /> : <ArrowUpIcon />;
    return <>
        <ListItem className={classes.listItemHeaderWhite + ' ' + classes.clickable}>
            <Container>
                <Item onClick={() => { if (!sortDisabled) { props.onColumnSort('uid'); } }} xs={2}>User UID {sortCol === 'uid' ? icon : ''}</Item>
                <Item onClick={() => { if (!sortDisabled) { props.onColumnSort('email'); } }} xs={2}>E-mail {sortCol === 'email' ? icon : ''}</Item>
                <Item xs={1}>Total Events</Item>
                <Item xs={1}>Upcoming Events</Item>
                <Item onClick={() => { if (!sortDisabled) { props.onColumnSort('creationTime'); } }} xs={1}>Created {sortCol === 'creationTime' ? icon : ''}</Item>
                <Item onClick={() => { if (!sortDisabled) { props.onColumnSort('lastSignInTime'); } }} xs={1}>Signed In {sortCol === 'lastSignInTime' ? icon : ''}</Item>
                <Item xs={2}>Pro status {sortCol === 'exp' ? icon : ''}</Item>
                <Item xs={2}>Courses / Events</Item>
            </Container>
        </ListItem>
        <Divider />
        <Divider />
    </>;
};

type UserItemProps = {
    user: EventsUser;
    userAware: UserAware;
    handleOpenEventsList: (user: EventsUser) => void;
    onGrantChanged: (grant: number) => void;
};

const UserItem = (props: UserItemProps) => {
    const { user, userAware, handleOpenEventsList, onGrantChanged } = props;
    const [showUserStatusDialog, setShowUserStatusDialog] = React.useState(false);
    const [showRecentCourses, setShowRecentCourses] = React.useState(false);
    const [showSavedCourses, setShowSavedCourses] = React.useState(false);
    const classes = useAppStyles();
    const now = Date.now();
    const upcomingEventsAmount = user.eventsDates?.filter(date => date > now).length ?? 0;
    const onClick = () => setShowUserStatusDialog(true);
    return <>
        <ListItemButton>
            <Container>
                <Item onClick={onClick} xs={2}><PersonIcon className={classes.textIcon} />{user.uid}</Item>
                <Item onClick={onClick} xs={2}>{user.email ?? 'n/a'}</Item>
                <Item onClick={onClick} xs={1}>{user.eventsDates?.length ?? 0}</Item>
                <Item onClick={onClick} xs={1}>{upcomingEventsAmount}</Item>
                <Item onClick={onClick} xs={1}>{Utils.formatDateDashed1(user.creationTime)}</Item>
                <Item onClick={onClick} xs={1}>{Utils.formatDateDashed1(user.lastSignInTime)}</Item>
                <Item onClick={onClick} xs={2}>
                    {Boolean(user.exp && user.exp > now) && <Green>S {Utils.formatDateDashed1(user.exp!)} &nbsp;</Green>}
                    {Boolean(user.grant && user.grant > now) && <Blue>G {Utils.formatDateDashed1(user.grant!)} &nbsp;</Blue>}
                    {Boolean(user.exp && user.exp <= now) && <Grey>S {Utils.formatDateDashed1(user.exp!)} &nbsp;</Grey>}
                    {Boolean(user.grant && user.grant <= now) && <Grey>G {Utils.formatDateDashed1(user.grant!)} &nbsp;</Grey>}
                    {Boolean(user.sup) && <Red>SUP</Red>}
                </Item>
                <Item xs={2}>
                    <TitledLink title="Recent courses used by user" onClick={() => setShowRecentCourses(true)}>Recent</TitledLink> &nbsp;
                    <TitledLink title="Saved courses modified by user" onClick={() => setShowSavedCourses(true)}>Saved</TitledLink> &nbsp;
                    <TitledLink title="Events list" onClick={() => handleOpenEventsList(user)}>Events</TitledLink>
                </Item>
            </Container>
        </ListItemButton>
        <Divider />
        {showUserStatusDialog && <UserStatusDialog
            user={user}
            userAware={userAware}
            onClose={() => setShowUserStatusDialog(false)}
            onGrantChanged={onGrantChanged}
        />}
        {showRecentCourses && <RecentCoursesSelectionDialog
            userId={user.uid}
            title="Recent courses for"
            titleHint={user.email ?? user.uid}
            allowDelete={true}
            handleClose={() => setShowRecentCourses(false)}
            handleFacilitySelect={() => { }}
        />}
        {showSavedCourses && <RecentCoursesSelectionDialog
            userId={user.uid}
            title="Saved courses for"
            titleHint={user.email ?? user.uid}
            allowDelete={true}
            savedCourses={true}
            handleClose={() => setShowSavedCourses(false)}
            handleFacilitySelect={() => { }}
        />}
    </>;
};

const LogItemHeader = () => {
    const classes = useAppStyles();
    return <>
        <ListItem className={classes.listItemHeaderWhite + ' ' + classes.clickable}>
            <Container>
                <Item xs={2} noWrap variant="body2">Date/Time</Item>
                <Item xs={1} noWrap variant="body2">Event ID</Item>
                <Item xs={2} noWrap variant="body2">Source</Item>
                <Item xs={2} noWrap variant="body2">Action</Item>
                <Item xs={5} noWrap variant="body2">Details</Item>
            </Container>
        </ListItem>
        <Divider />
        <Divider />
    </>;
};

type LogItemProps = { log: EventLog, clickHandler: (log: EventLog) => void };

const LogItem = (props: LogItemProps) => {
    const { log } = props;
    return <>
        <ListItemButton onClick={() => props.clickHandler(log)}>
            <Container wrap="nowrap">
                <Item xs={2} noWrap variant="body2">{Utils.formatDateTime(log.datetime)}</Item>
                <Item xs={1} noWrap variant="body2">{log.eventId}</Item>
                <Item xs={2} noWrap variant="body2">{log.source}</Item>
                <Item xs={2} noWrap variant="body2">{log.action}</Item>
                <Item xs={5} noWrap variant="body2">{log.details}</Item>
            </Container>
        </ListItemButton>
        <Divider />
    </>;
};

type EventData = {
    date: number;
    name: string;
    type: EventType;
    userId: string;
    userEmail?: string;
    lastModified?: number;
    eventId: string;
    eventPortal: string;
    scores: number;
    appScores: number;
    participants: number;
    invites: number;
};

export type EventUserInfo = {
    userId: string;
    userName?: string;
    userEmail?: string;
    legitEventsCount: number;
    createdEventsCount: number;
};

type Props = WithStyles<typeof styles> & WithUserAware;

interface State {
    curPage: number;
    users: Array<EventsUser>;
    events: Array<Event>;
    logs: Array<EventLog>;
    logEntry?: EventLog;
    lastUser?: EventsUser;
    eventId: string;
    eventName: string;
    emailOrUid: string;
    from?: string;
    to?: string;
    sortCol: string;
    asc: boolean;
    proOnly?: boolean;
    eventStats?: boolean;
    exportToCSV?: boolean;
    pageIndex: number;
    pageQueries: Map<number, Backend.Query>;
    pageTimeStamps: Map<number, number>;
    eventsUser?: EventsUser;
    eventsListPageState: EventsListPageState;
    includeMultidays: boolean;
    includeSingledays: boolean;
    includeLeaderboards: boolean;
    dateRangeStatisticsInfo?: DateRangeStatisticsInfo;
    firebaseCollection: string;
    firebaseEntities: Array<Backend.Entity>;
    firebaseColumns: Array<any>;
    firebaseError?: string;
    querySelection: string;
    queryLimit: number;
    queryDataset?: string;
    queryDatasets: Array<Dataset>;
    queryDatasetsStatus?: string;
    queryDateField?: string;
    queryRecords: Array<any>;
    queryColumns: Array<any>;
    queryError?: string;
    queryTime?: number;
    expanded?: boolean;
    customQuery?: string;
    selectedFields: Array<QueryParam>;
    mainAnnouncementText: string;
    allUsersCount: number;
    userStats: Map<string, UserStats>;
    eventAdminEmailsSet: Set<string>;
    llOnlyAdminEmailsSet: Set<string>;
    noEventsOrLLsEmailsSet: Set<string>;
}

const ITEMS_PER_PAGE = 100;

class UserList extends React.Component<Props, State> {

    private unsubscribe: () => void;

    private tryUnsubscribe = () => {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            curPage: 0,
            users: [],
            events: [],
            logs: [],
            eventId: '',
            eventName: '',
            emailOrUid: '',
            sortCol: 'lastSignInTime',
            asc: false,
            includeMultidays: true,
            includeSingledays: true,
            includeLeaderboards: false,
            pageIndex: 0,
            pageQueries: new Map<number, Backend.Query>(),
            pageTimeStamps: new Map<number, number>([[0, 0]]),
            eventsListPageState: {
                pageState: PageState.FIRST,
                pageOffset: 0
            },
            queryDatasets: [],
            firebaseCollection: 'events',
            querySelection: BQ_QUERIES[0].name,
            queryLimit: 100,
            firebaseEntities: [],
            firebaseColumns: [],
            queryRecords: [],
            queryColumns: [],
            selectedFields: [],
            mainAnnouncementText: '',
            allUsersCount: 0,
            userStats: new Map(),
            eventAdminEmailsSet: new Set(),
            llOnlyAdminEmailsSet: new Set(),
            noEventsOrLLsEmailsSet: new Set()
        };
        this.unsubscribe = () => { };
    }

    componentDidMount() {
        this.loadAnnouncement();
    }

    componentWillUnmount() {
        this.tryUnsubscribe();
    }

    private handleLoadStart = () => this.loadUsers();
    private handleUnsup = () => this.props.userAware.setEffectiveUserId(undefined);
    private handleExportToCSV = () => this.setState({ exportToCSV: true });

    private openEvent() {
        openEventById(this.state.eventId);
    }

    private onUsers = (users: Array<EventsUser>, curPage: number, sortCol?: string, asc?: boolean) => {
        console.log(`onUsers`, users);
        const lastUser = users.length > 0 ? users[users.length - 1] : undefined;
        this.setState({ users, lastUser, curPage, sortCol: sortCol ?? this.state.sortCol, asc: asc ?? this.state.asc });
    }

    private onLogs = (logs: Array<EventLog>) => {
        this.setState({ logs });
    }

    private getDataSortedByColumn = (sortCol: keyof EventsUser) => {
        let { asc } = this.state;
        asc = sortCol === this.state.sortCol ? !asc : asc;
        this.loadUsers(undefined, sortCol, asc);
    }

    private handleOpenEvent = () => {
        const { logEntry } = this.state;
        if (logEntry) {
            openEventById(logEntry.eventId);
        }
    }

    private handleLoadNext = () => {
        const { lastUser, sortCol, asc } = this.state;
        this.loadUsers(lastUser?.uid, sortCol, asc);
    }

    private loadUsers = async (next?: string, sortColumn?: string, ascending?: boolean) => {
        if (!Backend.firebaseAuth.currentUser) {
            return;
        }
        this.tryUnsubscribe();
        const { proOnly, eventStats } = this.state;
        const hideProgress = showProgress('UserList handleLoad');
        try {
            const token = await Backend.firebaseAuth.currentUser.getIdToken(false);
            const sortCol = sortColumn ?? this.state.sortCol;
            const asc = ascending ?? this.state.asc;
            const count = USERS_PER_PAGE;
            const res = await axios.post(Urls.listUsers, { token, next, count, sortCol, asc, proOnly, eventStats }, { timeout: 600000 });
            hideProgress('List users OK');
            this.onUsers(res.data ?? [], next ? this.state.curPage + 1 : 0, sortCol, asc);
        } catch (err) {
            hideProgress('Failed to list users: ' + Utils.getServerErrorMessage(err));
            this.onUsers([], 0);
        }
    }

    private findUser = () => {
        const { emailOrUid } = this.state;
        this.findUserByEmailOrUid(res => this.onUsers([res.data], 0), emailOrUid);
    }

    private findMe = () => {
        const { userAware } = this.props;
        this.setState({ emailOrUid: userAware.user?.uid ?? '' });
        this.findUserByEmailOrUid(res => this.onUsers([res.data], 0), userAware.user?.uid);
    }

    private handleOpenChangelog = () => {
        this.tryUnsubscribe();
        const query = Backend.query(Backend.adminLogsDb(), Backend.orderBy('datetime', 'desc'), Backend.limit(ITEMS_PER_PAGE));
        const hideProgress = showProgress('UserList handleOpenChangelog');
        this.unsubscribe = Backend.onSnapshot(query, data => setTimeout(() => {
            hideProgress();
            const dataArray = Backend.toArray<EventLog>(data);
            this.onLogs(dataArray);
        }));
        this.state.pageQueries.set(0, query);
    }

    private getEventsQuery = (user?: EventsUser, pageType?: PageChangedType) => {
        const { events, includeMultidays, includeSingledays, includeLeaderboards } = this.state;
        let query: Backend.Query = Backend.eventsDb;
        if (user?.uid) {
            query = Backend.query(query, Backend.where('userId', '==', user.uid));
        }
        const types: Array<EventType> = [];
        if (includeMultidays) {
            types.push('multiday');
        }
        if (includeSingledays) {
            types.push('tournament');
        }
        if (includeLeaderboards) {
            types.push('leaderboard');
        }
        if (types.length === 0) {
            return undefined;
        }
        query = Backend.query(query, Backend.where('type', 'in', types));
        query = Backend.query(query, Backend.orderBy('date', 'desc'));
        if ((pageType === PageChangedType.NEXT || pageType === PageChangedType.PREVIOUS) && events.length > 0) {
            if (pageType === PageChangedType.NEXT) {
                query = Backend.query(query, Backend.startAfter(events[events.length - 1].date));
            } else if (pageType === PageChangedType.PREVIOUS) {
                return Backend.query(query, Backend.endBefore(events[0].date), Backend.limitToLast(EVENTS_PER_PAGE));
            }
        }
        if (pageType === PageChangedType.LAST) {
            return Backend.query(query, Backend.limitToLast(EVENTS_PER_PAGE));
        }
        return Backend.query(query, Backend.limit(EVENTS_PER_PAGE));
    }

    private listEvents = (eventsUser?: EventsUser, pageType?: PageChangedType) => {
        this.setMode('events');
        this.tryUnsubscribe();
        const eventQuery = this.getEventsQuery(eventsUser, pageType);
        if (!eventQuery) {
            return;
        }
        this.unsubscribe = Backend.onSnapshot(eventQuery, data => {
            const dataArray = Backend.toArray<Event>(data);
            const hideProgress = showProgress('UserList handleOpenEventsList');
            const { events, eventsListPageState } = this.state;
            if (dataArray.length === 0 && events && events.length > 0 && eventsUser === this.state.eventsUser) {
                hideProgress('No data to display');
                eventsListPageState.maxOffset = eventsListPageState.maxOffset == null ? eventsListPageState.pageOffset : eventsListPageState.maxOffset;
                eventsListPageState.pageState = pageType === PageChangedType.PREVIOUS ? PageState.FIRST : PageState.LAST;
                eventsListPageState.pageOffset = 0;
                this.setState({ eventsListPageState });
            } else if (dataArray.length < EVENTS_PER_PAGE) {
                hideProgress('Events: ' + dataArray.length);
                this.setState({
                    eventsListPageState: {
                        pageState: pageType == null ? PageState.BOTH :
                            (pageType === PageChangedType.PREVIOUS || pageType === PageChangedType.FIRST) ? PageState.FIRST : PageState.LAST,
                        maxOffset: eventsListPageState.maxOffset == null ? eventsListPageState.pageOffset : eventsListPageState.maxOffset, pageOffset: 0
                    },
                    events: dataArray,
                    eventsUser: eventsUser
                });
            } else {
                hideProgress('Events: ' + dataArray.length);
                if (pageType == null) {
                    this.setState({ events: dataArray, eventsUser, eventsListPageState: { pageState: PageState.FIRST, pageOffset: 0 } });
                } else {
                    this.setState({ events: dataArray, eventsUser });
                }
            }
        }, console.log, console.log);
    }

    private async findUserByEmailOrUid(actionAfterResponse: (res: AxiosResponse) => void, emailOrUid?: string) {
        const emailOrUserId = emailOrUid?.trim();
        if (!emailOrUserId || !Backend.firebaseAuth.currentUser) {
            return;
        }
        this.tryUnsubscribe();
        const hideProgress = showProgress('UserList findUserByEmailOrUid');
        const findByUid = emailOrUserId.startsWith('W-') && !Utils.emailFormat.test(emailOrUserId);
        try {
            const token = await Backend.firebaseAuth.currentUser.getIdToken(false);
            const result = await (findByUid ?
                axios.post(Urls.findUserById, { token, userId: emailOrUserId, fullInfo: true }) :
                axios.post(Urls.findUser, { token, email: emailOrUserId })
            );
            console.log('result', result.data);
            const { email, uid } = result.data;
            hideProgress(`Found user: ${uid} - ${email ?? 'n/a'}`);
            actionAfterResponse(result);
        } catch (err: any) {
            const errStr = typeof err.response?.data === 'string' ? err.response.data : err.message;
            hideProgress(`Failed to find user for ${emailOrUserId}: ${errStr}`);
        }
    }

    private findEventByName = async () => {
        const { eventName } = this.state;
        const eventsSnapshot = await Backend.getDocs(Backend.query(Backend.eventsDb, Backend.where('name', '==', eventName)));
        const events = toArray<Event>(eventsSnapshot);
        this.setState({ events });
    }

    private openNextPage = () => {
        const { eventsListPageState } = this.state;
        eventsListPageState.pageOffset = eventsListPageState.pageState === PageState.LAST ? eventsListPageState.pageOffset - 1 : eventsListPageState.pageOffset + 1;
        this.setState({ eventsListPageState });
        this.listEvents(this.state.eventsUser, PageChangedType.NEXT);
    }

    private openPrevPage = () => {
        const { eventsListPageState } = this.state;
        eventsListPageState.pageOffset = eventsListPageState.pageState === PageState.FIRST ? eventsListPageState.pageOffset - 1 : eventsListPageState.pageOffset + 1;
        this.setState({ eventsListPageState });
        this.listEvents(this.state.eventsUser, PageChangedType.PREVIOUS);
    }

    private openFirstPage = () => {
        const { eventsListPageState } = this.state;
        this.setState({ eventsListPageState: { pageState: PageState.FIRST, pageOffset: 0, maxOffset: eventsListPageState.maxOffset } });
        this.listEvents(this.state.eventsUser, PageChangedType.FIRST);
    }

    private openLastPage = () => {
        const { eventsListPageState } = this.state;
        this.setState({ eventsListPageState: { pageState: PageState.LAST, pageOffset: 0, maxOffset: eventsListPageState.maxOffset } });
        this.listEvents(this.state.eventsUser, PageChangedType.LAST);
    }

    private onPrevLogs = () => {
        const { pageQueries } = this.state;
        const pageIndex = this.state.pageIndex - 1;
        const query = pageQueries.get(pageIndex);
        if (query) {
            this.tryUnsubscribe();
            const hideProgress = showProgress('UserList onPrevLogs');
            this.unsubscribe = Backend.onSnapshot(query, data => setTimeout(() => {
                hideProgress();
                const dataArray = Backend.toArray<EventLog>(data);
                this.onLogs(dataArray);
            }));
        } else {
            this.onLogs([]);
        }
        this.setState({ pageIndex });
    }

    private onNextLogs = () => {
        this.tryUnsubscribe();
        const { logs, pageTimeStamps, pageQueries } = this.state;
        const timeStamp = logs ? logs[logs.length - 1].datetime : 0;
        const pageIndex = this.state.pageIndex + 1;
        pageTimeStamps.set(pageIndex + 1, timeStamp);
        const query = Backend.query(Backend.adminLogsDb(), Backend.orderBy('datetime', 'desc'), Backend.startAfter(timeStamp), Backend.limit(ITEMS_PER_PAGE));
        const hideProgress = showProgress('UserList onNextLogs');
        pageQueries.set(pageIndex, query);
        this.unsubscribe = Backend.onSnapshot(query, data => setTimeout(() => {
            hideProgress();
            const dataArray = Backend.toArray<EventLog>(data);
            this.onLogs(dataArray);
        }));
        this.setState({ pageIndex });
    }

    private getExportData(): DataAndHeaders {
        let exportData = new DataAndHeaders();
        const tab = tabName('/sup', 'list') as SupMode;
        if (tab === 'users') {
            const { users } = this.state;
            exportData.headers.push('index', 'uid', 'email', 'creationTime', 'lastSignInTime');
            users && users.forEach((user, index) => {
                const exportRow: string[] = [];
                exportRow.push(String(index + 1));
                exportRow.push(user.uid);
                exportRow.push(user.email || '');
                exportRow.push(Utils.formatDateDashed1(user.creationTime));
                exportRow.push(Utils.formatDateDashed1(user.lastSignInTime));
                exportData.data.push(exportRow);
            });
        } else if (tab === 'bigquery') {
            const { queryRecords } = this.state;
            exportData = getDataAndHeaders(queryRecords);
        } else if (tab === 'fbquery') {
            const { firebaseEntities } = this.state;
            exportData = getDataAndHeaders(firebaseEntities);
        }
        return exportData;
    }

    private getRangeStatistics = async () => {
        const { from, to } = this.state;
        const fromVal = from ? new Date(from).getTime() : 0;
        const toVal = to ? new Date(to).getTime() : new Date().getTime();
        if (fromVal > toVal) {
            showAlert('\'From\' date can not be later than \'To\' date.');
            this.setState({ dateRangeStatisticsInfo: undefined });
        }
        const hideProgress = showProgress('UserList getRangeStatistics');
        try {
            const eventsSnapshot = await Backend.getDocs(Backend.query(Backend.eventsDb, Backend.where('date', '>=', fromVal), Backend.where('date', '<=', toVal)));
            if (!eventsSnapshot.empty) {
                const events = toArray<Event>(eventsSnapshot);
                const adminsData = new Map<string, EventUserInfo>();
                let legitEventsAmount = 0;
                for (const { leaderboard, exists, stats, userId, userEmail, userName } of events) {
                    const isLegit = !leaderboard && exists && stats &&
                        (stats.participantsWithScores > 0 || stats.participantsWithAppScores > 0);
                    if (!adminsData.has(userId)) {
                        adminsData.set(userId, {
                            userId,
                            userEmail,
                            userName,
                            legitEventsCount: isLegit ? 1 : 0,
                            createdEventsCount: 1
                        } as EventUserInfo);
                    } else {
                        const userInfo: EventUserInfo = adminsData.get(userId)!;
                        if (!userInfo.userEmail && userEmail) {
                            userInfo.userEmail = userEmail;
                        }
                        if (!userInfo.userName && userName) {
                            userInfo.userName = userName;
                        }
                        if (isLegit) {
                            userInfo.legitEventsCount++;
                        }
                        userInfo.createdEventsCount++;
                    }
                    if (isLegit) {
                        legitEventsAmount++;
                    }
                }
                adminsData.forEach((eventUserInfo, userId, map) => {
                    if (!eventUserInfo.legitEventsCount) {
                        map.delete(userId);
                    }
                });
                const dateRangeStatisticsInfo: DateRangeStatisticsInfo = {
                    adminsData,
                    legitEventsAmount,
                    events
                };
                hideProgress();
                this.setState({ dateRangeStatisticsInfo });
            } else {
                hideProgress('An error fetching stats occurred.');
                this.setState({ dateRangeStatisticsInfo: undefined });
            }
        } catch (err) {
            hideProgress(errMsg(err));
            console.log(errMsg(err));
            this.setState({ dateRangeStatisticsInfo: undefined });
        }
    }

    private getContactList = async () => {
        const hideProgress = showProgress('UserList getContactList');
        try {
            const userStats = await Backend.mapEntities<UserStats>(Backend.userStatsDb);
            const eventsAdminsIds = new Array<string>();
            const llsOnlyAdminsIds = new Array<string>();
            for (const stats of Array.from<UserStats>(userStats.values())) {
                if (!stats?.exists || !stats.eventsDates) {
                    continue;
                }
                const eventsAdmin = stats.eventsDates.some(stat => !Boolean(stat.ll));
                if (eventsAdmin) {
                    eventsAdminsIds.push(stats.id);
                } else {
                    llsOnlyAdminsIds.push(stats.id);
                }
            }
            if (Backend.firebaseAuth.currentUser) {
                const eventAdminEmailsSet = new Set<string>();
                const llOnlyAdminEmailsSet = new Set<string>();
                const noEventsOrLLsEmailsSet = new Set<string>();
                const token = await Backend.firebaseAuth.currentUser.getIdToken(false);
                let next: string | undefined = undefined;
                let allUsersCount = 0;
                let users: Array<EventsUser> | undefined = undefined;
                do {
                    const res = await axios.post(Urls.listUsersEmails, { token, next, count: USERS_PER_REQUEST });
                    if (!res || res?.data?.lenght === 0) {
                        break;
                    }
                    users = res.data as Array<EventsUser>;
                    users.forEach(user => {
                        if (user?.email?.length && !userStats.has(user.uid)) {
                            noEventsOrLLsEmailsSet.add(user.email);
                        }
                    });
                    const usersMap = new Map<string, EventsUser>(users.map(user => [user.uid, user]));
                    allUsersCount += usersMap.size;
                    for (const userId of eventsAdminsIds) {
                        const email = usersMap.get(userId)?.email;
                        if (email?.length) {
                            eventAdminEmailsSet.add(email);
                        }
                    }
                    for (const userId of llsOnlyAdminsIds) {
                        const email = usersMap.get(userId)?.email;
                        if (email?.length) {
                            llOnlyAdminEmailsSet.add(email);
                        }
                    }
                    next = users[users.length - 1].uid;
                } while (users.length === USERS_PER_REQUEST);
                this.setState({ allUsersCount, userStats, eventAdminEmailsSet, llOnlyAdminEmailsSet, noEventsOrLLsEmailsSet });
            }
        } catch (err) {
            console.log(errMsg(err));
        } finally {
            hideProgress();
        }
    }

    renderUsers = () => {
        const { classes, userAware } = this.props;
        const { emailOrUid, users, asc, sortCol, curPage, exportToCSV, proOnly, eventStats } = this.state;
        const now = Date.now();
        const exportFile = `users-${Utils.formatDateDashed1(now)}.csv`;
        const exportData = exportToCSV ? this.getExportData() : new DataAndHeaders();
        return <>
            <ListItem>
                {userAware.effectiveUserId && <>
                    <Typography className={classes.childrenCentered + ' ' + classes.listItem1}>{userAware.effectiveUserId}</Typography>
                    <AppButton onClick={this.handleUnsup}>Unsup</AppButton>
                    &nbsp;&nbsp;&nbsp;
                </>}
                <TextField
                    type="text"
                    label="User email or uid"
                    value={emailOrUid}
                    size={TEXT_FIELD_SIZE}
                    variant="outlined"
                    sx={{ width: 240 }}
                    onKeyDown={event => processEnterKey(event, this.findUser)}
                    onChange={e => this.setState({ emailOrUid: e.target.value })} />
                &nbsp;&nbsp;&nbsp;
                <AppButton color="info" onClick={this.findUser}><ArrowBackIcon sx={{ height: '.7em' }} /> Find user by email or uid</AppButton>
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                <AppButton color="info" onClick={this.findMe}>Find me</AppButton>
            </ListItem>
            <Divider />
            <ListItem>
                <Typography className={classes.childrenCentered + ' ' + classes.listItem1}>Page {curPage + 1} ({users.length} users)</Typography>
                &nbsp;&nbsp;&nbsp;
                <FormControlLabel label="PRO only"
                    control={<Checkbox color="secondary" checked={!!proOnly} />}
                    onChange={() => this.setState({ proOnly: !proOnly })}
                />
                <FormControlLabel label="Event stats"
                    control={<Checkbox color="secondary" checked={!!eventStats} />}
                    onChange={() => this.setState({ eventStats: !eventStats })}
                />
                <AppButton color="info" onClick={this.handleLoadStart}>List Start</AppButton>
                &nbsp;&nbsp;&nbsp;
                <AppButton color="info" onClick={this.handleLoadNext}>List Next</AppButton>
                &nbsp;&nbsp;&nbsp;
                {users.length > 0 && <CSVLink data={exportData.data} headers={exportData.headers} filename={exportFile} style={{ textDecoration: 'none' }}>
                    <AppButton color="info" onClick={this.handleExportToCSV}>Export results</AppButton></CSVLink>}
            </ListItem>
            <UserItemHeader onColumnSort={this.getDataSortedByColumn} asc={asc} sortCol={sortCol} sortDisabled={users.length === 1} />
            {users.map(user => <UserItem
                key={user.uid}
                user={user}
                userAware={userAware}
                handleOpenEventsList={this.listEvents}
                onGrantChanged={grant => {
                    user.grant = grant;
                    this.setState({});
                }}
            />)}
        </>;
    }

    renderLogs = () => {
        const { pageIndex, logs, logEntry } = this.state;
        return <>
            <ListItem>
                <AppButton color="primary" onClick={this.handleOpenChangelog}>Load change logs</AppButton>
                &nbsp;&nbsp;&nbsp;
            </ListItem>
            <LogItemHeader />
            {logs && logs.map(item => <LogItem key={item.id} log={item} clickHandler={log => this.setState({ logEntry: log })} />)}
            {logs && <ButtonBar margin>
                <AppButton color="secondary" onClick={this.onPrevLogs} disabled={pageIndex < 1}>Recent</AppButton>
                <AppButton color="secondary" onClick={this.onNextLogs} disabled={logs.length < ITEMS_PER_PAGE}>Previous</AppButton>
            </ButtonBar>}
            {!!logEntry && <ChangelogDetailsDialog
                open={!!logEntry}
                logEntry={logEntry}
                onClose={() => this.setState({ logEntry: undefined })}
                openEvent={this.handleOpenEvent} />}
        </>;
    }

    private eventsListData = () => {
        const { classes } = this.props;
        const { events } = this.state;
        const data = events.map(event => {
            const { name, type, publicId, leaderboard, date, lastModified, userId, userEmail } = event;
            const portalUrl = `${window.location.origin}/event/${publicId}/${leaderboard ? 'standings' : 'about'}`;
            /*const eventIdAndPortal = (
                <span>
                    <TitledLink onClick={() => openEventById(publicId)}>{publicId}</TitledLink> &nbsp;
                    <TitledLink href={portalUrl} target="_blank">Event site</TitledLink> &nbsp;
                </span>
            );*/
            const { invites, participants, participantsWithScores, participantsWithAppScores } = event.stats ?? {} as EventStats;
            const record: EventData = {
                date,
                name,
                type,
                userId,
                userEmail,
                lastModified,
                eventId: publicId,
                eventPortal: portalUrl,
                scores: participantsWithScores ?? 0,
                appScores: participantsWithAppScores ?? 0,
                participants: participants ?? 0,
                invites: invites ?? 0,
            };
            return record;
        });
        return (
            <div className={classes.eventsDataTable}>
                <DataTable
                    dense
                    data={data}
                    fixedHeader={true}
                    sortIcon={<SortIcon />}
                    columns={eventTableColumns}
                    customStyles={dataTableStyle}
                    fixedHeaderScrollHeight={'800px'}
                    subHeaderAlign={Alignment.CENTER}
                />
                <Spacing />
            </div>
        );
    };

    renderEvents = () => {
        const { eventId, eventName, includeMultidays, includeSingledays, includeLeaderboards, eventsListPageState, events } = this.state;
        return <>
            <ListItem>
                <TextField
                    type="text"
                    label="Event ID"
                    value={eventId}
                    size={TEXT_FIELD_SIZE}
                    variant="outlined"
                    sx={{ width: 240 }}
                    onKeyDown={event => processEnterKey(event, this.openEvent)}
                    onChange={e => this.setState({ eventId: e.target.value })} />
                &nbsp;&nbsp;&nbsp;
                <AppButton color="info" onClick={this.openEvent}><ArrowBackIcon sx={{ height: '.7em' }} /> Open Event</AppButton>
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                <TextField
                    type="text"
                    label="Event Name"
                    value={eventName}
                    size={TEXT_FIELD_SIZE}
                    variant="outlined"
                    sx={{ width: 240 }}
                    onKeyDown={event => processEnterKey(event, this.findEventByName)}
                    onChange={e => this.setState({ eventName: e.target.value })} />
                &nbsp;&nbsp;&nbsp;
                <AppButton color="info" onClick={this.findEventByName}><ArrowBackIcon sx={{ height: '.7em' }} /> Find Event</AppButton>
            </ListItem>
            <Divider />
            <ListItem>
                <AppButton color="primary" onClick={() => this.listEvents()}>List Events</AppButton>
                &nbsp;&nbsp;&nbsp;
                <FormControlLabel label="Multidays"
                    control={<Checkbox color="secondary" checked={includeMultidays} />}
                    onChange={() => this.setState({ includeMultidays: !this.state.includeMultidays })} />
                <FormControlLabel label="Singledays"
                    control={<Checkbox color="secondary" checked={includeSingledays} />}
                    onChange={() => this.setState({ includeSingledays: !this.state.includeSingledays })} />
                <FormControlLabel label="Leaderboards"
                    control={<Checkbox color="secondary" checked={includeLeaderboards} />}
                    onChange={() => this.setState({ includeLeaderboards: !this.state.includeLeaderboards })} />
                <AppButton color="info" onClick={this.openFirstPage} disabled={(eventsListPageState.pageState !== PageState.LAST && eventsListPageState.pageOffset === 0) ||
                    (eventsListPageState.pageState === PageState.LAST && eventsListPageState.pageOffset === eventsListPageState.maxOffset)}>First page</AppButton>
                &nbsp;&nbsp;&nbsp;
                <AppButton color="info" onClick={this.openPrevPage} disabled={(eventsListPageState.pageState !== PageState.LAST && eventsListPageState.pageOffset === 0) ||
                    (eventsListPageState.pageState === PageState.LAST && eventsListPageState.pageOffset === eventsListPageState.maxOffset)}>Previous page</AppButton>
                &nbsp;&nbsp;&nbsp;
                <AppButton color="info" onClick={this.openNextPage} disabled={(eventsListPageState.pageState !== PageState.FIRST && eventsListPageState.pageOffset === 0) ||
                    (eventsListPageState.pageState === PageState.FIRST && eventsListPageState.pageOffset === eventsListPageState.maxOffset)}>Next page</AppButton>
                &nbsp;&nbsp;&nbsp;
                <AppButton color="info" onClick={this.openLastPage} disabled={(eventsListPageState.pageState !== PageState.FIRST && eventsListPageState.pageOffset === 0) ||
                    (eventsListPageState.pageState === PageState.FIRST && eventsListPageState.pageOffset === eventsListPageState.maxOffset)}>Last page</AppButton>
                &nbsp;&nbsp;&nbsp;
                count: {events.length}, page: {eventsListPageState.pageOffset}
            </ListItem>
            {this.eventsListData()}
        </>;
    }

    renderContacts = () => {
        const { classes } = this.props;
        const { allUsersCount, userStats, eventAdminEmailsSet, llOnlyAdminEmailsSet, noEventsOrLLsEmailsSet } = this.state;
        return <>
            <AppButton color="info" onClick={this.getContactList}>Show contact list</AppButton>
            <Divider />
            <span className={classes.modeListTitle}>Emails associated with LL creation only:&nbsp;
                <span className={classes.emailsList}>{Array.from<string>(llOnlyAdminEmailsSet).join(', ')}.</span>
            </span>
            <br />
            <br />
            <span className={classes.modeListTitle}>Emails associated with events and LLs:&nbsp;
                <span className={classes.emailsList}>{Array.from<string>(eventAdminEmailsSet).join(', ')}.</span>
            </span>
            <br />
            <br />
            <span className={classes.modeListTitle}>Emails not associated with events or LLs:&nbsp;
                <span className={classes.emailsList}>{Array.from<string>(noEventsOrLLsEmailsSet).join(', ')}.</span>
            </span>
            <br />
            <br />
            <span className={classes.modeListTitle}>Details:&nbsp;
                <span className={classes.emailsList}>
                    User stats in db: {userStats.size},
                    all db users: {allUsersCount},
                    LL only admins: {llOnlyAdminEmailsSet.size},
                    event and LL admins: ${eventAdminEmailsSet.size},
                    no events or LLs admins: ${noEventsOrLLsEmailsSet.size}
                </span>
            </span>
            <Spacing height={40} />
        </>;

    }

    renderActiveAdmins = () => {
        const { classes } = this.props;
        const { from, to, dateRangeStatisticsInfo } = this.state;
        return <>
            <ListItem>
                <TextField
                    type="date"
                    label="From date"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    sx={{ width: 200 }}
                    InputLabelProps={{ shrink: true }}
                    value={from ?? 0}
                    onChange={e => this.setState({ from: e.target.value })} />
                &nbsp;&nbsp;&nbsp;
                <TextField
                    type="date"
                    label="To date"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    sx={{ width: 200 }}
                    InputLabelProps={{ shrink: true }}
                    value={to ?? 0}
                    onChange={e => this.setState({ to: e.target.value })} />
                &nbsp;&nbsp;&nbsp;
                <AppButton color="primary" style={{ marginRight: 8 }} onClick={this.getRangeStatistics}>Get range statistics</AppButton>
            </ListItem>
            {dateRangeStatisticsInfo &&
                <RangeStatisticsContents classes={classes} {...dateRangeStatisticsInfo} />}
        </>;
    }

    doQuery = async () => {
        const { firebaseCollection, queryLimit } = this.state;
        const colletionGroup = FB_COLLECTIONS.find(c => c.name === firebaseCollection)?.collGroup;
        const query = Backend.query(Backend.dbCollection(firebaseCollection, colletionGroup), Backend.limit(queryLimit));
        const hideProgress = showProgress('UserList doQuery');
        this.setState({ firebaseError: '', firebaseEntities: [] });
        try {
            const firebaseEntities = await Backend.getEntities(query);
            const firebaseColumns = getColumns(firebaseEntities);
            this.setState({ firebaseEntities, firebaseColumns });
            hideProgress();
        } catch (err) {
            const firebaseError = Utils.getServerErrorMessage(err);
            hideProgress(firebaseError);
            console.log(firebaseError);
            this.setState({ firebaseError });
        }
    }

    renderFirebaseQuery = () => {
        const { classes } = this.props;
        const { firebaseCollection, firebaseEntities, firebaseColumns, firebaseError, queryLimit, exportToCSV } = this.state;
        const exportFile = `${firebaseCollection}.csv`;
        const exportData = exportToCSV ? this.getExportData() : new DataAndHeaders();
        return <>
            <ListItem>
                <TextField
                    select
                    label="Collection"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    value={firebaseCollection}
                    onChange={e => this.setState({ firebaseCollection: e.target.value })}
                >
                    {FB_COLLECTIONS.map(coll => <MenuItem key={coll.name} value={coll.name}>{coll.name}</MenuItem>)}
                </TextField>
                &nbsp;&nbsp;&nbsp;
                <TextField
                    label="Limit"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    sx={{ width: 60 }}
                    value={queryLimit}
                    onChange={e => this.setLimit(e.target.value)} />
                &nbsp;&nbsp;&nbsp;
                <AppButton color="primary" onClick={this.doQuery}>Do Query</AppButton>
                &nbsp;&nbsp;&nbsp;
                {Utils.withS(firebaseEntities.length, 'document')}
                &nbsp;&nbsp;&nbsp;
                {firebaseError}
                {firebaseEntities.length > 0 &&
                    <CSVLink data={exportData.data} headers={exportData.headers} filename={exportFile} style={{ textDecoration: 'none' }}>
                        <AppButton color="info" onClick={this.handleExportToCSV}>Export results</AppButton></CSVLink>}
            </ListItem>
            <div className={classes.eventsDataTable}>
                <DataTable
                    dense
                    data={firebaseEntities}
                    columns={firebaseColumns}
                    fixedHeader={true}
                    customStyles={dataTableStyle}
                    fixedHeaderScrollHeight={'600px'}
                    subHeaderAlign={Alignment.CENTER} />
            </div>
        </>;
    }

    loadDatasets = async (refresh?: boolean) => {
        if (!Backend.firebaseAuth.currentUser) {
            return;
        }
        const { queryDatasets } = this.state;
        if (queryDatasets.length > 0 && !refresh) {
            return;
        }
        let { queryDataset, queryLimit } = this.state;
        this.setState({ queryDatasetsStatus: 'Loading...' });
        const hideProgress = showProgress('UserList loadDatasets');
        try {
            const token = await Backend.firebaseAuth.currentUser.getIdToken(false);
            const result = await axios.post(Urls.bgQuery, { token, datasets: 1 });
            const queryDatasets = (result.data as Array<Dataset>).sort((a, b) => -a.id.localeCompare(b.id));
            if (!queryDataset) {
                queryDataset = queryDatasets[0]?.id;
            }
            const { query: customQuery } = getQuery(queryDataset, 'events', queryLimit);
            this.setState({ queryDatasets, queryDataset, customQuery, queryDatasetsStatus: undefined });
            hideProgress();
        } catch (err) {
            const queryError = 'Failed to load datasets: ' + Utils.getServerErrorMessage(err);
            hideProgress(queryError);
            console.log(queryError);
            this.setState({ queryDatasetsStatus: 'Failed to load datasets' });
        }
    }

    private removeSelectedField = (name: string) => {
        const { selectedFields } = this.state;
        let index = selectedFields.findIndex(selectedField => selectedField.name === name);
        if (index >= 0) {
            selectedFields.splice(index, 1);
            this.setState({ selectedFields });
        }
    }

    private addSelectedField = (name: string, value: any) => {
        const { selectedFields } = this.state;
        let index = selectedFields.findIndex(selectedField => selectedField.name === name);
        if (index < 0) {
            selectedFields.push({ name, value });
        } else {
            selectedFields[index].value = value;
        }
        this.setState({ selectedFields });
    }

    private updateSelectedField = (name: string, value: any) => {
        const { selectedFields } = this.state;
        let index = selectedFields.findIndex(selectedField => selectedField.name === name);
        if (index >= 0) {
            selectedFields[index].value = value;
            this.setState({ selectedFields });
        }
    }

    private setDataset = (queryDataset: string) => {
        if (queryDataset === 'refresh') {
            this.loadDatasets(true);
        } else {
            this.setState({ queryDataset })
        }
    }

    private setLimit = (value: string) => {
        let queryLimit = Utils.parseInt(value, 1, 2000);
        if (isNaN(queryLimit)) {
            queryLimit = 1;
        }
        this.setState({ queryLimit });
    }

    private setQuery = (querySelection: string) => {
        this.setState({ querySelection, queryDateField: undefined, selectedFields: [] });
    }

    private processKeyInCustomQuery(ev: React.KeyboardEvent) {
        if (ev.ctrlKey && ev.key === 'Enter') {
            ev.preventDefault();
            this.runBigQuery();
        }
    }

    private runBigQuery = async () => {
        const { queryDataset, querySelection, queryLimit, queryDateField, from, to, selectedFields, customQuery } = this.state;
        if (!Backend.firebaseAuth.currentUser || !queryDataset) {
            return;
        }
        const hideProgress = showProgress('UserList runBigQuery');
        this.setState({ queryError: '', queryTime: undefined, queryRecords: [] });
        try {
            const startTime = Date.now();
            let { query } = getQuery(queryDataset, querySelection, queryLimit, queryDateField, from, to, selectedFields);
            if (querySelection === 'custom' && customQuery) {
                query = customQuery;
            }
            const token = await Backend.firebaseAuth.currentUser.getIdToken(false);
            const result = await axios.post(Urls.bgQuery, { token, query });
            const queryRecords = result.data as Array<any>;
            const queryColumns = getColumns(queryRecords, (selectedField: string, selectedFieldValue: any) => this.addSelectedField(selectedField, selectedFieldValue));
            const queryTime = Date.now() - startTime;
            this.setState({ queryTime, queryRecords, queryColumns });
            hideProgress();
        } catch (err) {
            const queryError = Utils.getServerErrorMessage(err);
            hideProgress(queryError);
            console.log(queryError);
            this.setState({ queryError });
        }
    }

    private renderBigQuery = () => {
        const { classes } = this.props;
        const { queryDatasets, queryDataset, queryDatasetsStatus, querySelection, queryLimit, queryDateField, from, to,
            queryRecords, queryTime, queryColumns, queryError, customQuery, selectedFields, expanded, exportToCSV } = this.state;
        const { query, description, dates, expandable } = getQuery(queryDataset ?? 'DATASET', querySelection, queryLimit, queryDateField, from, to, selectedFields);
        const exportFile = `${querySelection} (${queryDataset}).csv`;
        const exportData = exportToCSV ? this.getExportData() : new DataAndHeaders();
        return <>
            <div className={classes.eventsDataTable}>
                <TextField
                    select
                    label="Dataset"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    value={queryDatasetsStatus ?? queryDataset ?? ''}
                    onChange={e => this.setDataset(e.target.value)}
                >
                    {queryDatasetsStatus && <MenuItem value={queryDatasetsStatus}>{queryDatasetsStatus}</MenuItem>}
                    {queryDatasets.map(ds => <MenuItem key={ds.id} value={ds.id}>{ds.id}</MenuItem>)}
                    <Divider />
                    <MenuItem value={'refresh'}>Refresh</MenuItem>
                </TextField>
                &nbsp;&nbsp;&nbsp;
                <TextField
                    select
                    label="Query"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    value={querySelection}
                    onChange={e => this.setQuery(e.target.value)}
                >
                    {BQ_QUERIES.map((coll, idx) => coll.name ? <MenuItem key={coll.name} value={coll.name}>{coll.name}</MenuItem> : <Divider key={idx} />)}
                </TextField>
                &nbsp;&nbsp;&nbsp;
                <TextField
                    label="Limit"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    sx={{ width: 80 }}
                    value={queryLimit}
                    onChange={e => this.setLimit(e.target.value)} />
                {dates && <>
                    &nbsp;&nbsp;&nbsp;
                    <TextField
                        select
                        label="Date field"
                        size={TEXT_FIELD_SIZE}
                        variant={TEXT_FIELD_VARIANT}
                        value={queryDateField ?? 'none'}
                        onChange={e => this.setState({ queryDateField: e.target.value !== 'none' ? e.target.value : undefined })}
                    >
                        <MenuItem key={'none'} value={'none'}>none</MenuItem>
                        {dates.map(d => <MenuItem key={d} value={d}>{d}</MenuItem>)}
                    </TextField>
                </>}
                {selectedFields.map(selectedField => <React.Fragment key={selectedField.name}>
                    &nbsp;&nbsp;&nbsp;
                    <TextField
                        label={<span>
                            {selectedField.name}
                            &nbsp;&nbsp;&nbsp;
                            <BkCloseButton onClick={() => this.removeSelectedField(selectedField.name)} />
                        </span>}
                        size={TEXT_FIELD_SIZE}
                        variant={TEXT_FIELD_VARIANT}
                        value={selectedField.value}
                        onChange={e => this.updateSelectedField(selectedField.name, e.target.value)} />
                </React.Fragment>)}
                {queryDateField && <>
                    &nbsp;&nbsp;&nbsp;
                    <TextField
                        type="date"
                        label={`From ${queryDateField}`}
                        size={TEXT_FIELD_SIZE}
                        variant={TEXT_FIELD_VARIANT}
                        sx={{ width: 180 }}
                        InputLabelProps={{ shrink: true }}
                        value={from ?? 0}
                        onChange={e => this.setState({ from: e.target.value })} />
                    &nbsp;&nbsp;&nbsp;
                    <TextField
                        type="date"
                        label={`To ${queryDateField}`}
                        size={TEXT_FIELD_SIZE}
                        variant={TEXT_FIELD_VARIANT}
                        sx={{ width: 180 }}
                        InputLabelProps={{ shrink: true }}
                        value={to ?? 0}
                        onChange={e => this.setState({ to: e.target.value })} />
                </>}
                <span style={{ height: 48, display: 'inline-block', }}>
                    <span style={{ height: 48, display: 'inline-flex', alignItems: 'center', textAlign: 'center' }}>
                        &nbsp;&nbsp;&nbsp;
                        <AppButton color="primary" onClick={this.runBigQuery}>Run query</AppButton>
                        &nbsp;&nbsp;&nbsp;
                        {Utils.withS(queryRecords.length, 'record')}
                        {queryTime && <span>, spent {queryTime} ms</span>}
                        &nbsp;&nbsp;&nbsp;
                        {queryError}
                        {queryRecords.length > 0 &&
                            <CSVLink data={exportData.data} headers={exportData.headers} filename={exportFile} style={{ textDecoration: 'none' }}>
                                <AppButton color="info" onClick={this.handleExportToCSV}>Export results</AppButton></CSVLink>}
                    </span>
                </span>
            </div>
            <div className={classes.eventsDataTable}>
                <Accordion expanded={expanded || querySelection === 'custom'} onChange={(_e, expanded) => this.setState({ expanded })} sx={{ width: '100%' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>{description}</AccordionSummary>
                    <AccordionDetails> {querySelection === 'custom' ?
                        <TextField multiline fullWidth
                            variant='outlined'
                            placeholder="Type SQL query…"
                            value={customQuery}
                            onChange={e => this.setState({ customQuery: e.target.value })}
                            onKeyDown={event => this.processKeyInCustomQuery(event)} /> :
                        <pre>{query}</pre>}
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className={classes.eventsDataTable}>
                <DataTable
                    dense
                    fixedHeader
                    expandableRows={expandable}
                    expandableRowsComponent={ExpandedComponent}
                    data={queryRecords}
                    columns={queryColumns}
                    customStyles={dataTableStyle}
                    fixedHeaderScrollHeight={'600px'}
                    subHeaderAlign={Alignment.CENTER} />
            </div>
        </>;
    }

    private saveAnnouncement = () => {
        showAlert(`Confirm to save and publish Announcement - it will be updated and shown on all opened admin pages.`, [
            { title: 'Cancel' },
            { title: 'Publish Announcement', color: 'secondary', action: this.publishAnnouncement }
        ]);
    }

    private publishAnnouncement = () => {
        const { mainAnnouncementText: text = '' } = this.state;
        const timestamp = Date.now();
        const announcement = { text, timestamp };
        Backend.updateDoc(Backend.mainAnnouncementDoc(), announcement);
    }

    private loadAnnouncement = async () => {
        const hideProgress = showProgress('UserList loadAnnouncement');
        try {
            const announcement = Backend.fromEntity<Announcement>(await Backend.getDoc(Backend.mainAnnouncementDoc()));
            this.setState({ mainAnnouncementText: announcement.text });
            hideProgress();
        } catch (err) {
            const queryError = Utils.getServerErrorMessage(err);
            hideProgress(queryError);
            console.log(queryError);
        }
    }

    private clearAnnouncement = () => this.setState({ mainAnnouncementText: '' }, () => this.setState({ mainAnnouncementText: '' }, this.publishAnnouncement));
    private setAnnouncement = (mainAnnouncementText: string) => this.setState({ mainAnnouncementText });

    renderAnnouncement = () => {
        const { mainAnnouncementText } = this.state;
        return <>
            <ListItem>
                <RichTextQuill value={mainAnnouncementText} onChange={txt => this.setAnnouncement(txt)} />
            </ListItem>
            <ListItem>
                <AppButton onClick={this.clearAnnouncement}>Clear</AppButton>
                &nbsp;&nbsp;&nbsp;
                <AppButton onClick={this.loadAnnouncement}>Get from DB</AppButton>
            </ListItem>
            <ListItem>PREVIEW:</ListItem>
            <AnnouncementAlert announcement={{ text: mainAnnouncementText } as Announcement} />
            <ListItem>
                {mainAnnouncementText && <pre>{mainAnnouncementText}</pre>}
            </ListItem>
            <ListItem>
                <AppButton color="secondary" onClick={this.saveAnnouncement}>Publish Announcement</AppButton>
            </ListItem>
        </>;
    }

    private setMode = (mode: SupMode) => {
        pushUrl('/sup/' + mode);
        this.setState({}, () => {
            if (mode === 'bigquery') {
                this.loadDatasets();
            }
        });
    }

    render() {
        const tab = tabName('/sup', 'list') as SupMode;
        return (
            <List sx={{ padding: 0 }}>
                <ListItem sx={{ backgroundColor: AppColors.webBlue100 }}>
                    <RadioGroup row value={tab} onChange={v => this.setMode(v.target.value as SupMode)}>
                        {SupModes.map((m, i) => <FormControlLabel key={m} value={m} control={<Radio />} label={SupModeLabels[i]} />)}
                    </RadioGroup>
                </ListItem>
                <Divider />
                <Routes>
                    <Route path="users" element={this.renderUsers()} />
                    <Route path="events" element={this.renderEvents()} />
                    <Route path="contacts" element={this.renderContacts()} />
                    <Route path="logs" element={this.renderLogs()} />
                    <Route path="active_admins" element={this.renderActiveAdmins()} />
                    <Route path="fbquery" element={this.renderFirebaseQuery()} />
                    <Route path="bigquery" element={this.renderBigQuery()} />
                    <Route path="announcement" element={this.renderAnnouncement()} />
                    <Route path="*" element={<Navigate to={'users'} replace />} />
                </Routes>
                <Spacing />
            </List>
        );
    }
}

export default function() {
    const classes = useAppStyles();
    const userAware = useUserAware();
    return <UserList classes={classes} userAware={userAware} />;
}
