import * as React from 'react';
import RichTextView from '../common/form/richtext/RichTextView';
import CurrencyList from 'currency-list';
import axios from "axios";
import DateRangeIcon from '@mui/icons-material/DateRange';
import {
    Event, AddGolfersRequest, AddGolfersResult, Competition, Contact, ContactDetails, ContactInvite, getEventInfo,
    getCurrentCourseName, PaymentStatus, Portal, Round, getEventMainCompetition, ContactInviteStatus, getRegistrationDate,
    ACTION_REGISTRATION, ACTION_GOLFER_CONFIRMATION, formatTeesName
} from '../types/EventTypes';
import * as Utils from '../util/utility';
import { roundDate, findGetParameter } from '../util/utility';
import { Box, DialogActions, DialogContent, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AppColors } from "../main/Theme";
import ListGridItem from "../common/components/ListGridItem";
import * as AppTheme from '../main/Theme';
import { BlueDollarIcon, CheckMarkIcon, DoneIconPrimary, GolferAddIcon, InfoIcon, LinkIcon, LocationIcon, ScoringIcon } from "../common/Icons";
import { formatDateUniversal, formatEventDateAndTime, formatEventDaysOfWeek, onContactTheOrganizerClick } from "../event/Event";
import AppButton from "../common/components/AppButton";
import { showAlert, showProgress } from "../redux/ReduxConfig";
import { FirebaseDataComponent, FirebaseDocComponent } from "../common/WithData";
import * as Backend from "../util/firebase";
import RegisterContactDialog from "../contact/EditContactDialog";
import { Urls } from "../util/config";
import { useAppStyles } from "../styles";
import { fullName, newContact, shortSurname } from "../contact/Contact";
import CopyTextField from "../common/components/CopyTextField";
import { XSMobileDialog } from "../common/dialog/MobileDialog";
import DialogAppBar from "../common/dialog/DialogAppBar";
import { PayPalPaymentDialog } from "../payments/paypal/PayPalPaymentDialog";
import { Flex, Spacing } from 'src/common/Misc';

const textStylesCommon: React.CSSProperties = {
    fontSize: 16,
    fontFamily: '"poppins", sans-serif',
    lineHeight: '145%'
};

type RegistrationAvailableProps = Omit<EventInfoContentProps, 'competitionsMap' | 'adminAddress' | 'rounds'>;

const RegistrationAvailableContent = (props: RegistrationAvailableProps) => {
    const { event, addedContact, adminEmail, payingContactInfo, paymentStatus, onRegister } = props;
    const registrationDate = getRegistrationDate(event);
    const title = `Registration deadline — through ${formatDateUniversal(registrationDate, 'MMMM D, YYYY')}`;
    const txtGolferRegistered = addedContact ? `${fullName(addedContact)} registered successfully.` : undefined;
    const registrationLink = Urls.makeAboutLink(event.publicId);
    const paymentSettings = event.paymentSettings;
    const currencySymbol = React.useMemo(
        () => paymentSettings ? CurrencyList.get(paymentSettings.currencyCode)?.symbol ?? '' : '',
        [event.paymentSettings?.currencyCode]
    );
    const entryFeeTitle = paymentSettings?.enabled ? `${currencySymbol}${currencySymbol.length > 1 ? ' ' : ''}${paymentSettings.feeCost} entry fee` : '';
    const entryFeeSubTitle = 'Entry fee';
    const paymentSuccess = paymentStatus === PaymentStatus.SUCCESSFUL_PAYMENT || paymentStatus === PaymentStatus.SUCCESSFUL_PAYMENT_WITH_EXCEPTION;
    return (<Grid item margin="dense">
        <ListGridItem
            icon={<GolferAddIcon color="primary" />}
            sx1={AppTheme.topTextStyle}
            sx2={AppTheme.bottomTextStyle}
            text1={title}
            text2={Utils.formatDayOfWeek(registrationDate)} />
        {paymentSettings?.enabled && <ListGridItem
            icon={<BlueDollarIcon />}
            sx1={AppTheme.topTextStyle}
            sx2={AppTheme.bottomTextStyle}
            text1={entryFeeTitle}
            text2={entryFeeSubTitle} />}
        <Box paddingLeft={0.5}>
            {addedContact && <Box>
                <Box height={8} />
                <Typography sx={{ ...textStylesCommon, color: AppColors.webBlack }}>
                    <DoneIconPrimary style={{ verticalAlign: 'middle', marginRight: '6px', marginBottom: 3 }} />
                    {txtGolferRegistered}
                </Typography>
                <Typography sx={{ ...textStylesCommon, color: AppColors.webGrey }}>
                    For changes and cancellations please contact event administrator
                    {adminEmail && <> at <span style={{ ...textStylesCommon, color: AppColors.webBlue500 }}>
                        {adminEmail}
                    </span></>}.
                </Typography>
            </Box>}
            <Box height={8} />
            {paymentSuccess && payingContactInfo && <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row">
                    <Box width={20} height={20} marginRight={1}><CheckMarkIcon color="#5893C7" /></Box>
                    <Typography>
                        Entry fee for {shortSurname(payingContactInfo.contact)} has been successfully paid
                    </Typography>
                </Box>
                <Box height={12} />
            </Box>}
            <CopyTextField
                readOnly btnOnly
                color="secondary"
                fieldValue={registrationLink}
                btnLabel={<React.Fragment>INVITE FRIENDS<LinkIcon /></React.Fragment>}
                btnStyle={{ marginBottom: 12 }} />
            <AppButton style={{ marginLeft: 16, marginBottom: 12 }}
                color="secondary"
                disabled={payingContactInfo?.payButtonEnabled === false}
                onClick={payingContactInfo?.handlePayClick ?? onRegister}>
                {payingContactInfo?.payButtonEnabled ? 'Pay' :
                    addedContact ? 'Register another golfer' : 'Register'}
            </AppButton>
        </Box>
    </Grid>);
};

const TileRoundItem = ({ round, mainCompetition }: { round: Round, mainCompetition?: Competition }) => {
    const classes = useAppStyles();
    const teesName = formatTeesName(round, mainCompetition);
    return <Box className={classes.tileAboutBox}>
        <Grid container>
            <Grid item xs={12}>
                <Typography sx={{ fontWeight: 500, fontSize: 16, lineHeight: '30px' }}>
                    Round {round.roundOrder}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <ListGridItem
                        iconMinWidth={'18px'}
                        icon={<DateRangeIcon color='primary' sx={{ width: '20px' }} />}
                        sx1={AppTheme.topTextStyleSm}
                        sx2={AppTheme.bottomTextStyleSm}
                        text1={formatEventDateAndTime(round, [], false, false)}
                        text2={formatEventDaysOfWeek(round, [])} />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <ListGridItem
                        iconMinWidth={'18px'}
                        icon={<LocationIcon color='primary' sx={{ width: '20px' }} />}
                        sx1={AppTheme.topTextStyleSm}
                        sx2={AppTheme.bottomTextStyleSm}
                        text1={getCurrentCourseName(round)}
                        text2={teesName} />
                </Grid>
            </Grid>
        </Grid>
    </Box>;
}

type EventInfoContentProps = {
    event: Event;
    rounds: Array<Round>;
    competitionsMap: Map<string, Array<Competition>>;
    addedContact?: Contact;
    adminEmail?: string;
    adminAddress?: string;
    payingContactInfo?: PayingContactInfo;
    paymentStatus: PaymentStatus;
    onRegister: () => void;
};

const EventInfoComponent = (props: EventInfoContentProps) => {
    const { event, rounds, competitionsMap, adminEmail, addedContact, payingContactInfo, onRegister, paymentStatus } = props;
    const mainCompetition = getEventMainCompetition(competitionsMap.get(event.type === 'multiday' && rounds.length > 0 ? rounds[0].id : event.id) ?? [], true);
    const { startTime, courseName, competitionName, teesName } = getEventInfo(event, rounds, mainCompetition);
    const startDateTime = event.type === 'multiday' ?
        formatEventDateAndTime(event, rounds, true, false) :
        `Start date — ${formatDateUniversal(event.date, 'D MMMM YYYY')}, ${startTime}`;
    const registrationDate = getRegistrationDate(event);
    const deadline = roundDate(registrationDate);
    const now = roundDate(new Date().getTime());
    const registrationClosed = event.registrationByAdmin || now > deadline;
    const eventName = event.name ?? '';
    const theme = useTheme();
    const lessThanXl = useMediaQuery(theme.breakpoints.down('xl'));
    return (
        <Box padding={lessThanXl ? '16px 12px' : 'unset'}>
            <Typography sx={{ fontWeight: 500, fontSize: 20, lineHeight: '30px' }}>
                {eventName}
            </Typography>
            <Grid container direction="row" columnSpacing={3}>
                <Grid item xs={12} sm={6} margin="dense">
                    <ListGridItem
                        icon={<DateRangeIcon color="primary" />}
                        sx1={AppTheme.topTextStyle}
                        sx2={AppTheme.bottomTextStyle}
                        text1={startDateTime}
                        text2={formatEventDaysOfWeek(event, rounds)} />
                    <ListGridItem
                        icon={<LocationIcon color="primary" />}
                        sx1={AppTheme.topTextStyle}
                        sx2={AppTheme.bottomTextStyle}
                        text1={courseName}
                        text2={teesName} />
                    <ListGridItem
                        icon={<ScoringIcon color="primary" />}
                        sx1={AppTheme.topTextStyle}
                        sx2={AppTheme.bottomTextStyle}
                        text1={competitionName}
                        text2={event.teamSize > 1 ? 'Team' : 'Individual'} />
                </Grid>
                <Grid item xs={12} sm={6} margin="dense">
                    {registrationClosed ?
                        <>
                            <ListGridItem
                                icon={<GolferAddIcon color="primary" />}
                                sx1={AppTheme.topTextStyle}
                                sx2={AppTheme.bottomTextStyle}
                                text1={'Registration closed'}
                                text2={''}
                            />
                            {adminEmail &&
                                <AppButton
                                    color="secondary"
                                    sx={{ fontSize: 14, lineHeight: '21px', marginTop: 1 }}
                                    onClick={() => onContactTheOrganizerClick(eventName, adminEmail)}>
                                    Contact the organizer
                                </AppButton>}
                        </>
                        : <RegistrationAvailableContent
                            event={event}
                            adminEmail={adminEmail}
                            addedContact={addedContact}
                            onRegister={onRegister}
                            payingContactInfo={payingContactInfo}
                            paymentStatus={paymentStatus}
                        />}
                </Grid>
            </Grid>
            {rounds.length > 0 && <>
                <Typography sx={{ fontWeight: 500, fontSize: 20, lineHeight: '30px' }}>
                    Rounds info:
                </Typography>
                <Spacing height={8} />
                <Grid container spacing={1}>
                    {rounds.map(round =>
                        <Grid item key={round.roundOrder}>
                            <TileRoundItem round={round} mainCompetition={getEventMainCompetition(competitionsMap.get(round.id) ?? [], true)} />
                        </Grid>)}
                </Grid>
                <Spacing height={8} />
                {Boolean(event.practiceRoundInfo) && <Flex><InfoIcon color="primary" /> &nbsp; Practice round: {event.practiceRoundInfo}</Flex>}
            </>}
        </Box>
    );
};

type RegistrationCompletedComponent = {
    handleClose: () => void;
    paymentStatus: PaymentStatus;
};

const RegistrationCompletedComponent = (props: RegistrationCompletedComponent) => {
    const { paymentStatus, handleClose } = props;
    const classes = useAppStyles();
    const successfulPayment = 'You have successfully registered, the organizer will be notified of payment.';
    const successfullyRegistered = 'You have successfully registered.';
    const successFulPaymentStatus = paymentStatus === PaymentStatus.SUCCESSFUL_PAYMENT ||
        paymentStatus === PaymentStatus.SUCCESSFUL_PAYMENT_WITH_EXCEPTION;
    const paymentFailure = paymentStatus === PaymentStatus.PAYMENT_FAILURE;
    const payLater = paymentStatus === PaymentStatus.PAY_LATER;
    const registerResultText = successFulPaymentStatus ? successfulPayment : payLater ? successfullyRegistered :
        paymentFailure ? successfullyRegistered : '';
    const commonStyle: React.CSSProperties = { fontSize: 16, lineHeight: '24px', fontFamily: '"poppins", sans-serif' };
    return (
        <XSMobileDialog open={true} maxWidth="xs">
            <DialogAppBar label={<Box display="flex" flexDirection="row">
                <img style={{ width: 24, height: 24, marginRight: 12 }} src={Urls.checkMarkBlueIcon} alt="" />
                <Typography className={classes.bigHeaderFontStyle}>REGISTRATION COMPLETED</Typography>
            </Box>} close={handleClose}>
            </DialogAppBar>
            <DialogContent style={{ ...commonStyle }}>
                {registerResultText && <Box>
                    <Typography style={{ fontWeight: 600, ...commonStyle }}>{registerResultText}</Typography>
                    <Box height={16} />
                </Box>}
                {successFulPaymentStatus &&
                    <Box style={{ backgroundColor: AppColors.webSuccess, color: AppColors.white, ...commonStyle }}
                        padding={1} borderRadius="4px" display="flex" flexDirection="row" alignItems="center">
                        <Box width={20} height={20}><CheckMarkIcon /></Box>
                        <Box width={8} />
                        <Typography style={{ fontSize: 16 }}>
                            The payment was successful. Thank you for using PayPal!
                        </Typography>
                    </Box>}
                {(paymentFailure || payLater) &&
                    <Box style={{ backgroundColor: AppColors.webGrey100 }} padding={1} borderRadius="4px"
                        display="flex" flexDirection="row" alignItems="center">
                        <InfoIcon sx={{ color: payLater ? AppColors.webWarning : AppColors.webError }} />
                        <Box width={8} />
                        <Box display="flex" flexDirection="column" style={{ ...commonStyle }}>
                            {paymentFailure && <Typography style={{ color: AppColors.webError, fontWeight: 600 }}>
                                The payment was unsuccessful.
                            </Typography>}
                            <Typography style={{ color: AppColors.webBlack }}>
                                Please coordinate payment with the tournament organizer directly.
                            </Typography>
                        </Box>
                    </Box>}
                <Box height={16} />
                <Typography style={{ ...commonStyle }}>
                    Please add <span style={{ cursor: 'pointer', color: AppColors.webBlue500 }}>updates@golfpad.events</span>&nbsp;to your email contacts so you don't miss important updates.
                </Typography>
            </DialogContent>
            <DialogActions>
                <AppButton color="secondary" onClick={handleClose}>Ok</AppButton>
            </DialogActions>
        </XSMobileDialog>
    );
};

type PaymentCompletedDialogProps = {
    paymentStatus: PaymentStatus;
    handleClose: () => void;
};

const PaymentCompletedDialog = (props: PaymentCompletedDialogProps) => {
    const { paymentStatus, handleClose } = props;
    const classes = useAppStyles();
    const success = paymentStatus === PaymentStatus.SUCCESSFUL_PAYMENT ||
        paymentStatus === PaymentStatus.SUCCESSFUL_PAYMENT_WITH_EXCEPTION;
    const commonStyle: React.CSSProperties = { fontFamily: '"poppins", sans-serif' };
    return (
        <XSMobileDialog open={true} maxWidth="xs">
            <DialogAppBar label={<Box display="flex" flexDirection="row">
                {success && <img style={{ width: 24, height: 24, marginRight: 12 }} src={Urls.checkMarkBlueIcon} alt="" />}
                <Typography className={classes.bigHeaderFontStyle}>PAYMENT OF ENTRY FEE</Typography>
            </Box>} close={handleClose}>
            </DialogAppBar>
            <DialogContent style={commonStyle}>
                {success ?
                    <Box>
                        <Box>
                            <Typography style={{ fontWeight: 600, ...commonStyle }}>
                                The organizer will be notified of payment.
                            </Typography>
                            <Box height={16} />
                        </Box>
                        <Box style={{ backgroundColor: AppColors.webSuccess, color: AppColors.white, ...commonStyle }}
                            padding={1} borderRadius="4px" display="flex" flexDirection="row" alignItems="center">
                            <Box width={20} height={20}><CheckMarkIcon /></Box>
                            <Box width={8} />
                            <Typography style={{ fontSize: 16 }}>
                                The payment was successful. Thank you for using PayPal!
                            </Typography>
                        </Box>
                    </Box> :
                    <Box style={{ backgroundColor: AppColors.webGrey100 }} padding={1} borderRadius="4px"
                        display="flex" flexDirection="row" alignItems="center">
                        <InfoIcon sx={{ color: AppColors.webError }} />
                        <Box width={8} />
                        <Box display="flex" flexDirection="column" style={{ ...commonStyle }}>
                            <Typography style={{ color: AppColors.webError, fontWeight: 600 }}>
                                The payment was unsuccessful.
                            </Typography>
                            <Typography style={{ color: AppColors.webBlack }}>
                                Please try again later.
                            </Typography>
                        </Box>
                    </Box>}
            </DialogContent>
            <DialogActions>
                <AppButton color="secondary" onClick={handleClose}>Ok</AppButton>
            </DialogActions>
        </XSMobileDialog>
    );
};

type PayingContactInfo = {
    payButtonEnabled: boolean;
    contact: Contact;
    handlePayClick?: () => void;
};

type AboutProperties = {
    event: Event;
    rounds: Array<Round>;
    portal: Portal;
    competitionsMap: Map<string, Array<Competition>>;
    invite?: ContactInvite;
    decline?: boolean;
};

type AboutState = {
    editedContact?: Contact,
    addedContact?: Contact,
    adminEmail?: string,
    golfers: Map<string, Contact>;
    roster: Map<string, ContactDetails>;
    maxEventContacts: number;
    showRegistrationSuccess: boolean;
    paymentStatus: PaymentStatus;
    showPaymentDialog: boolean;
    payingUserId?: string;
    showPaymentCompletedDialog: boolean;
    registrationPaymentDialogOpened: boolean;
    invite?: ContactInvite;
    inviteStatus?: ContactInviteStatus;
};

class About extends React.Component<AboutProperties, AboutState> {
    state: AboutState;
    constructor(props: AboutProperties) {
        super(props);
        const payingUserId = findGetParameter('payingUserId');
        this.state = {
            golfers: new Map(),
            roster: new Map(),
            maxEventContacts: 100,
            showRegistrationSuccess: false,
            paymentStatus: PaymentStatus.NONE,
            showPaymentDialog: Boolean(payingUserId),
            payingUserId: payingUserId,
            showPaymentCompletedDialog: false,
            registrationPaymentDialogOpened: false,
            invite: props.invite
        };
    }

    private onGolfers = (golfers: Map<string, Contact>) => this.setState({ golfers });

    private onRoster = (roster: Map<string, ContactDetails>) => {
        this.setState({ roster });
    }

    private onUserData = (adminEmail: string) => this.setState({ adminEmail });

    private handleRegister = (contactDetails: ContactDetails) => {
        this.setState({ editedContact: undefined }, () => {
            const { event } = this.props;
            const currentUser = Backend.firebaseAuth.currentUser;
            const source = currentUser ? (currentUser.email ? currentUser.email : currentUser.uid) : '';
            const nowDate = new Date();
            const req: AddGolfersRequest = {
                eventId: event.id,
                contact: contactDetails,
                action: ACTION_REGISTRATION,
                source,
                token: '',
                userTime: nowDate.getTime(),
                userTimezoneOffset: nowDate.getTimezoneOffset()
            };
            const hideProgress = showProgress('About handleRegister');
            axios.post(Urls.regGolfer, req)
                .then(res => {
                    hideProgress();
                    let { adminEmail, maxEventContacts } = this.state;
                    const result = res.data as AddGolfersResult;
                    if (result.adminEmail) {
                        adminEmail = result.adminEmail;
                    }
                    if (result.maxEventContacts) {
                        maxEventContacts = result.maxEventContacts;
                    }
                    if (result.registeredContactId) {
                        contactDetails.id = result.registeredContactId;
                    }
                    if (result.nameAlreadyRegistered) {
                        this.setState({ adminEmail, maxEventContacts }, () => showAlert(this.nameAlreadyRegisteredMsg(fullName(contactDetails))));
                    } else if (result.emailAlreadyRegistered) {
                        this.setState({ adminEmail, maxEventContacts }, () => showAlert(this.emailAlreadyRegisteredMsg(contactDetails.email ?? '')));
                    } else if (result.limitExceeded) {
                        this.setState({ adminEmail, maxEventContacts }, () => showAlert(this.fullMsg()));
                    } else {
                        this.setState({
                            showPaymentDialog: event.paymentSettings?.enabled ?? false,
                            payingUserId: result.registeredContactId,
                            addedContact: contactDetails, adminEmail, maxEventContacts,
                            showRegistrationSuccess: !event.paymentSettings?.enabled
                        });
                    }
                })
                .catch(err => hideProgress(`Failed to register golfer: ${err.message}`));
        });
    };

    private handleConfirm = (contactDetails: ContactDetails, invite: ContactInvite) => {
        const { event } = this.props;
        const currentUser = Backend.firebaseAuth.currentUser;
        const source = currentUser ? (currentUser.email ? currentUser.email : currentUser.uid) : '';
        const nowDate = new Date();
        const req: AddGolfersRequest = {
            eventId: event.id,
            contact: contactDetails,
            invite,
            action: ACTION_GOLFER_CONFIRMATION,
            source,
            token: '',
            userTime: nowDate.getTime(),
            userTimezoneOffset: nowDate.getTimezoneOffset()
        };
        const hideProgress = showProgress('About handleConfirm');
        axios.post(Urls.regGolfer, req)
            .then(res => {
                hideProgress();
                let { adminEmail, maxEventContacts } = this.state;
                const result = res.data as AddGolfersResult;
                if (result.adminEmail) {
                    adminEmail = result.adminEmail;
                }
                if (result.maxEventContacts) {
                    maxEventContacts = result.maxEventContacts;
                }
                if (result.registeredContactId) {
                    contactDetails.id = result.registeredContactId;
                }
                if (result.nameAlreadyRegistered) {
                    this.setState({ adminEmail, maxEventContacts }, () => showAlert(this.nameAlreadyRegisteredMsg(fullName(contactDetails))));
                } else if (result.emailAlreadyRegistered) {
                    this.setState({ adminEmail, maxEventContacts }, () => showAlert(this.emailAlreadyRegisteredMsg(contactDetails.email ?? '')));
                } else if (result.limitExceeded) {
                    this.setState({ adminEmail, maxEventContacts }, () => showAlert(this.fullMsg()));
                } else {
                    this.setState({
                        showPaymentDialog: event.paymentSettings?.enabled ?? false,
                        payingUserId: result.registeredContactId,
                        invite: undefined,
                        inviteStatus: 'confirmed',
                        addedContact: contactDetails, adminEmail, maxEventContacts,
                        showRegistrationSuccess: !event.paymentSettings?.enabled
                    });
                }
            })
            .catch(err => hideProgress(`Failed to register golfer: ${err.message}`));
    };

    private fullMsg = () => {
        const { event } = this.props;
        const { adminEmail } = this.state;
        const contactRef = "mailto:" + (adminEmail || event.userEmail);
        return <>
            This event is currently full. Please contact the event organizer (<a href={contactRef} style={{ color: AppColors.webBlue500 }}>{adminEmail || event.userEmail}</a>) about waitlist options.
        </>;
    };

    private emailAlreadyRegisteredMsg = (userEmail: string) => {
        const { event } = this.props;
        const { adminEmail } = this.state;
        const contactRef = "mailto:" + (adminEmail || event.userEmail);
        return <>
            Golfer with an email address <span style={{ color: AppColors.webBlue500 }}>{userEmail}</span> is already registered as playing in the {event.name}. Please contact your <a href={contactRef} style={{ color: AppColors.webBlue500 }}>event organizer</a> with any questions.
        </>;
    }

    private nameAlreadyRegisteredMsg = (userName: string) => {
        const { event } = this.props;
        const { adminEmail } = this.state;
        const contactRef = "mailto:" + (adminEmail || event.userEmail);
        return <>
            <span style={{ cursor: 'pointer', fontWeight: 'bold' }}>{userName}</span> is already registered as playing in the {event.name}. Please contact your <a href={contactRef} style={{ color: AppColors.webBlue500 }}>event organizer</a> with any questions.
        </>;
    }

    private onRegister = () => {
        const { golfers, maxEventContacts } = this.state;
        if (golfers.size < maxEventContacts) {
            this.setState({ editedContact: newContact(this.props.event) })
        } else {
            showAlert(this.fullMsg());
        }
    };

    private showPaymentDialog = () => this.setState({ showPaymentDialog: true });
    private closePaymentDialog = () => this.setState({ showPaymentDialog: false, showRegistrationSuccess: !findGetParameter('payingUserId') });
    private closePaymentCompletedDialog = () => this.setState({ showPaymentCompletedDialog: false });

    private handlePaymentStatusChanged = (paymentStatus: PaymentStatus) =>
        this.setState({ paymentStatus, showPaymentCompletedDialog: true, showPaymentDialog: false });

    render() {
        const { event, rounds, competitionsMap, portal, decline } = this.props;
        const { invite } = this.state;
        let {
            roster, golfers, editedContact, addedContact, adminEmail, showRegistrationSuccess, paymentStatus, showPaymentDialog,
            payingUserId, showPaymentCompletedDialog, inviteStatus
        } = this.state;
        const adminAddress = adminEmail || event.userEmail;
        const payingContact = payingUserId ? golfers.get(payingUserId) : undefined;
        const invitedContact = invite && invite.cid && roster.has(invite.cid) ? roster.get(invite.cid) : newContact(event, invite) as ContactDetails;
        const confirmInvite = invite && !invite.hidden && invite.inviteStatus !== 'confirmed' && invite.inviteStatus !== 'sending_error'
            && !decline && !inviteStatus && getRegistrationDate(event) > Date.now();
        let payingContactInfo: PayingContactInfo | undefined = undefined;
        if (payingContact) {
            payingContactInfo = {
                contact: payingContact,
                payButtonEnabled: paymentStatus !== PaymentStatus.SUCCESSFUL_PAYMENT && paymentStatus !== PaymentStatus.SUCCESSFUL_PAYMENT_WITH_EXCEPTION,
                handlePayClick: paymentStatus !== PaymentStatus.SUCCESSFUL_PAYMENT && paymentStatus !== PaymentStatus.SUCCESSFUL_PAYMENT_WITH_EXCEPTION ? () => this.showPaymentDialog()
                    : undefined
            };
        }
        return <Box>
            <EventInfoComponent
                event={event}
                rounds={rounds}
                competitionsMap={competitionsMap}
                onRegister={this.onRegister}
                paymentStatus={paymentStatus}
                payingContactInfo={payingContactInfo}
                addedContact={addedContact}
                adminEmail={adminEmail}
                adminAddress={adminAddress} />
            {portal.about ? <RichTextView value={portal.about} /> : <Box height={40} />}
            {!!editedContact && <RegisterContactDialog
                open
                event={event}
                rounds={rounds}
                hasPro={false}
                initialContact={editedContact}
                actionMode="register"
                saveToEvent={(contact, _, __) => this.handleRegister(contact)}
                handleClose={() => this.setState({ editedContact: undefined })} />}
            {!!confirmInvite && <RegisterContactDialog
                open
                event={event}
                rounds={rounds}
                hasPro={false}
                initialContact={invitedContact}
                actionMode="confirm"
                saveToEvent={(contact, _, __) => this.handleConfirm(contact, invite)}
                handleClose={() => this.setState({ inviteStatus: "confirmed" })} />}
            {showPaymentDialog && payingContact && !payingContact.paymentId && <PayPalPaymentDialog
                event={event}
                contact={payingContact}
                handlePaymentStatusChanged={this.handlePaymentStatusChanged}
                handleClose={this.closePaymentDialog} />}
            {payingContact && showPaymentCompletedDialog && <PaymentCompletedDialog
                paymentStatus={paymentStatus}
                handleClose={this.closePaymentCompletedDialog} />}
            {showRegistrationSuccess && <RegistrationCompletedComponent
                paymentStatus={paymentStatus}
                handleClose={() => this.setState({ showRegistrationSuccess: false })} />}
            <FirebaseDataComponent
                query={Backend.query(Backend.golferDb(event.id), Backend.where('hidden', '==', false))}
                onMap={this.onGolfers} />
            <FirebaseDataComponent
                query={Backend.query(Backend.rosterDb(event.userId))}
                onMap={this.onRoster} />
            <FirebaseDocComponent
                docReference={Backend.userFields(event.userId)}
                onData={data => this.onUserData(data.email)} />
        </Box>;
    }
}

export default About;
