import * as React from 'react';
import { EventData } from '../../../types/EventTypes';
import GeneralEventSettings from './general/GeneralEventSettings';
import PortalSettings from './portal/PortalSettings';
import LinkTabs, { LinkTab } from '../common/LinkTabs';
import SponsorshipSettings from './portal/SponsorshipSettings';

type TabType = 'general' | 'portal' | 'sponsorship';

interface Props {
    eventData: EventData;
    initial?: string;
    onSelectedTab: (subtab: string) => void;
}

function SettingsTab(props: Props) {
    const { event } = props.eventData;
    const tabs: Array<LinkTab<TabType>> = [{
        id: 'general',
        label: 'General',
        node: <GeneralEventSettings eventData={props.eventData} />
    }, {
        id: 'portal',
        label: 'Event site',
        node: <PortalSettings eventId={event.id} eventPubId={event.publicId} />
    }, {
        id: 'sponsorship',
        label: 'Sponsorship',
        node: <SponsorshipSettings eventData={props.eventData} />
    }];
    return (
        <LinkTabs
            tabs={tabs}
            initial={props.initial || 'general'}
            parentUrl={`/events/${event.id}/settings`}
            onSelectedTab={props.onSelectedTab}
        />
    );
}

export default SettingsTab;
