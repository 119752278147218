import * as React from 'react';
import { ListItem, Typography, Divider, IconButton, Unstable_Grid2 as Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FlagIcon from '@mui/icons-material/GolfCourse';
import GolfIcon from '@mui/icons-material/SportsGolf';
import { showAlert, showAlertProps, showProgress } from '../../../../../redux/ReduxConfig';
import { Facility, FacilitiesGroup, CourseTee, CourseTees, Tee } from '../../../../../types/EventTypes';
import { loadFacilityCourseTees } from '../../../../Courses';
import { ListItemX } from '../../../../../common/Misc';
import { getRating, getSlope, equalTees } from '../../../../../scoring/handicap';
import { GolfPadProgress } from 'src/common/GolfPadProgress';

interface FacilityProps {
    userId: string;
    facility: Facility;
    extraInfo?: boolean;
    onSelect: (facility: Facility) => void;
    onDelete?: (facility: Facility) => void;
}

//const txt = (txt?: string) => <Typography variant="caption">{txt ?? ''}</Typography>
const txt = (txt?: React.ReactNode) => txt ?? '';

const TeeView = (props: { label: string, tee?: Tee, selected: boolean, differ?: boolean }) => {
    const { label, tee, selected, differ } = props;
    return (
        <tr>
            <td>{txt(label + (selected ? '*' : ''))}</td>
            <td>{txt(tee?.name)}</td>
            <td>{txt(tee?.gender)}</td>
            <td>{txt(tee?.handicapSystem)}</td>
            <td>{txt(tee?.handicap?.join(' '))}</td>
            <td>{txt(tee?.par?.join(' '))}</td>
            <td>{txt(tee?.len?.join(' '))}</td>
            <td>{txt(tee ? getRating(tee) : '')}</td>
            <td>{txt(tee ? getSlope(tee) : '')}</td>
            <td>{txt((tee?.edited ? 'edited ' : '') + (tee?.deleted ? 'deleted ' : '') + (differ ? 'different ' : ''))}</td>
        </tr>
    );
}

const CourseTeeView = (props: { courseTee: CourseTee }) => {
    const { courseTee } = props;
    const tee = courseTee.getTee();
    const userTee = courseTee.getUserTee();
    const mainTee = courseTee.getMainTee();
    const differ = (userTee && mainTee) ? !equalTees(userTee, mainTee) : false;
    return (
        <table>
            <thead><tr>
                <th />
                <th>{txt(tee.id)}</th>
                <th>{txt('gender')}</th>
                <th>{txt('system')}</th>
                <th>{txt('hcp')}</th>
                <th>{txt('par')}</th>
                <th>{txt('len')}</th>
                <th>{txt('rating')}</th>
                <th>{txt('slope')}</th>
                <th />
            </tr></thead>
            <tbody>
                <TeeView label="user" tee={userTee} selected={tee === userTee} />
                <TeeView label="main" tee={mainTee} selected={tee === mainTee} differ={differ} />
            </tbody>
        </table>
    );
}

const CourseTeesView = (props: { courseTees: CourseTees }) => {
    const { courseTees } = props;
    return (
        <React.Fragment>
            {courseTees.map((courseTee, idx) => <tr key={idx}><td><CourseTeeView courseTee={courseTee} /></td></tr>)}
        </React.Fragment>
    );
}

class FacilityView extends React.Component<FacilityProps> {
    showTees = async () => {
        const { userId, facility } = this.props;
        const hideProgress = showProgress('loadFacilityCourseTees');
        const courseTees = await loadFacilityCourseTees(userId, facility);
        hideProgress();
        showAlertProps({
            content: <CourseTeesView courseTees={courseTees} />,
            appBarLabel: `${facility.id} - ${facility.name}`,
            buttons: [{ title: 'Close' }],
            ownContent: true,
            maxWidth: 'xl'
        });
    }
    render() {
        const { extraInfo, facility, onSelect, onDelete } = this.props;
        const name = extraInfo ? `${facility.id} - ${facility.name}` : facility.name;
        const extraSpace = onDelete || extraInfo;
        return (
            <ListItemX button={!extraInfo} onClick={() => onSelect(facility)}>
                <Grid container alignItems="center" columnSpacing={3} sx={{ width: '100%' }}>
                    <Grid xs={1}>
                        <FlagIcon color="secondary" />
                    </Grid>
                    <Grid xs={extraSpace ? 9 : 11}>
                        <Grid container direction={'column'}>
                            <Grid xs={12}>
                                <Typography>{name}</Typography>
                            </Grid>
                            <Grid xs={12}>
                                {!!facility.city && <Typography variant="caption" sx={{ color: 'grey', lineHeight: '18px', fontSize: '12px' }}>
                                    {facility.city.formattedString}
                                </Typography>}
                            </Grid>
                        </Grid>
                    </Grid>
                    {extraSpace &&
                        <Grid xs={2}>
                            <span>
                                {onDelete && <IconButton onClick={_ => onDelete(facility)} size="large"><DeleteIcon /></IconButton>}
                                {extraInfo && <IconButton onClick={_ => this.showTees()} size="large"><GolfIcon /></IconButton>}
                            </span>
                        </Grid>}
                </Grid>
            </ListItemX>
        );
    }
}

export interface FacilityListProps {
    userId: string;
    facilitiesGroups: Array<FacilitiesGroup>;
    onSelect: (facility: Facility) => void;
    onDelete?: (facility: Facility) => void;
    allowDelete?: boolean;
    savedCourses?: boolean;
    spaceIfNotLoaded?: boolean;
    extraInfo?: boolean;
}

export class FacilityList extends React.Component<FacilityListProps> {
    private deleteFacility = (facility: Facility) => {
        const { savedCourses, onDelete } = this.props;
        showAlert(`Remove ${savedCourses ? 'saved' : 'recent'} ${facility.id} - ${facility.name} ?`, [
            { title: 'Cancel' },
            { title: 'Remove', color: 'secondary', action: () => onDelete ? onDelete(facility) : undefined }
        ]);
    }
    renderFacilitiesGroup = (facilitiesGroup: FacilitiesGroup, key: string) => {
        const { userId, onSelect, allowDelete, spaceIfNotLoaded, extraInfo } = this.props;
        const { name, loadResult, loadStatus, facilities } = facilitiesGroup;
        return (
            <React.Fragment key={key}>
                {name && <ListItem><Typography sx={{ color: 'grey', lineHeight: '18px', fontSize: '12px' }} gutterBottom
                    variant="overline">{name}</Typography></ListItem>}
                {facilities && facilities.length > 0 &&
                    facilities.map(facility => (
                        <React.Fragment key={facility.id}>
                            <FacilityView userId={userId} extraInfo={extraInfo} facility={facility} onSelect={onSelect} onDelete={allowDelete ? this.deleteFacility : undefined} />
                            <Divider variant="middle" />
                        </React.Fragment>
                    ))}
                {!(facilities && facilities.length > 0) && loadResult &&
                    <div style={{ textAlign: 'center', paddingTop: 8 }}>
                        <Typography variant="subtitle2">{loadResult ?? ''}</Typography>
                    </div>}
                {!facilities?.length && !loadResult && spaceIfNotLoaded &&
                    <div style={{ textAlign: 'center' }}>
                        <Typography variant="caption" gutterBottom style={{ fontSize: 20 }}>&nbsp;</Typography>
                    </div>}
                {loadStatus === 'in_progress' &&
                    <div style={{ textAlign: 'center' }}>
                        <GolfPadProgress />
                    </div>}
            </React.Fragment>
        );
    }
    render() {
        const { facilitiesGroups } = this.props;
        return (
            <React.Fragment>
                {facilitiesGroups.map((facilitiesGroup, idx) => this.renderFacilitiesGroup(facilitiesGroup, String(idx)))}
            </React.Fragment>
        );
    }
}
