import axios from "axios";
import * as React from "react";
import { errMsg } from "../../util/firebase";

const LocationProviderComponent = () => {
    const getGeoInfo = async () => {
        try {
            const response = await axios.get("https://ipapi.co/json/");
            const countryCode = response?.data?.country_code;
            const localCurrency = response?.data?.currency;
            localStorage.setItem('countryCode', countryCode);
            localStorage.setItem('localCurrency', localCurrency);
        } catch (err) {
            // console.log('LocationProviderComponent err: ', err);
        }
    };
    React.useEffect(() => {
        getGeoInfo().catch(e => console.log(errMsg(e)));
    }, []);
    return <></>;
};

export default LocationProviderComponent;