import * as React from 'react';
import { Badge, FormControlLabel, Link, ListItem, ListItemButton, Radio, useMediaQuery } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Grid, { GridSize, GridProps } from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { useTheme, WithStyles, StyleRules } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import EditIconMUI from '@mui/icons-material/Edit';
import LinkIconMUI from '@mui/icons-material/OpenInNew';
import { useAppStyles, styles } from '../styles';
import { Variant } from "@mui/material/styles/createTypography";
import { AppColors } from 'src/main/Theme';

export const labelStyles = makeStyles((_theme: Theme) => {
    return {
        inlineFlex: {
            display: 'inline-flex',
            position: 'relative',
            alignItems: 'center'
        },
        flex: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center'
        },
        spaceBetween: {
            justifyContent: 'space-between'
        },
        right: {
            justifyContent: 'flex-end'
        },
        center: {
            justifyContent: 'center',
            textAlign: 'center'
        },
        top: {
            alignItems: 'flex-start'
        },
        bottom: {
            alignItems: 'flex-end'
        },
        middle: {
            alignItems: 'center'
        },
        baseline: {
            alignItems: 'baseline'
        },
        label: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            fontWeight: 'inherit',
            padding: 0
        },
        nowrap: {
            whiteSpace: 'nowrap',
        }
    } as StyleRules;
});

export interface BasicProps {
    className?: string;
    fontSize?: string;
    fontWeight?: string | number;
    width?: number | string;
    maxWidth?: number | string;
    minWidth?: number | string;
    height?: number | string;
    margin?: number | string;
    border?: number | string;
    borderRadius?: number | string;
    padding?: number | string;
    paddingLeft?: number | string;
    paddingRight?: number | string;
    paddingTop?: number | string;
    paddingBottom?: number | string;
    color?: string;
    backgroundColor?: string;
}

export type LabelProps = BasicProps & {
    children?: React.ReactNode;
    neg?: boolean;
    placeCenter?: boolean;
    placeRight?: boolean;
    placeTop?: boolean;
    placeBottom?: boolean;
    placeMiddle?: boolean;
    spaceBetween?: boolean;
    baseline?: boolean;
    wrapLabel?: boolean;
    inline?: boolean;
};

export type ContainerProps = GridProps & {
    children: React.ReactNode;
    width?: string | number;
    maxWidth?: number | string;
    minWidth?: number | string;
    height?: string | number;
    gutterBottom?: boolean;
    bottom?: boolean;
    gridPadding?: number;
    gridStyles?: React.CSSProperties;
};

export type ItemProps = GridProps & LabelProps & {
    xs?: GridSize;
    sm?: GridSize;
    md?: GridSize;
    lg?: GridSize;
    xl?: GridSize;
    wide?: boolean;
    noWrap?: boolean;
    clickable?: boolean;
    variant?: Variant;
    onClick?: (e: React.MouseEvent) => void;
};

export function flexStyles(props: LabelProps) {
    const classes = labelStyles();
    const { className, placeCenter, placeRight, placeTop, placeBottom, placeMiddle, spaceBetween, baseline, inline } = props;
    return (className ? className : '') + ' ' +
        (inline ? classes.inlineFlex : classes.flex) + ' ' +
        (spaceBetween ? classes.spaceBetween : '') + ' ' +
        (placeCenter ? classes.center : placeRight ? classes.right : '') + ' ' +
        (placeTop ? classes.top : placeBottom ? classes.bottom : placeMiddle ? classes.middle : baseline ? classes.baseline : '');
}

export const Container = (props: ContainerProps) => {
    const { children, width, maxWidth, minWidth, height, gutterBottom, bottom, ...other } = props;
    const paddingBottom = gutterBottom ? '1em' : undefined;
    const alignItems = bottom ? 'flex-end' : undefined;
    return (
        <Grid container alignItems={alignItems} style={{ width, maxWidth, minWidth, height, paddingBottom }} {...other}>
            {children}
        </Grid>
    );
};

export function FlexGrid(props: ContainerProps) {
    const { children, gridPadding, width, maxWidth, minWidth, height, gutterBottom, spacing, ...other } = props;
    const paddingBottom = gutterBottom ? '1em' : undefined;
    return (
        <div style={{ padding: gridPadding ?? 20 }}>
            <Grid {...other} container spacing={spacing} alignContent="space-around" style={{ width, maxWidth, minWidth, height, paddingBottom }}>
                {children}
            </Grid>
        </div>
    );
}

export const Item = (props: ItemProps) => {
    const { children, className, neg, clickable, onClick, xs, sm, md, lg, xl, wide, style, ...other } = props;
    const classes = useAppStyles();
    const gridStyle =
        (className ? ' ' + className : '') +
        (clickable ? ' ' + classes.clickable : '') +
        (neg ? ' ' + classes.marginNeg : '');
    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={gridStyle} style={style} onClick={onClick}>
            {wide ?
                <span {...other} style={{ width: '100%' }}>{children}</span> :
                <FlexLabel height="100%" {...other}>{children}</FlexLabel>}
        </Grid>
    );
};

export const ItemS = (props: ItemProps) => {
    const { children, className, clickable, neg, xs, sm, md, lg, xl, placeRight, style, ...other } = props;
    const classes = useAppStyles();
    const gridStyle =
        (className ? ' ' + className : '') +
        (clickable ? ' ' + classes.clickable : '') +
        (neg ? ' ' + classes.marginNeg : '');
    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={gridStyle} style={style}>
            <Flex {...other}>{children}</Flex>
        </Grid>
    );
};

export const ItemBase = (props: ItemProps) => {
    const { children, className, clickable, neg, xs, sm, md, lg, xl } = props;
    const classes = useAppStyles();
    const gridStyle =
        (className ? ' ' + className : '') +
        (clickable ? ' ' + classes.clickable : '') +
        (neg ? ' ' + classes.marginNeg : '');
    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={gridStyle}>
            {children}
        </Grid>
    );
};

export const NoWrap = (props: { children: string | React.ReactNode, className?: string }) => {
    const { children, className } = props;
    return (
        <Typography color="inherit" variant="inherit" noWrap className={className} style={{ overflow: 'visible' }}>{children}</Typography>
    );
};

export const NoWrapOverflow = (props: { children: string | React.ReactNode, className?: string }) => {
    const { children, className } = props;
    return (
        <Typography color="inherit" variant="inherit" noWrap className={className}>{children}</Typography>
    );
};

export const NoWrapOverflowS = (props: { children: string | React.ReactNode, width?: number | string }) => {
    const { children, width } = props;
    const classes = useAppStyles();
    return (
        <span className={classes.labelOverflow} style={{ width: width, display: 'inline-block' }}>{children}</span>
    );
};

export const VertDivider = (props: { width?: number | string, height?: number | string, padding?: number | string }) => {
    const { width, height, padding } = props;
    return (
        <span style={{ width: width || 2, height: height || 20, paddingTop: padding, paddingBottom: padding }} >
            <span style={{ width: '100%', height: '100%', display: 'inline-block', backgroundColor: 'lightgrey', verticalAlign: 'middle' }} />
        </span>
    );
};

export const HorzDivider = (props: { width?: number | string, height?: number | string }) => {
    const { width, height } = props;
    return (
        <span style={{ width: width || '100%', height: height || 2, display: 'inline-block', backgroundColor: 'lightgrey' }} />
    );
};

export const HorzSpacer = (props: { width?: number | string, height?: number | string }) => {
    const { width, height } = props;
    return (
        <span style={{ width: width || '100%', height: height || 2, display: 'inline-block' }} />
    );
};

export const ListTitle = withStyles(styles)((props: { text: string | React.ReactNode, gutterBottom?: boolean, wrap?: boolean, uppercase?: boolean, children?: React.ReactNode, onClick?: () => void } & WithStyles<typeof styles>) => {
    const { classes, gutterBottom, text, wrap, uppercase, children, onClick } = props;
    return (
        <Typography noWrap={!wrap} gutterBottom={gutterBottom} variant="subtitle1" className={classes.listTitleMisc + ' ' + (uppercase ? classes.uppercaseText : '')} onClick={onClick}>
            {text}
            {children}
        </Typography>
    );
});

export const CenteredText = (props: { children?: React.ReactNode } & TypographyProps) => {
    const { children } = props;
    return (
        <span style={{ position: 'relative' }}>
            <Typography {...props} style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', whiteSpace: 'nowrap' }}>
                {children}
            </Typography>
        </span>
    );
};

export type FlexProps = LabelProps & { style?: React.CSSProperties; };

export const Flex = (props: FlexProps) => {
    let { className, placeCenter, placeRight, placeTop, placeBottom, placeMiddle, spaceBetween, children, style, ...other } = props;
    return (
        <span className={flexStyles(props)} style={{ ...other, ...style }}>
            {children}
        </span>
    );
};

export const FlexRight = (props: FlexProps) => {
    const { children, ...other } = props;
    return (
        <Flex {...other} placeRight>
            {children}
        </Flex>
    );
};

export const FlexCenter = (props: FlexProps) => {
    const { children, ...other } = props;
    return (
        <Flex {...other} placeCenter>
            {children}
        </Flex>
    );
};

export const FlexTop = (props: FlexProps) => {
    const { children, ...other } = props;
    return (
        <Flex {...other} placeTop>
            {children}
        </Flex>
    );
};

export const FlexBottom = (props: FlexProps) => {
    const { children, ...other } = props;
    return (
        <Flex {...other} placeBottom>
            {children}
        </Flex>
    );
};

export const Label = (props: LabelProps) => {
    const { className, children, neg, placeCenter, placeRight, placeTop, placeBottom, wrapLabel, ...other } = props;
    const classes = labelStyles();
    const cls = classes.label +
        (className ? ' ' + className : '') +
        (wrapLabel ? '' : ' ' + classes.nowrap);
    return (
        <span className={cls} style={{ ...other }}>
            {children}
        </span>
    );
};

export const FlexLabel = (props: LabelProps) => {
    const { children, height, neg, placeCenter, placeRight, placeTop, placeBottom, placeMiddle, baseline, ...other } = props;
    return (
        <Flex placeCenter={placeCenter} placeRight={placeRight} placeTop={placeTop} placeBottom={placeBottom} placeMiddle={placeMiddle} baseline={baseline} height={height}>
            <Label {...other}>
                {children}
            </Label>
        </Flex>
    );
};

export const EditIcon = withStyles(styles)((props: WithStyles<typeof styles> & { invisible?: boolean }) => {
    const { classes, invisible } = props;
    return <EditIconMUI className={classes.textIconInverted + (invisible ? ' ' + classes.invisible : '')} />;
});

const LinkIcon = withStyles(styles)((props: WithStyles<typeof styles> & { invisible?: boolean, inverted?: boolean, className?: string }) => {
    const { classes, invisible, inverted, className } = props;
    return <LinkIconMUI className={(inverted ? classes.textIconInverted : classes.textIcon) + (invisible ? ' ' + classes.invisible : '') + (className ? ' ' + className : '')} />;
});

export const IconLinkElement = (props: { href: string, target: string, className?: string, rel?: string, children?: React.ReactNode, other?: React.ReactNode, click?: (e: React.MouseEvent) => void }) => {
    const { href, target, className, rel, children, other, click } = props;
    return (
        <Flex className={className}>
            <a href={href} target={target} rel={rel} onClick={click}><LinkIcon /></a>
            <a href={href} target={target} rel={rel} onClick={click}>{children}</a>
            &nbsp;
            {other}
        </Flex>
    );
};

export const ListElem = (props: { title: React.ReactNode, subtitle: React.ReactNode, id?: string, boldTitle?: boolean, className?: string, color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error', titleColor?: string }) => {
    const theme = useTheme();
    const variant = useMediaQuery(theme.breakpoints.up('sm')) ? 'body2' : 'caption';
    return (
        <div>
            <Typography component="div" id={props.id} color={props.titleColor ?? props.color} variant={variant} sx={props.boldTitle ? { fontWeight: 600 } : {}}>{props.title}</Typography>
            {props.subtitle === ' ' ?
                <Typography component="div" variant="caption" className={props.className} color={props.color}>&nbsp;</Typography> :
                <Typography component="div" variant="caption" className={props.className} color={props.color}>{props.subtitle}</Typography>}
        </div>
    );
};

export const Spacing = (props: BasicProps) => {
    const { className, height, ...other } = props;
    return <div className={className} style={{ height: height ?? 16, ...other }} />;
};

export const Red = (props: { children?: React.ReactNode }) => {
    const { children } = props;
    return (
        <span style={{ color: 'red' }}>
            {children}
        </span>
    );
};

export const Green = (props: { children?: React.ReactNode }) => {
    const { children } = props;
    return (
        <span style={{ color: 'green' }}>
            {children}
        </span>
    );
};

export const Cyan = (props: { children?: React.ReactNode }) => {
    const { children } = props;
    return (
        <span style={{ color: 'cyan' }}>
            {children}
        </span>
    );
};

export const Blue = (props: { children?: React.ReactNode }) => {
    const { children } = props;
    return (
        <span style={{ color: 'blue' }}>
            {children}
        </span>
    );
};

export const Grey = (props: { children?: React.ReactNode }) => {
    const { children } = props;
    return (
        <span style={{ color: 'grey' }}>
            {children}
        </span>
    );
};

export const TitledLink = (props: { children?: React.ReactNode, title?: string, href?: string, target?: string, onClick?: () => void }) => {
    const { children, title, href, target, onClick } = props;
    const handleClick = (e: React.SyntheticEvent) => {
        if (!href || onClick) {
            e.preventDefault();
        }
        if (onClick) {
            onClick();
        }
    }
    return (
        <Link href={href ?? '#'} target={target} underline="always" title={title} onClick={handleClick}>
            {children}
        </Link>
    );
}

export const ListItemX = (props: { button: boolean, children?: React.ReactNode, onClick?: () => void, className?: string; }) => {
    const { button, children, onClick, className } = props;
    if (button) {
        return (
            <ListItemButton onClick={onClick} className={className}>
                {children}
            </ListItemButton>
        );
    } else {
        return (
            <ListItem>
                {children}
            </ListItem>
        );
    }
};

export const ProgressRadio = ({ value, label, width }: { value: string, label: React.ReactNode, disabled?: boolean, width?: number }) => {
    return (
        <span style={{ width }}>
            <FormControlLabel value={value} style={{ marginLeft: 0 }} control={<Radio color="secondary" />} label={label} />
        </span>
    );
}

export const ButtonBadge = ({ invisible, selected }: { invisible: boolean, selected: boolean }) => {
    return (
        <Badge
            invisible={invisible}
            variant="dot"
            overlap="rectangular"
            sx={{ marginBottom: 2, marginLeft: 1 }}
            slotProps={{
                badge: {
                    style: {
                        backgroundColor: selected ? AppColors.white : AppColors.primary
                    }
                }
            }}
        />
    );
}
