import * as React from 'react';
import axios from 'axios';
import { Urls } from 'src/util/config';
import { hideAlert, showAlertProps, showProgress } from 'src/redux/ReduxConfig';
import { getUserInfo, prepareRequest } from 'src/event/Event';
import { UserAware } from './Auth';
import { dbgLog, getServerErrorMessage } from 'src/util/utility';
import { ACTION_CANCEL, ACTION_CHECKOUT, CancelSubscriptionRequest, CancelSubscriptionResponse, CheckoutRequest, CheckoutResponse, SubscriptionInterval } from 'src/types/EventTypes';
import { ManageSubscriptionDialog } from './ManageSubscriptionDialog';

export type AccountActions = 'upgrade_yearly' | 'upgrade_monthly';

export function accountAction(userAware: UserAware, name?: AccountActions) {
    if (name === 'upgrade_yearly') {
        stripeCheckout(userAware, 'year');
    } else if (name === 'upgrade_monthly') {
        stripeCheckout(userAware, 'month');
    } else if (name === 'manage_subscription') {
        const manageSubscriptionPrompt = <ManageSubscriptionDialog
            userAware={userAware}
            onClose={() => hideAlert({ content: manageSubscriptionPrompt, ownContent: true, buttons: [] })}
        />;
        showAlertProps({ content: manageSubscriptionPrompt, ownContent: true, buttons: [] });
    } else {
        return false;
    }
    return true;
}

export async function stripeCheckout(userAware: UserAware, subscription: SubscriptionInterval) {
    const hideProgress = showProgress('DuplicateEventDialog handleCreate');
    try {
        const { adminName, adminEmail } = await getUserInfo(userAware.workingUserId!);
        const checkoutRequest: CheckoutRequest = {
            token: '',
            userId: '',
            name: adminName,
            email: adminEmail,
            action: ACTION_CHECKOUT,
            interval: subscription,
            source: 'Checkout from Events',
            notificationLess: false,
            successUrl: window.location.origin + '/events/subscribed',
            cancelUrl: window.location.toString()
        };
        await prepareRequest(checkoutRequest);
        const res = await axios.post(Urls.checkout, checkoutRequest);
        const responseData: CheckoutResponse = res.data;
        const checkoutUrl = responseData.checkoutUrl;
        dbgLog(`Checkout result: ${responseData.checkoutUrl}, time spent ${responseData.timeSpent}ms`);
        if (checkoutUrl) {
            window.location.replace(checkoutUrl);
        }
        hideProgress();
    } catch (err) {
        hideProgress(getServerErrorMessage(err));
    }
}

export async function stripeCancel(userAware: UserAware) {
    const hideProgress = showProgress('DuplicateEventDialog handleCreate');
    try {
        const cancelRequest: CancelSubscriptionRequest = {
            token: '',
            source: 'Cancel from Events',
            action: ACTION_CANCEL,
            userId: userAware.workingUserId!
        };
        await prepareRequest(cancelRequest);
        const res = await axios.post(Urls.cancelSubscription, cancelRequest);
        const responseData: CancelSubscriptionResponse = res.data;
        dbgLog(`Cancel result: time spent ${responseData.timeSpent}ms`);
    } finally {
        hideProgress();
    }
}
