
export const defaultTeeExplanation = 'Select the default tees here. You can always override tees for individual golfers from the Golfers / List.';
export const flightExplanation = 'Flights are a series of smaller competitions within a golf tournament. A flight is a grouping of golfers who are competing ' +
    'against one another, instead of the entire field. Each flight is typically made up of golfers of similar scoring ability.';
export const carryoversUncheckedExplanation = 'Unchecked without carryovers means any tied holes are not awarded. Winners are holes won by one golfer, if payouts are used the skins purse will be divided amongst the number of winners. ';
export const carryoversCheckedExplanation = 'Checked carryovers means any tied holes will be carried over, and accumulate to the next hole won. If payouts are used the skins purse will be divided amongst the number of holes played, with each hole having the same value.';

export const FREE_OPTS = [
    'Unlimited number of single-day events',
    'Up to 100 participants in the event',
    'Easily-customizable event page with schedule, standings and payouts',
    'Online golfer registration',
    'Multiple individual and team scoring formats, with gross and net scoring',
    'Up to two competitions and unlimited side games per event',
    'Automatic scheduling with drag-n-drop adjustments',
    'Real-time scoring and standings',
    'Works seamlessly with free top-rated Golf Pad GPS app',
];

export const PRO_OPTS = [
    'Unlimited field size',
    'Unlimited competitions and side games',
    'Multi-day tournaments',
    'Automatic flights',
    'Automatic handicap lookup with the USGA',
    'Automatic event fees/payouts with PayPal',
    'Printed scorecards, schedule, cart signs, and more',
    // 'Showcase your event sponsors on event page, in email and printed materials',
    'Priority support and Zoom product introduction session',
    'Leagues, sponsorship showcase and more, coming soon',
    'Cancel at any time',
    'Price lock - the rate will never go up as long as your subscription is active',    
];

export const PRO_OPTS_EXT = [
];
