import * as React from 'react';
import { WithStyles } from '@mui/styles';
import { List, Typography } from '@mui/material';
import { detectMob } from '../../../util/react_utils';
import * as Backend from '../../../util/firebase';
import {
    EventData, Competition, Distance, ReportedScore, Score, isMainCompetitionScoring, isSkinsScoring, CalculatedGrossNetWinnersMapsByFlight,
    isNetMode, isGrossMode, Team, Contact, GolferGroup, EventBase, isRound, Round, sortCompetitions,
    SponsorPlacements, getEventStaff, getCompetitionsWithPayouts
} from '../../../types/EventTypes';
import { ContactGroup, prepareInviteCodes } from '../../Event';
import * as Scoring from '../../../scoring/scoring';
import { createScheduleDoc, createScoringDoc, createScorecardsDoc, createGolfersDoc, createCartSignsDoc, createMoneyListDoc, getGolferInfo } from '../../EventFormat';
import { getSchedule, getCartSigns } from '../../TeeTimes';
import ButtonBar from '../../../common/components/ButtonBar';
import AppButton from '../../../common/components/AppButton';
import AddGolfersDialog from '../../tabs/common/AddGolfersDialog';
import { withProgress } from '../../../util/ProgressPromise';
import { hideAlert, showAlertProps, showProgress } from '../../../redux/ReduxConfig';
import { GolfPadLogoImg, sponsorLogoLandscapePrintHeight, sponsorLogoLandscapePrintWidth, styles, useAppStyles } from '../../../styles';
import { getSameNameGolfersIds } from "../../../contact/Contact";
import ProPlanDialog from 'src/auth/ProPlanDialog';
import { WithUserAware, useUserAware } from 'src/auth/Auth';
import ProBadge from 'src/common/ProBadge';
import { AccountActions, accountAction } from 'src/auth/StripeRedirect';

const useBadge = true;

export function getImageFromURL(url: string, width: number, height: number): Promise<string> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.setAttribute("crossOrigin", "anonymous");
        img.onerror = error => reject(error);
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx!.drawImage(img, 0, 0, width, height);
            const dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
        };
        img.src = url;
    });
}

export function printDoc(doc: any, fileName: string) {
    if (doc) {
        const nav = (window.navigator as any);
        // work around according known Edge 10+ bug https://github.com/bpampuch/pdfmake/issues/693#issuecomment-551041722
        if (nav?.msSaveOrOpenBlob) {
            doc.getBlob((blob: Blob) => nav.msSaveOrOpenBlob(blob, fileName));
        } else if (detectMob()) {
            doc.download(fileName);
        } else {
            doc.print();
        }
    }
}

export async function getSponsorLogo(eventData: EventData, prop: keyof SponsorPlacements) {
    const portal = eventData.portal;
    const sponsorLogo = portal.sponsorLogoUrl && portal.sponsorPlacements?.[prop] ?
        await withProgress(getImageFromURL(portal.sponsorLogoUrl, sponsorLogoLandscapePrintWidth, sponsorLogoLandscapePrintHeight)) :
        await withProgress(getImageFromURL(GolfPadLogoImg, sponsorLogoLandscapePrintWidth, sponsorLogoLandscapePrintHeight));
    return sponsorLogo;
}

export async function printGolfers(eventOrRound: EventBase, eventData: EventData) {
    const { golfers, teams, groups, competitions } = getEventStaff(eventOrRound.id, eventData);
    const sponsorLogo = await getSponsorLogo(eventData, 'printGolfers');
    const doc = createGolfersDoc({ event: eventOrRound, golfers, teams, groups, competitions, sponsorLogo });
    printDoc(doc, eventOrRound.publicId + (isRound(eventOrRound) ? `-round${eventOrRound.roundOrder}` : '') + '-golfers.pdf');
}

export async function printAllSchedules(eventData: EventData) {
    const { event } = eventData;
    if (event.type === 'multiday') {
        const rounds = await Backend.getEntities<Round>(Backend.eventRoundsQuery(event.id));
        rounds.sort((a, b) => b.roundOrder - a.roundOrder).forEach(round => printSchedule(round, eventData));
    } else {
        printSchedule(event, eventData);
    }
}

export async function printSchedule(eventOrRound: EventBase, eventData: EventData) {
    const { golfers, teams, groups } = getEventStaff(eventOrRound.id, eventData);
    const schedule = getSchedule(eventOrRound, groups, golfers, teams);
    if (schedule) {
        const sponsorLogo = await getSponsorLogo(eventData, 'printSchedule');
        const doc = createScheduleDoc({ event: eventOrRound, schedule, golfers, sponsorLogo });
        printDoc(doc, eventOrRound.publicId + (isRound(eventOrRound) ? `-round${eventOrRound.roundOrder}` : '') + '-schedule.pdf');
    }
}

export async function printAllResults(eventData: EventData) {
    const { event } = eventData;
    if (event.type === 'multiday') {
        const rounds = await Backend.getEntities<Round>(Backend.eventRoundsQuery(event.id));
        rounds.sort((a, b) => b.roundOrder - a.roundOrder).forEach(round => printResults(round, eventData));
    } else {
        printResults(event, eventData);
    }
}

export async function printResults(eventOrRound: EventBase, eventData: EventData) {
    const { event, units, golfers, teams, groups } = getEventStaff(eventOrRound.id, eventData);
    const golferScores = await Backend.mapEntities<Score>(Backend.golferScoresDb(eventOrRound.id));
    const reportedScores = await Backend.mapEntities<ReportedScore>(Backend.reportedGolferScoresDb(eventOrRound.id));
    const teamScores = await Backend.mapEntities<Score>(Backend.golferTeamScoresDb(eventOrRound.id));
    const reportedTeamScores = await Backend.mapEntities<ReportedScore>(Backend.reportedTeamScoresDb(eventOrRound.id));
    const distances = await Backend.mapEntities<Distance>(Backend.golferDistancesDb(eventOrRound.id));
    const competitions = await Backend.getEntities<Competition>(Backend.eventCompetitionsQuery(eventOrRound.id));
    const competitionsSplit: Array<Competition> = [];
    competitions.forEach(competition => {
        if (isSkinsScoring(competition.scoring)) {
            if (isNetMode(competition.scoring.mode)) {
                competitionsSplit.push(Scoring.netCompetition(competition));
            }
            if (isGrossMode(competition.scoring.mode)) {
                competitionsSplit.push(Scoring.grossCompetition(competition));
            }
        } else {
            competitionsSplit.push(competition);
        }
    });
    const sponsorLogo = await getSponsorLogo(eventData, 'printResults');
    const doc = createScoringDoc({ event: eventOrRound, competitions: competitionsSplit, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, distancesMap: new Map([[eventOrRound.id, distances]]), units, sponsorLogo });
    printDoc(doc, event.publicId + (isRound(eventOrRound) ? `-round${eventOrRound.roundOrder}` : '') + '-results.pdf');
}

export async function printAllCartSigns(eventData: EventData) {
    const { event } = eventData;
    if (event.type === 'multiday') {
        const rounds = await Backend.getEntities<Round>(Backend.eventRoundsQuery(event.id));
        rounds.sort((a, b) => b.roundOrder - a.roundOrder).forEach(round => printCartSigns(round, eventData));
    } else {
        printCartSigns(event, eventData);
    }
}

export async function printCartSigns(eventOrRound: EventBase, eventData: EventData) {
    const { event, golfers, teams, groups, groupCodes } = getEventStaff(eventOrRound.id, eventData);
    const cartSigns = getCartSigns(eventOrRound, groups, golfers, teams);
    const sponsorLogo = await getSponsorLogo(eventData, 'printCartSigns');
    const doc = createCartSignsDoc({ event: eventOrRound, cartSigns, sameNameGolfersIdsSet: getSameNameGolfersIds(Array.from(golfers.values())), groupCodes, sponsorLogo });
    printDoc(doc, event.publicId + (isRound(eventOrRound) ? `-round${eventOrRound.roundOrder}` : '') + '-cartsigns.pdf');
}

export async function printMoney(eventData: EventData) {
    const { event } = eventData;
    const calculatedScoresMap: Map<string, Map<string, CalculatedGrossNetWinnersMapsByFlight>> = new Map();
    const distancesMap: Map<string, Map<string, Distance>> = new Map();
    const golferScoresMap: Map<string, Map<string, Score>> = new Map();
    const teamScoresMap: Map<string, Map<string, Score>> = new Map();
    const reportedScoresMap: Map<string, Map<string, ReportedScore>> = new Map();
    const reportedTeamScoresMap: Map<string, Map<string, ReportedScore>> = new Map();
    const competitionsMap: Map<string, Array<Competition>> = new Map();
    const golfers = await Backend.mapEntities<Contact>(Backend.golferDb(event.id));
    const groups = await Backend.getEntities<GolferGroup>(Backend.eventGroupsQuery(event.id));
    const teams = await Backend.mapEntities<Team>(Backend.golferTeamDb(event.id));
    const rounds = await Backend.mapEntities<Round>(Backend.eventRoundsQuery(event.id));
    let allCompetitions: Array<Competition> = [];
    const getScores = (eventOrRoundId: string) => {
        const golferScores = golferScoresMap.get(eventOrRoundId) ?? new Map<string, Score>();
        const teamScores = teamScoresMap.get(eventOrRoundId) ?? new Map<string, Score>();
        const reportedScores = reportedScoresMap.get(eventOrRoundId) ?? new Map<string, ReportedScore>();
        const reportedTeamScores = reportedTeamScoresMap.get(eventOrRoundId) ?? new Map<string, ReportedScore>();
        return { golferScores, teamScores, reportedScores, reportedTeamScores };
    }
    const updateScores = (eventOrRound: EventBase, competitions: Array<Competition>, calculatedScoresMap: Map<string, Map<string, CalculatedGrossNetWinnersMapsByFlight>>) => {
        const { golferScores, teamScores, reportedScores, reportedTeamScores } = getScores(eventOrRound.id);
        let calculatedScores = calculatedScoresMap.get(eventOrRound.id) ?? new Map<string, CalculatedGrossNetWinnersMapsByFlight>();
        calculatedScoresMap.set(eventOrRound.id, calculatedScores);
        const competitionScores = Scoring.getGolferScores(eventOrRound, competitions, golfers, teams, groups, golferScores, teamScores, reportedScores, reportedTeamScores);
        competitionScores.forEach((competitionScore, competitionId) => calculatedScores.set(competitionId, competitionScore));
    }
    const updateEventOrRoundScores = (eventOrRound?: EventBase) => {
        if (eventOrRound?.id) {
            const competitions = competitionsMap.get(eventOrRound?.id);
            if (competitions) {
                updateScores(eventOrRound, competitions, calculatedScoresMap);
            }
        } else {
            competitionsMap.forEach((competitions, eId) => updateScores(rounds.get(eId)!, competitions, calculatedScoresMap));
        }
    }
    if (event.type === 'multiday') {
        for (let round of Array.from(rounds.values())) {
            const competitions = await Backend.getEntities<Competition>(Backend.eventCompetitionsQuery(round.id));
            competitions.forEach(competition => {
                competition.eventOrRoundId = round.id;
                competition.roundOrder = round.roundOrder;
            });
            competitionsMap.set(round.id, competitions);
            const golferScores = await Backend.mapEntities<Score>(Backend.golferScoresDb(round.id));
            const teamScores = await Backend.mapEntities<Score>(Backend.golferTeamScoresDb(round.id));
            const distances = await Backend.mapEntities<Distance>(Backend.golferDistancesDb(round.id));
            const reportedScores = await Backend.mapEntities<ReportedScore>(Backend.reportedGolferScoresDb(round.id));
            const reportedTeamScores = await Backend.mapEntities<ReportedScore>(Backend.reportedTeamScoresDb(round.id));
            golferScoresMap.set(round.id, golferScores);
            teamScoresMap.set(round.id, teamScores);
            reportedScoresMap.set(round.id, reportedScores);
            reportedTeamScoresMap.set(round.id, reportedTeamScores);
            distancesMap.set(round.id, distances);
            updateEventOrRoundScores(round);
        }
    } else {
        const competitions = await Backend.getEntities<Competition>(Backend.eventCompetitionsQuery(event.id));
        const golferScores = await Backend.mapEntities<Score>(Backend.golferScoresDb(event.id));
        const teamScores = await Backend.mapEntities<Score>(Backend.golferTeamScoresDb(event.id));
        const distances = await Backend.mapEntities<Distance>(Backend.golferDistancesDb(event.id));
        const reportedScores = await Backend.mapEntities<ReportedScore>(Backend.reportedGolferScoresDb(event.id));
        const reportedTeamScores = await Backend.mapEntities<ReportedScore>(Backend.reportedTeamScoresDb(event.id));
        golferScoresMap.set(event.id, golferScores);
        teamScoresMap.set(event.id, teamScores);
        reportedScoresMap.set(event.id, reportedScores);
        reportedTeamScoresMap.set(event.id, reportedTeamScores);
        distancesMap.set(event.id, distances);
        competitionsMap.set(event.id, competitions);
        updateEventOrRoundScores(event);
    }
    const sponsorLogo = await getSponsorLogo(eventData, 'printMoneyList');
    competitionsMap.forEach(roundCompetitions => allCompetitions = allCompetitions.concat(roundCompetitions));
    allCompetitions = sortCompetitions(allCompetitions, false);
    const doc = createMoneyListDoc({ event, competitions: allCompetitions, calculatedScoresMap, golfers, teams, groups, distances: distancesMap, sponsorLogo });
    printDoc(doc, event.publicId + '-money.pdf');
}

async function printEventScorecards(eventOrRound: EventBase, eventData: EventData, teeGroups: Array<ContactGroup>, groupCodes: Map<string, string>, badgeData?: string, notes?: string, sponsorLogo?: string) {
    const { event, golfers, teams, groups, competitions } = getEventStaff(eventOrRound.id, eventData);
    const golferScores = await Backend.mapEntities<Score>(Backend.golferScoresDb(eventOrRound.id));
    const teamScores = await Backend.mapEntities<Score>(Backend.golferTeamScoresDb(eventOrRound.id));
    const hideProgress = showProgress('printScorecards');
    const doc = createScorecardsDoc({ eventOrRound: eventOrRound, badgeData, teeGroups, golferScores, teamScores, golfers, teams, groups, competitions, groupCodes, notes, sponsorLogo });
    printDoc(doc, event.publicId + (isRound(eventOrRound) ? `-round${eventOrRound.roundOrder}` : '') + '-scorecards.pdf');
    hideProgress();
}

async function printScorecards(eventOrRound: EventBase, eventData: EventData, contacts: Array<ContactGroup>, notes?: string) {
    if (contacts.length === 0) {
        return;
    }
    const { portal, groups } = getEventStaff(eventOrRound.id, eventData);
    const groupCodes = await prepareInviteCodes(groups, eventOrRound.id);
    const sponsorLogo = await getSponsorLogo(eventData, 'printScorecard');
    if (useBadge) {
        if (portal.badgeUrl) {
            const badgeData = await withProgress(getImageFromURL(portal.badgeUrl, 80, 80));
            await printEventScorecards(eventOrRound, eventData, contacts, groupCodes, badgeData, notes, sponsorLogo);
        } else {
            await printEventScorecards(eventOrRound, eventData, contacts, groupCodes, undefined, notes, sponsorLogo);
        }
    } else {
        await printEventScorecards(eventOrRound, eventData, contacts, groupCodes, '', notes, sponsorLogo);
    }
}

function PrintScorecardsDialog(props: { eventOrRound: EventBase, eventData: EventData, onClose: () => void }) {
    const { eventData, eventOrRound, onClose } = props;
    const { event, golfers, teams, groups } = getEventStaff(eventOrRound.id, eventData);
    return <>
        <AddGolfersDialog
            open
            selectAll
            withNotes
            label={'Print Scorecards'}
            okLabel={'Print'}
            {...{ eventOrRound: event, teams, groups, golfers, roster: new Map(), loadedTeams: 1, loadedGroups: 1, loadedGolfers: 1, loadedRoster: 1 }}
            golferDB={'GOLFER_GROUPS'}
            handleAddGolfers={(contacts, notes) => printScorecards(eventOrRound, eventData, contacts, notes)}
            handleCancel={onClose}
        />
    </>;
};

function getContacts(groups: Array<GolferGroup>) {
    return groups.map(group => ({ id: group.id, name: '*', group } as ContactGroup));;
}

export async function printAllScorecards(eventData: EventData) {
    const { event, rounds } = eventData;
    if (event.type === 'multiday') {
        rounds.sort((a, b) => b.roundOrder - a.roundOrder).forEach(round => printScorecards(round, eventData, getContacts(getEventStaff(round.id, eventData).groups), event.scorecardNotes));
    } else {
        printScorecards(event, eventData, getContacts(getEventStaff(event.id, eventData).groups), event.scorecardNotes);
    }
}

export async function printScorecardsDlg(eventOrRound: EventBase, eventData: EventData) {
    const { event, golfers, teams, groups, competitions } = getEventStaff(eventOrRound.id, eventData);
    const eventInfo = getGolferInfo(event, competitions, golfers, teams, groups);
    const noLength = eventInfo.getTees().findIndex(t => !t.len || t.len.findIndex(l => l > 0) < 0) >= 0;
    const next = () => {
        const printPrompt = (
            <PrintScorecardsDialog
                eventData={eventData}
                eventOrRound={eventOrRound}
                onClose={() => hideAlert({ content: printPrompt, ownContent: true, buttons: [] })}
            />
        );
        showAlertProps({ content: printPrompt, ownContent: true, buttons: [] });
    }
    let scorecardDontShowTeeWarning = true;
    if (noLength && !event.scorecardDontShowTeeWarning) {
        showAlertProps({
            content: 'One or more of the tees is missing hole lengths, these tees will not be shown on the printed scorecards. Add lengths under settings, course, view/edit scorecard, select tee.',
            buttons: [{
                title: 'Cancel'
            }, {
                title: 'OK',
                color: 'secondary',
                action: async () => {
                    if (scorecardDontShowTeeWarning) {
                        await withProgress(Backend.updateOrAdd(Backend.eventsDb, { id: event.id, exists: true, scorecardDontShowTeeWarning: true }).catch());
                    }
                    next();
                }
            }],
            dontShowAgain: scorecardDontShowTeeWarning,
            dontShowHandler: v => scorecardDontShowTeeWarning = v
        });
    } else {
        next();
    }
}

interface State {
    scorecardDontShowTeeWarning?: boolean;
    proBanner?: boolean;
}

type Props = { eventData: EventData; } & WithStyles<typeof styles> & WithUserAware;

class PrintTab extends React.Component<Props, State> {
    state: State = {};

    private printScorecards = (roundOrder?: number) => () => {
        const { userAware, eventData } = this.props;
        if (userAware.hasPro) {
            const { event, rounds } = this.props.eventData;
            withProgress(printScorecardsDlg(rounds.find(round => round.roundOrder === roundOrder) ?? event, eventData));
        } else {
            this.setState({ proBanner: true });
        }
    }

    private printSchedule = (roundOrder?: number) => () => {
        const { userAware, eventData } = this.props;
        if (userAware.hasPro) {
            const { event, rounds } = this.props.eventData;
            withProgress(printSchedule(rounds.find(round => round.roundOrder === roundOrder) ?? event, eventData));
        } else {
            this.setState({ proBanner: true });
        }
    }

    private printResults = (roundOrder?: number) => () => {
        const { userAware, eventData } = this.props;
        if (userAware.hasPro) {
            const { event, rounds } = this.props.eventData;
            withProgress(printResults(rounds.find(round => round.roundOrder === roundOrder) ?? event, eventData));
        } else {
            this.setState({ proBanner: true });
        }
    }

    private printGolfers = () => {
        const { userAware, eventData } = this.props;
        if (userAware.hasPro) {
            const { event, rounds } = this.props.eventData;
            withProgress(printGolfers(rounds.find(round => round.roundOrder === 1) ?? event, eventData));
        } else {
            this.setState({ proBanner: true });
        }
    }

    private printCartSigns = (roundOrder?: number) => () => {
        const { userAware, eventData } = this.props;
        if (userAware.hasPro) {
            const { event, rounds } = this.props.eventData;
            withProgress(printCartSigns(rounds.find(round => round.roundOrder === roundOrder) ?? event, eventData));
        } else {
            this.setState({ proBanner: true });
        }
    }

    private printMoney = () => {
        const { userAware, eventData } = this.props;
        if (userAware.hasPro) {
            withProgress(printMoney(eventData));
        } else {
            this.setState({ proBanner: true });
        }
    }

    private eventOrRound() {
        const { event, rounds } = this.props.eventData;
        return rounds.length > 0 ? rounds[0] : event;
    }

    private handleCloseProPlan = (name?: AccountActions) => {
        const { userAware } = this.props;
        this.setState({ proBanner: false });
        accountAction(userAware, name);
    }

    render() {
        const { classes, eventData, userAware } = this.props;
        const { rounds, competitionsMap, golfersMap, groupsMap } = eventData;
        const { proBanner } = this.state;
        const eventOrRound = this.eventOrRound();
        const groups = groupsMap.get(eventOrRound.id) ?? [];
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        const competitions = competitionsMap.get(eventOrRound.id) ?? [];
        const noSchedule = groups.filter(g => g.contactIds.length > 0).length === 0;
        const noScorings = competitions.filter(comp => isMainCompetitionScoring(comp.scoring, true)).length === 0;
        const noGolfers = golfers.size === 0;
        const noMoney = golfers.size === 0 || getCompetitionsWithPayouts(competitions).length === 0;
        return (
            <div className={classes.listRootGrey}>
                <List disablePadding>
                    <Typography sx={{ fontWeight: 600 }}>GENERAL</Typography>
                    <ButtonBar>
                        <AppButton color="secondary" onClick={this.printGolfers} disabled={noGolfers}>
                            {!userAware.hasPro && <ProBadge small mr={4} disabled={noGolfers} />}
                            Print Golfers List
                        </AppButton>
                        {rounds.length === 0 && <>
                            <AppButton color="secondary" onClick={this.printScorecards()} disabled={noSchedule || noScorings}>
                                {!userAware.hasPro && <ProBadge small mr={4} disabled={noSchedule || noScorings} />}
                                Print Scorecards
                            </AppButton>
                            <AppButton color="secondary" onClick={this.printSchedule()} disabled={noSchedule}>
                                {!userAware.hasPro && <ProBadge small mr={4} disabled={noSchedule} />}
                                Print Schedule
                            </AppButton>
                            <AppButton color="secondary" onClick={this.printResults()} disabled={noScorings}>
                                {!userAware.hasPro && <ProBadge small mr={4} disabled={noScorings} />}
                                Print Results
                            </AppButton>
                            <AppButton color="secondary" onClick={this.printCartSigns()} disabled={noSchedule}>
                                {!userAware.hasPro && <ProBadge small mr={4} disabled={noSchedule} />}
                                Print Cart Signs
                            </AppButton>
                        </>}
                        <AppButton color="secondary" onClick={this.printMoney} disabled={noMoney}>
                            {!userAware.hasPro && <ProBadge small mr={4} disabled={noMoney} />}
                            Print Money List
                        </AppButton>
                    </ButtonBar >
                    {rounds.map(round => <div key={round.roundOrder}>
                        <Typography sx={{ fontWeight: 600 }}>ROUND {round.roundOrder}</Typography>
                        <ButtonBar>
                            <AppButton color="info" className={classes.buttonWhite} onClick={this.printScorecards(round.roundOrder)} disabled={noSchedule || noScorings}>
                                {!userAware.hasPro && <ProBadge small mr={4} disabled={noSchedule || noScorings} />}
                                Print Scorecards
                            </AppButton>
                            <AppButton color="info" className={classes.buttonWhite} onClick={this.printSchedule(round.roundOrder)} disabled={noSchedule}>
                                {!userAware.hasPro && <ProBadge small mr={4} disabled={noSchedule} />}
                                Print Schedule
                            </AppButton>
                            <AppButton color="info" className={classes.buttonWhite} onClick={this.printResults(round.roundOrder)} disabled={noScorings}>
                                {!userAware.hasPro && <ProBadge small mr={4} disabled={noScorings} />}
                                Print Results
                            </AppButton>
                            <AppButton color="info" className={classes.buttonWhite} onClick={this.printCartSigns(round.roundOrder)} disabled={noSchedule}>
                                {!userAware.hasPro && <ProBadge small mr={4} disabled={noSchedule} />}
                                Print Cart Signs
                            </AppButton>
                        </ButtonBar>
                    </div>)}
                </List>
                {proBanner &&
                    <ProPlanDialog handleClose={this.handleCloseProPlan} />}
            </div>
        );
    }
}

export default function(props: Omit<Props, 'classes' | 'userAware'>) {
    const classes = useAppStyles();
    const userAware = useUserAware();
    return <PrintTab classes={classes} userAware={userAware} {...props} />;
}
