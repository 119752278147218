import * as React from 'react';
import { Contact } from '../types/EventTypes';
import { fullName } from '../contact/Contact';
import { showAlert, showAlertProps } from '../redux/ReduxConfig';

export function showGolfersDeletionAlert(golfersCount: number, onRemove: () => void) {
    showAlertProps({
        appBarLabel: 'DELETE GOLFER(S)',
        content: <>
            Deleting will remove golfer(s) from the event.<br />
            Are you sure you want to delete?
        </>,
        buttons: [
            { title: 'Cancel' },
            { title: 'Delete', color: 'secondary', action: onRemove }
        ]
    });
}

export function showReportedGolfersDeletionAlert(reportedContacts: Array<Contact>, onRemove: () => void) {
    const reportedNames = reportedContacts.map(golfer => fullName(golfer)).join(', ');
    const golferLabel = reportedContacts.length === 1 ? 'this golfer' : 'these golfers';
    const alertText = `Scoring has already started for ${reportedContacts.length > 1 ? `the following golfers: ${reportedNames}` : reportedNames}. 
        Are you sure about removing ${golferLabel} from event?`;
    showAlert(alertText, [
        { title: 'Cancel' },
        { title: 'Proceed', color: 'secondary', action: onRemove }
    ]);
}