import * as React from 'react';
import { Typography, List } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import AddIcon from '@mui/icons-material/Add';
import * as Backend from '../../../../util/firebase';
import { Event, EventBase, EventData, MAX_ROUNDS, Round, createRound } from '../../../../types/EventTypes';
import { deleteEvent, undeleteEvent, elog, formatDateUniversal, addNewEventRound, setEventPublicId, updateEventRounds as updateEventAndRounds } from '../../../Event';
import { showError, NoticeElement, showAlertProps } from '../../../../redux/ReduxConfig';
import EditTextField from '../../../../common/form/EditTextField';
import { EditDateField } from '../../../../common/form/EditDateField';
import { required, maxLength } from '../../../../validation/ValidatedTextField';
import AppButton from '../../../../common/components/AppButton';
import { getEditIdField } from './EditIdField';
import { makeFriendlyString, getUTCMidnight, dbgLog } from '../../../../util/utility';
import { pushUrl } from '../../../../redux/ReduxConfig';
import CourseSelectionField from './course/CourseSelectionField';
import HandicapSystemSelectionField from './course/HandicapSystemSelectionField';
import RegistrationSettingsField from './RegistrationSettingsField';
import EventGenderSettingsField from './EventGenderSettingsField';
import { styles } from '../../../../styles';
import { EVENT_DATETIME_FORMAT_LOG, ITEM_NEW_EVENT_ID } from 'src/util/config';
import { Spacing } from 'src/common/Misc';
import RoundSelectionField from './course/RoundSelectionField';
import { withProgress } from 'src/util/ProgressPromise';
import PracticeRoundField from './PracticeRoundField';
import DuplicateEventDialog from 'src/event/list/DuplicateEventDialog';
import ProBadge from 'src/common/ProBadge';
import { AppColors } from 'src/main/Theme';
import { useUserAware } from 'src/auth/Auth';
import { AccountActions, accountAction } from 'src/auth/StripeRedirect';
import ProPlanDialog from 'src/auth/ProPlanDialog';

const eventIdWithExplanation = (val: string) => {
    const msg = "A default id is assigned to each event, and this can be changed at any time. Golfers use this id to find your event on golfpad.events. The default id will work in addition to any customized event id."
    return <>
        <Typography variant="body1" style={{ wordWrap: "break-word" }}>{val}</Typography>
        <Typography variant="caption">Golfers and spectators can find the event using this id <NoticeElement>{msg}</NoticeElement></Typography>
    </>;
};

const RoundFields = ({ event, rounds }: { event: Event, rounds: Array<Round> }) => {
    const userAware = useUserAware();
    const [proBanner, setProBanner] = React.useState(false);
    function handleCloseProPlan(name?: AccountActions) {
        setProBanner(false);
        accountAction(userAware, name);
    }
    function addRound() {
        if (userAware.hasPro) {
            withProgress(addNewEventRound(event, createRound(event, rounds), rounds))
        } else {
            setProBanner(true);
        }
    }
    const disabled = (rounds.length ?? 0) >= MAX_ROUNDS;
    return <>
        {rounds.map((round, idx) => <RoundSelectionField key={idx} event={event} round={round} rounds={rounds} />)}
        <Spacing height={6} />
        <AppButton color="inherit" onClick={addRound}
            disabled={disabled}>
            <AddIcon />Add round
            &nbsp;
            {!userAware.hasPro && <ProBadge small disabled={disabled} />}
        </AppButton>
        <Spacing height={12} />
        {proBanner &&
            <ProPlanDialog handleClose={handleCloseProPlan} />}
    </>;
}

interface Props {
    eventData: EventData;
}

interface State {
    duplicateEvent?: boolean;
}

class GeneralEventSettings extends React.Component<Props & WithStyles<typeof styles>, State> {
    state: State = {};

    componentDidMount() {
        Backend.trackEvent('view_general_settings');
    }

    private handlePublicId = (value: string) => {
        const { eventData } = this.props;
        return withProgress(setEventPublicId(eventData.event, eventData.rounds, value, () => showError(`${value} is already taken. Please pick a different id`)));
    }

    private handleSaveEventDate = (date: number) => {
        const { eventData } = this.props;
        const toSave = { id: eventData.event.id, exists: true } as Event;
        toSave.date = date;
        toSave.dateUTC = getUTCMidnight(date);
        dbgLog(`handleSaveEventDate ${toSave.date} - ${toSave.dateUTC}`)
        Backend.updateOrAdd(Backend.eventsDb, toSave).then(() => elog(eventData.event, `Date modified`, formatDateUniversal(date, EVENT_DATETIME_FORMAT_LOG), `Id: ${eventData.event.id}`));
    }

    private handleSave = (key: keyof EventBase) => async (value: any) => {
        const { eventData } = this.props;
        await withProgress(updateEventAndRounds(eventData.event, eventData.rounds, key, value));
        elog(eventData.event, `${makeFriendlyString(key, true)} modified`, `${makeFriendlyString(key, true)}: ${value}`, `Id: ${eventData.event.id}`);
    }

    private handleDeleteEvent = () => {
        showAlertProps({
            content: `This event will be permanently deleted, including all settings, schedule, scoring, history, public user Event site, leaderboards and all other information. This operation can not be un-done. Click CONFIRM to proceed with deletion or CANCEL to keep this event.`,
            appBarLabel: 'Confirm permanent delete',
            buttons: [
                { title: 'Cancel' },
                { title: 'Confirm and delete', color: 'secondary', action: () => this.confirmedDeleteEvent() }
            ]
        });
    }

    private confirmedDeleteEvent = () => {
        const { eventData } = this.props;
        deleteEvent(eventData.event, eventData.rounds)
            .catch(err => showError('Error during event delete: ' + err))
            .then(() => { showError('Event has been deleted'); pushUrl('/events'); });
    }

    private handleUndeleteEvent = () => {
        const { eventData } = this.props;
        undeleteEvent(eventData.event, eventData.rounds)
            .catch(err => showError('Error during event undelete: ' + err))
            .then(() => showError('Event has been undeleted'));
    }

    render() {
        const { classes, eventData } = this.props;
        const { duplicateEvent } = this.state;
        const { event } = eventData;
        const EditIdField = getEditIdField(eventIdWithExplanation);
        return <>
            <List className={classes.listRootGreyRound}>
                <EditTextField
                    textFieldProps={{}}
                    label="Event name"
                    value={event.name}
                    rules={[required, maxLength(100)]}
                    save={this.handleSave('name')} />
                <EditIdField
                    textFieldProps={{}}
                    label="Event id"
                    value={event.publicId}
                    save={() => { }}
                    savePromise={this.handlePublicId}
                    rules={[{
                        valid: (value?: string) => !!value && (event.type === 'leaderboard' || event.leaderboard || !value.trim().startsWith('LL-')),
                        text: 'Regular event\'s public id can not start with LL- prefix'
                    }]} />
                {event.type !== 'multiday' && <EditDateField
                    label="Date"
                    value={event.date}
                    save={this.handleSaveEventDate} />}
                {event.type === 'multiday' ?
                    <RoundFields event={event} rounds={eventData.rounds} /> :
                    <CourseSelectionField eventOrRound={event} eventData={eventData} />}
                {event.type === 'multiday' && <PracticeRoundField event={event} />}
                <HandicapSystemSelectionField eventData={eventData} />
                <EventGenderSettingsField event={event} />
                <RegistrationSettingsField event={event} />
                <Spacing height={6} />
                {event.deleted
                    ? <AppButton color="secondary" onClick={this.handleUndeleteEvent}>
                        <DeleteIcon className={classes.leftButtonIcon} />
                        Undelete event
                    </AppButton>
                    : <AppButton color="secondary" onClick={this.handleDeleteEvent}>
                        <DeleteIcon className={classes.leftButtonIcon} />
                        Delete event
                    </AppButton>}
                &nbsp;&nbsp;&nbsp;
                <AppButton sx={{ backgroundColor: AppColors.webGreen500 }} onClick={() => this.setState({ duplicateEvent: true })}>
                    <ProBadge small inv mr={4} />
                    Duplicate
                </AppButton>
                {duplicateEvent && <DuplicateEventDialog
                    event={event}
                    handleClose={(addedId?: string) => {
                        this.setState({ duplicateEvent: false });
                        if (addedId) {
                            localStorage.setItem(ITEM_NEW_EVENT_ID, addedId);
                            window.location.replace(`/events/${addedId}`);
                        }
                    }} />}
            </List>
        </>;
    }
}

export default withStyles(styles)(GeneralEventSettings);
