import React from 'react';
import {
    Box,
    ButtonGroup,
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemButton,
    Menu,
    MenuItem,
    Switch,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningAmberRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DoneIcon from '@mui/icons-material/DoneRounded';
import { CSVLink } from 'react-csv';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import * as Utils from '../../../../util/utility';
import * as Backend from '../../../../util/firebase';
import { errMsg } from '../../../../util/firebase';
import {
    ACTION_ADD_FROM_ROSTER,
    ACTION_GOLFER_ADDED,
    ACTION_GOLFER_MODIFIED,
    AutoSchedule,
    Contact,
    ContactDetails,
    ContactInvite,
    Distance,
    Event,
    EventBase,
    EventData, getEventStaff, GHINGolfer,
    isMainCompetitionScoring,
    isNetMode,
    isPastEvent,
    PaymentInfo,
    ReportedScore,
    ResultStatus,
    rollEvents,
    Score,
    SpreadsheetImportResult,
    Tee
} from '../../../../types/EventTypes';
import {
    deleteGolfersFromEvent,
    saveContact,
    saveContactDetails,
    saveContacts,
    scheduleGroupsAndSave,
} from '../../../Event';
import {
    compareContacts,
    compareContactsBy,
    contactFromRoaster,
    equalContactData,
    formatHandicap,
    fullLastName,
    getSameNameGolfersIds,
    hasContactTees,
    newContact
} from '../../../../contact/Contact';
import AddGolfersFromRosterDialog from '../../common/AddGolfersFromRosterDialog';
import GolfersEmailDialog from '../../common/GolfersEmailDialog';
import EditContactDialog from '../../../../contact/EditContactDialog';
import ImportContactDialog from '../../../../contact/import/ImportContactDialog';
import ImportReviewDialog from '../../../../contact/import/ImportReviewDialog';
import ButtonBar from '../../../../common/components/ButtonBar';
import AppButton from '../../../../common/components/AppButton';
import SelectTeesComponent from '../../common/SelectTeesComponent';
import { Container, EditIcon, Flex, Item, Spacing } from '../../../../common/Misc';
import { styles, useAppStyles } from '../../../../styles';
import { DoubleArrowIcon, InfoIcon, PlusIcon } from '../../../../common/Icons';
import { Opts, Urls } from "../../../../util/config";
import { isValidHCPIndex } from "../../../../scoring/handicap";
import { showGolfersDeletionAlert, showReportedGolfersDeletionAlert } from "../../../../util/common_alerts";
import {
    InfoElement,
    NoticeElement,
    ProgressFunction,
    showAlertProps,
    showProgress
} from '../../../../redux/ReduxConfig';
import { AppColors } from '../../../../main/Theme';
import TeesProvider from "../../common/TeesProvider";
import { FirebaseDataComponent } from "../../../../common/WithData";
import { SendPaymentMessageDialog } from "../../../../payments/paypal/SendPaymentMessageDialog";
import { withProgress } from 'src/util/ProgressPromise';
import { printGolfers } from '../../tools/PrintTab';
import { EmailVariant, sendInviteEmail, sendPaymentMessage } from "../../../../util/email_utils";
import CurrencyList from "currency-list";
import { PaidStatusSelectionComponent } from "../../../../payments/PaidStatusSelectionComponent";
import InvitesEmailDialog from "../../common/InvitesEmailDialog";
import InviteGolfersDialog from '../../common/InviteGolfersDialog';
import { getAvailableColor } from 'src/util/colors';
import { useUserAware, WithUserAware } from 'src/auth/Auth';
import ProBadge from 'src/common/ProBadge';
import ProPlanDialog from 'src/auth/ProPlanDialog';
import { accountAction, AccountActions } from 'src/auth/StripeRedirect';
import { findAndUpdateGhinGolfers } from "../../../../ghin/GhinGolfers";
import { formatDateUS } from "../../../../util/utility";
const USGALabelVertical = '/img/USGAWordMarkVertical.svg';
const outOfDateIcon = '/img/outofdate.svg';
const upToDateIcon = '/img/uptodate.svg';

function showGhinRefreshAlert(classes: any, onRefresh: () => void) {
    showAlertProps({
        content: <div style={{ display: 'table' }}>
            <span style={{ verticalAlign: 'text-bottom', display: 'table-cell' }}>
                <InfoIcon style={{ verticalAlign: '-webkit-baseline-middle', paddingRight: '8px', paddingTop: '4px' }} htmlColor={AppColors.webWarning} />
            </span>
            <span style={{ verticalAlign: 'bottom', display: 'table-cell' }}>
                Refreshing handicaps may require adjustments to established competitions. Please check to ensure your
                competitions and flight settings after the update.
            </span>
        </div>
        ,
        buttons: [
            {
                title: 'Cancel'
            },
            { title: 'Refresh Index', color: 'secondary', action: onRefresh }
        ]
    });
}

function showGhinRefreshSuccessAlert(classes: any) {
    showAlertProps({
        content: <div style={{ textAlign: 'center', minWidth: '360px' }}>
            <DoneIcon fontSize="large" htmlColor={AppColors.webGreen300} />
            <Typography variant="h6">
                Success!
            </Typography>
            <Typography variant="body2" style={{ paddingTop: '8px' }}>
                Player handicaps successfully updated
            </Typography>
        </div>
    });
}

function showGhinRefreshWarningAlert(classes: any) {
    showAlertProps({
        content: <div style={{ textAlign: 'center', minWidth: '360px' }}>
            <WarningIcon fontSize="large" htmlColor={AppColors.webWarning} />
            <Typography variant="h6">
                Something went wrong
            </Typography>
            <Typography variant="body2" style={{ paddingTop: '8px' }}>
                Please, try again later.
            </Typography>
        </div>
    });
}

export async function refreshGHINHandicaps(event: Event, contacts: Array<ContactDetails>) {
    const updatedContacts: ContactDetails[] = [];
    const contactsWithHandicapIdMap = new Map<string, ContactDetails>(
        contacts.filter(contact => contact.handicapId?.length).map(contact => [contact.handicapId!, contact])
    );
    try {
        const ghins = await findAndUpdateGhinGolfers(new Set(contactsWithHandicapIdMap.keys()));
        ghins.forEach((ghin: GHINGolfer, key: string) => {
            const contact = contactsWithHandicapIdMap.get(key);
            if (ghin && isValidHCPIndex(ghin.handicapIndex) && contact && contact.handicapIndex !== ghin.handicapIndex) {
                contact.handicapIndex = ghin.handicapIndex;
                updatedContacts.push(contact);
            }
        });
        if (updatedContacts.length > 0) {
            await saveContacts(event, updatedContacts, ACTION_GOLFER_MODIFIED);
            return Promise.resolve(`${updatedContacts.length} contacts updated.`);
        } else {
            return Promise.reject('No contacts updated');
        }
    } catch (err) {
        console.log(errMsg(err));
        return Promise.reject(errMsg(err))
    }
}

interface ContactItemProps {
    contact: ContactDetails;
    clickHandler: (onRevokeSuccess: () => void, contact: ContactDetails) => void;
    cbClickHandler: (contact: ContactDetails) => void;
    selected: boolean;
    withTee?: boolean;
    withHomeCourseOrCity?: boolean;
    zeroHandicapIconAllowed: boolean;
    ghin?: GHINGolfer;
    linkedFromApp: boolean;
    paymentInfo?: PaymentInfo;
    onRevokeSuccess: () => void;
    showPaymentInfo?: boolean;
    feePaid?: boolean;
    eventOrRounds: Array<EventBase>;
    handleSendMessage: (contact: Contact) => void;
    handleFeePaidStatusUpdate: (feePaid: boolean) => Promise<void>;
}

type ContactItemHeaderProps = {
    roundsNum: number;
    selectedAll: boolean;
    selectedAny: boolean;
    withTee?: boolean;
    showPaymentInfo?: boolean;
    paymentColumnEnabled?: boolean;
    sortCol?: string;
    indexNoticeText?: string;
    asc?: boolean;
    selectAll: (checked: boolean) => void;
    onRequestClick: () => void;
    onSortColumn: (column: string) => void;
};

const toolTipPopperProps = {
    modifiers: [{
        name: "offset",
        options: {
            offset: [0, -10]
        },
    }]
};

const EmptyIconBox = () => <Box height={16} width={16} />;

const ContactItemHeader = (props: ContactItemHeaderProps) => {
    const classes = useAppStyles();
    const { roundsNum, withTee, selectedAll, selectedAny, showPaymentInfo, paymentColumnEnabled, asc, sortCol, indexNoticeText, onRequestClick, onSortColumn } = props;
    const checkBox = <Checkbox color="secondary" checked={selectedAll} indeterminate={selectedAny && !selectedAll} onChange={(_e: any, v: any) => props.selectAll(v)} disableRipple />;
    const rightIcon = <EditIcon invisible />;
    const nameXs = 6 - ((withTee ? roundsNum : 0) + (showPaymentInfo ? 2 : 0));
    const sortIcon = <DoubleArrowIcon isUp={asc !== false} />;
    //const indexNotice = <NoticeElement>{indexNoticeText}</NoticeElement>
    const [hoveredCol, setHoveredCol] = React.useState<string>('');
    return <>
        <ListItem className={classes.listItemHeaderWhite}>
            <Container wrap="nowrap">
                <Item xs={nameXs} noWrap variant="body2">
                    <Box display="flex" flexDirection="row" alignItems="center"
                        onMouseEnter={() => setHoveredCol('name')} onMouseLeave={() => setHoveredCol('')}>
                        {checkBox}
                        <LightTooltip PopperProps={toolTipPopperProps} disableInteractive placement="top"
                            title={(sortCol === 'name' || !sortCol) && asc === false ? 'Z-A' : 'A-Z'}>
                            <Box display="flex" flexDirection="row" alignItems="center"
                                onClick={() => { onSortColumn('name'); }}>
                                Name {sortCol === 'name' || hoveredCol === 'name' || !sortCol ? sortIcon : <EmptyIconBox />}
                            </Box>
                        </LightTooltip>
                    </Box>
                </Item>
                <Item xs={3} noWrap variant="body2" onClick={() => { onSortColumn('email'); }}>
                    <LightTooltip PopperProps={toolTipPopperProps} disableInteractive placement="top"
                        title={sortCol !== 'email' || asc ? 'A-Z' : asc === false ? 'Z-A' : ''}>
                        <Box display="flex" flexDirection="row" alignItems="center"
                            onMouseEnter={() => setHoveredCol('email')} onMouseLeave={() => setHoveredCol('')}>
                            Email {sortCol === 'email' || hoveredCol === 'email' ? sortIcon : <EmptyIconBox />}
                        </Box>
                    </LightTooltip>
                </Item>
                <Item xs={2} noWrap variant="body2" onClick={() => { onSortColumn('index'); }}>
                    <LightTooltip PopperProps={toolTipPopperProps} disableInteractive placement="top"
                        title={indexNoticeText}>
                        <Box display="flex" flexDirection="row" alignItems="center"
                            onMouseEnter={() => setHoveredCol('index')} onMouseLeave={() => setHoveredCol('')}>
                            Index&nbsp;{<InfoIcon color='secondary' />}&nbsp;{sortCol === 'index' || hoveredCol === 'index' ? sortIcon : <EmptyIconBox />}
                        </Box>
                    </LightTooltip>
                </Item>
                {showPaymentInfo && <Item noWrap variant="body2">
                    Entry fee&nbsp;<span onClick={onRequestClick} style={{
                        color: paymentColumnEnabled ? AppColors.webBlue500 : '#C4C4C4', fontWeight: 400,
                        cursor: paymentColumnEnabled ? 'pointer' : 'unset'
                    }}>(Request)</span>
                </Item>}
                {withTee && Utils.range(1, roundsNum + 1).map(roundNum =>
                    <Item key={roundNum} xs={1} noWrap variant="body2" onClick={() => { props.onSortColumn('tee'); }}>
                        <LightTooltip PopperProps={toolTipPopperProps} disableInteractive placement="top"
                            title={sortCol !== 'tee' || asc ? 'A-Z' : asc === false ? 'Z-A' : ''}>
                            <Box display="flex" flexDirection="row" alignItems="center"
                                onMouseEnter={() => setHoveredCol('tee')} onMouseLeave={() => setHoveredCol('')}>
                                Tee {roundsNum > 1 ? `R${roundNum}` : ''} {sortCol === 'tee' || hoveredCol === 'tee' ? sortIcon : <EmptyIconBox />}
                            </Box>
                        </LightTooltip>
                    </Item>)
                }
                <Item xs={1} noWrap variant="body2" placeRight className={classes.colorWhite}>{rightIcon}</Item>
            </Container>
        </ListItem>
        <Divider />
        <Divider />
    </>;
};

const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 10,
        lineHeight: '150%',
        color: '#343434',
        boxShadow: theme.shadows[1],
        borderRadius: 12
    },
}))(Tooltip);

const ContactItem = withStyles(styles)((props: ContactItemProps & WithStyles<typeof styles>) => {
    const { selected, contact, classes, withTee, withHomeCourseOrCity, zeroHandicapIconAllowed, paymentInfo,
        linkedFromApp, ghin, showPaymentInfo, feePaid, cbClickHandler, clickHandler, onRevokeSuccess,
        handleSendMessage, handleFeePaidStatusUpdate, eventOrRounds
    } = props;
    const rightIcon = <EditIcon />;
    const decorations = classes.listItem + (selected ? (' ' + classes.selected) : '');
    const checkBox = (
        <Checkbox color="secondary" checked={selected} style={{ backgroundColor: 'inherit', color: 'inherit', }}
            onClick={e => { e.preventDefault(); e.stopPropagation(); cbClickHandler(contact); }} disableRipple />
    );
    let email = contact.email;
    const handicapItem = formatHandicap(contact.handicapIndex) ?? (zeroHandicapIconAllowed && <div className={classes.flexCenteredRow21px}>0<InfoIcon className={classes.smallerShiftedIcon} htmlColor={AppColors.webWarning} /></div>);
    let teeRoundIdx = 0;
    if (eventOrRounds.length > 1) {
        const today = Utils.getUserToday();
        while (teeRoundIdx < eventOrRounds.length && isPastEvent(eventOrRounds[teeRoundIdx], today)) {
            teeRoundIdx++;
        }
    }
    const nearestTee = teeRoundIdx < eventOrRounds.length ? (contact.roundTees ?? {})[eventOrRounds[teeRoundIdx].id] : undefined;
    const teeName = nearestTee?.name;
    const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
    const nameXs = 6 - ((withTee ? eventOrRounds.length : 0) + (showPaymentInfo ? 2 : 0));
    const currencySymbol = paymentInfo?.currencyCode ? (CurrencyList.get(paymentInfo.currencyCode).symbol ?? '$') : '$';
    const currencyString = currencySymbol.length > 1 ? currencySymbol + ' ' : currencySymbol;
    const teeColor = getAvailableColor(teeName);
    const isIndexUpToDate = ghin && ghin.handicapIndex === contact.handicapIndex;
    return <>
        <ListItemButton className={decorations} onClick={() => clickHandler(onRevokeSuccess, contact)}>
            <Container wrap="nowrap">
                <Item xs={nameXs} paddingRight={4}>
                    <div className={classes.flexCenteredRow}>
                        {checkBox}&nbsp;
                        <div>
                            <Flex>
                                {fullLastName(contact)}
                                &nbsp;
                                <span className={classes.homeCourseOrCity}>
                                    {(withHomeCourseOrCity && contact.homeCourseOrCity) ? ` (${contact.homeCourseOrCity})` : ''}
                                </span>
                                {linkedFromApp &&
                                    <LightTooltip disableInteractive placement={'right'} title="Connected with the golfers Golf Pad app">
                                        <img className={classes.connectedPhoneIcon} src={Urls.connectedPhoneIcon} alt={''} />
                                    </LightTooltip>}
                            </Flex>
                            {teeName && <Flex>
                                {teeColor && <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: teeColor, border: '1px solid black' }} />}
                                &nbsp;
                                <Typography sx={{ fontSize: '11px', color: AppColors.webGrey300 }}>
                                    {teeName} tee
                                </Typography>
                                &nbsp;
                            </Flex>}
                        </div>
                    </div>
                </Item>
                <Item xs={3} paddingRight={4}>{email}</Item>
                <Item xs={2}>{ghin ? <div className={classes.flexCenteredRow21px}>
                    <span>{handicapItem}</span>
                    <LightTooltip disableInteractive placement={'bottom'}
                        title={isIndexUpToDate ? "Received from USGA information network" : "Updated on: " + formatDateUS(ghin.lastUpdate)}>
                        <Box paddingTop={0.8}>
                            <img style={{ marginLeft: isXs ? 4 : 8, width: 16, height: 16 }}
                                src={isIndexUpToDate ? upToDateIcon : outOfDateIcon} alt={''} />
                            <img style={{ marginLeft: isXs ? 4 : 8, width: 16, height: 16 }}
                                src={USGALabelVertical} alt={''} />
                        </Box>
                    </LightTooltip>
                </div> : handicapItem}</Item>
                {showPaymentInfo && <Item xs={2}>{paymentInfo?.platform === 'PayPal' ?
                    <Box display="flex" alignItems="center" height={20}>
                        <span style={{ fontWeight: 500 }}>Paid</span>
                        <LightTooltip placement="right" title={`Paid ${currencyString}${paymentInfo.feeCost} with PayPal${paymentInfo.platformFee ? ` (including ${currencyString}${paymentInfo.platformFee} PayPal fee)` : ''}`}>
                            <img className={classes.connectedPhoneIcon} src={Urls.iconPayPalLogo} alt="" />
                        </LightTooltip>
                    </Box> :
                    <PaidStatusSelectionComponent feePaid={feePaid} contactEmail={contact.email}
                        styles={{ fontSize: '14px', lineHeight: '21px', fontWeight: 500 }}
                        disableUnderline
                        handleSendMessage={() => handleSendMessage(contact)}
                        handleFeePaidStatusUpdate={handleFeePaidStatusUpdate} />}
                </Item>}
                {withTee && eventOrRounds.map(eventOrRound =>
                    <Item key={eventOrRound.id} xs={1}>
                        {(contact.roundTees ?? {})[eventOrRound.id]?.name ?? 'Default'}
                    </Item>)}
                <Item xs={1} placeRight className={classes.colorWhite}>{rightIcon}</Item>
            </Container>
        </ListItemButton>
        <Divider />
    </>;
});

interface State {
    importingFromRoster: boolean;
    importingFromSpreadsheet: boolean;
    actionChangeTeeOpen: boolean;
    actionMenuOpen: boolean;
    actionNewEmailOpen: boolean;
    addGolfersMenuOpen: boolean;
    selectMenuOpen: boolean;
    editedContactDetails?: ContactDetails;
    selectedGolfers: Set<string>;
    inviteDialogOpen: boolean;
    inviteEmailDialogOpen: boolean;
    selectedAll: boolean;
    importResult?: SpreadsheetImportResult;
    handleTeamExport: boolean;
    handleGolferExport: boolean;
    proBanner?: boolean;
    onRevokeSuccess: () => void;
    tees?: Tee[];
    payments?: Map<string, PaymentInfo>;
    golferScores: Map<string, Score>;
    teamScores: Map<string, Score>;
    reportedScores: Map<string, ReportedScore>;
    reportedTeamScores: Map<string, ReportedScore>;
    distances: Map<string, Distance>;
    sortCol?: string;
    asc?: boolean;
    inviteRecipients?: ContactDetails[];
}

type Props = { eventData: EventData; } & WithStyles<typeof styles> & WithUserAware;

class GolfersList extends React.Component<Props, State> {
    private readonly teesLoader: React.RefObject<TeesProvider> = React.createRef();
    private readonly addMenuRef: React.RefObject<HTMLDivElement> = React.createRef();
    private readonly selectMenuRef: React.RefObject<HTMLButtonElement> = React.createRef();
    private readonly exportMenuRef: React.RefObject<HTMLButtonElement> = React.createRef();
    state: State = {
        importingFromRoster: false,
        importingFromSpreadsheet: false,
        actionMenuOpen: false,
        actionNewEmailOpen: false,
        selectMenuOpen: false,
        addGolfersMenuOpen: false,
        actionChangeTeeOpen: false,
        selectedGolfers: new Set(),
        inviteDialogOpen: false,
        inviteEmailDialogOpen: false,
        selectedAll: false,
        handleGolferExport: false,
        handleTeamExport: false,
        onRevokeSuccess: () => { },
        golferScores: new Map(),
        teamScores: new Map(),
        reportedScores: new Map(),
        reportedTeamScores: new Map(),
        distances: new Map()
    };

    componentDidMount() {
        Backend.trackEvent('view_golfers');
    }

    private onDistances = (distances: Map<string, Distance>) => this.setState({ distances });
    private onTeamScores = (scores: Map<string, Score>) => this.setState({ teamScores: scores });
    private onGolferScores = (scores: Map<string, Score>) => this.setState({ golferScores: scores });
    private onReportedGolferScores = (reportedScores: Map<string, ReportedScore>) => this.setState({ reportedScores });
    private onReportedTeamScores = (reportedTeamScores: Map<string, ReportedScore>) => this.setState({ reportedTeamScores });
    private handleGolferExport = () => this.setState({ actionMenuOpen: false, handleGolferExport: true });
    private handleAddGolferClick = () => this.setState({ addGolfersMenuOpen: false, editedContactDetails: newContact(this.props.eventData.event) });
    private handleAddFromRosterClick = () => this.setState({ addGolfersMenuOpen: false, importingFromRoster: true });
    private handleAddFromSpreadsheet = () => this.setState({ addGolfersMenuOpen: false, importingFromSpreadsheet: true });
    private handleImportClick = () => this.setState({ addGolfersMenuOpen: !this.state.addGolfersMenuOpen });
    private handleImportClose = () => this.setState({ addGolfersMenuOpen: false });
    private handleSelectClick = () => this.setState({ selectMenuOpen: !this.state.selectMenuOpen });
    private handleSelectClose = () => this.setState({ selectMenuOpen: false });
    private handleActionsClick = () => this.setState({ actionMenuOpen: true });
    private handleInviteClick = () => this.setState({ inviteDialogOpen: true });
    private handleInviteClose = () => this.setState({ inviteDialogOpen: false, inviteRecipients: [], inviteEmailDialogOpen: false });
    private handleReviewInviteClose = () => this.setState({ inviteDialogOpen: true, inviteEmailDialogOpen: false });
    private handleActionNewEmail = () => this.setState({ actionMenuOpen: false, actionNewEmailOpen: true, handleGolferExport: false });
    private handleActionChangeTee = () => this.setState({ actionMenuOpen: false, actionChangeTeeOpen: true, handleGolferExport: false });
    private handleActionNewEmailSent = () => this.setState({ actionNewEmailOpen: false });
    private handleActionNewEmailCanceled = () => this.setState({ actionNewEmailOpen: false });
    private handleCloseEditDialog = () => this.setState({ editedContactDetails: undefined });
    private handleCancelFromRoster = () => this.setState({ importingFromRoster: false, importingFromSpreadsheet: false });
    private handleContactDeleted = (golfers: Array<Contact>) => this.deleteGolfersFromEvent(golfers);
    private handleActionsClose = () => this.setState({ actionMenuOpen: false, handleGolferExport: false });
    private handleSelectAll = () => this.selectAllGolfers(true);
    private handleSelectNone = () => this.selectAllGolfers(false);
    private onEventsPaymentsInfo = (payments: Map<string, PaymentInfo>) => this.setState({ payments });

    private handleGolferRowClick = (onRevokeSuccess: () => void, contact: ContactDetails) => {
        const editedContactDetails: ContactDetails = { ...contact, tee: undefined };
        this.setState({ editedContactDetails, onRevokeSuccess });
    }

    private teesLoaded = (loadStatus: ResultStatus, tees?: Tee[]) => {
        if (loadStatus === 'ok') {
            this.setState({ tees });
        }
    };

    private onSelectTees = async (selectedGolfers: Array<Contact>, menTee?: Tee, womenTee?: Tee) => {
        const { event } = this.props.eventData;
        selectedGolfers.filter(g => g.gender === 'male').forEach(g => g.tee = menTee);
        selectedGolfers.filter(g => g.gender === 'female').forEach(g => g.tee = womenTee);
        await Backend.updateOrAddBatch(Backend.golferDb(event.id), selectedGolfers, true);
        this.setState({ actionChangeTeeOpen: false });
    }

    private handleGolferCbChange = (contact: ContactDetails) => {
        const { golfersAggregated } = this.props.eventData;
        let { selectedAll, selectedGolfers } = this.state;
        if (selectedGolfers.has(contact.id)) {
            selectedGolfers.delete(contact.id);
        } else {
            selectedGolfers.add(contact.id);
        }
        if (selectedGolfers.size === 0) {
            this.handleActionsClose();
        }
        selectedAll = selectedGolfers.size === golfersAggregated.size;
        this.setState({ selectedGolfers, selectedAll });
    }

    private selectAllGolfers = (selectedAll: boolean) => {
        const selectedGolfers = new Set<string>();
        if (selectedAll) {
            const { golfersAggregated } = this.props.eventData;
            golfersAggregated.forEach(golfer => selectedGolfers.add(golfer.id));
        }
        this.setState({ selectedGolfers, selectedAll, selectMenuOpen: false });
    }

    private handleSortGolfers = (column: string) => {
        let { sortCol, asc } = this.state;
        if (sortCol === column || (sortCol === undefined && column === 'name')) {
            asc = asc === undefined ? false : !asc;
        } else {
            sortCol = column;
            asc = true;
        }
        this.setState({ sortCol, asc });
    }

    private handleActionsDelete = () => {
        this.setState({ actionMenuOpen: false });
        if (this.state.actionMenuOpen) {
            const { golfersAggregated } = this.props.eventData;
            const { selectedGolfers } = this.state;
            const selectedGolfersList = Array.from(golfersAggregated.values()).filter(golfer => selectedGolfers.has(golfer.id));
            const reportedGolfers = selectedGolfersList.filter(golfer => !!golfer.reportedBy);
            if (reportedGolfers.length > 0) {
                showReportedGolfersDeletionAlert(reportedGolfers, () => this.deleteGolfersFromEvent(selectedGolfersList));
            } else {
                showGolfersDeletionAlert(selectedGolfersList.length, () => this.deleteGolfersFromEvent(selectedGolfersList));
            }
        }
    }

    private deleteGolfersFromEvent = async (golfersToDelete: Array<Contact>) => {
        const { event } = this.props.eventData;
        try {
            await withProgress(deleteGolfersFromEvent(event, golfersToDelete, true));
            this.setState({ actionMenuOpen: false, selectedGolfers: new Set<string>(), handleGolferExport: false });
        } catch {
            this.setState({ actionMenuOpen: false });
        }
    }

    private handleContactChanged = async (contactDetail: ContactDetails, notificationLess: boolean, initialContact?: ContactDetails) => {
        const { event, rounds } = this.props.eventData;
        if (initialContact && equalContactData(contactDetail, initialContact)) {
            /*const golfer = golfers.get(contactDetail.id);
            if (golfer && contactDetail.feePaid !== golfer.feePaid) {
                golfer.feePaid = contactDetail.feePaid;
                golfers.set(contactDetail.id, golfer);
            }*/
            await saveContactDetails(event, rounds, contactDetail, ACTION_GOLFER_MODIFIED);
        } else {
            await saveContact(event, contactDetail, contactDetail.id ? ACTION_GOLFER_MODIFIED : ACTION_GOLFER_ADDED, notificationLess);
        }
        this.setState({ editedContactDetails: undefined });
    }

    private handleAddFromRoster = (contacts: Array<ContactDetails>, notifyGolfers: boolean) => {
        const { event } = this.props.eventData;
        if (contacts && contacts.length) {
            const newGolfers = contacts.map(contactFromRoaster);
            saveContacts(event, newGolfers, ACTION_ADD_FROM_ROSTER, !notifyGolfers)
                .then(() => this.setState({ importingFromRoster: false }));
        } else {
            this.setState({ importingFromRoster: false });
        }
    }

    private handleImportResults = (result: SpreadsheetImportResult, progress?: ProgressFunction) => {
        if (progress) {
            progress();
        }
        if (result && result.contacts && result.contacts.length) {
            this.setState({ importResult: result });
        } else {
            const error = 'Unable to process the import. Please verify data format.';
            this.setState({ importResult: { added: 0, updated: 0, rejected: 0, contacts: [], error: error, statuses: new Map() } });
        }
        this.setState({ importingFromSpreadsheet: false });
    }

    private handleFinishImportResults = () => {
        this.setState({ importResult: undefined });
    }

    private eventOrRound() {
        const { event, rounds } = this.props.eventData;
        return rounds.length > 0 ? rounds[0] : event;
    }

    private getStaff() {
        const { eventData } = this.props;
        const eventOrRound = this.eventOrRound();
        return getEventStaff(eventOrRound.id, eventData);
    }

    private printGolferList = () => {
        const { userAware, eventData } = this.props;
        if (userAware.hasPro) {
            withProgress(printGolfers(this.eventOrRound(), eventData));
        } else {
            this.setState({ proBanner: true });
        }
    }

    private contactsComparator = (c1: ContactDetails, c2: ContactDetails) => {
        const { sortCol, asc } = this.state;
        return compareContactsBy(c1, c2, sortCol || 'name', asc === undefined ? true : asc)
    };

    private exportGolferData() {
        const { golfersAggregated } = this.props.eventData;
        const { selectedGolfers } = this.state;
        const selectedGolfersList = Array.from(golfersAggregated.values()).filter(golfer => selectedGolfers.has(golfer.id));
        const withTees = selectedGolfersList.findIndex(hasContactTees) >= 0;
        const exportHeaders = ['Email', 'FirstName', 'LastName', 'Gender', 'Handicap'];
        if (withTees) {
            exportHeaders.push('Tee');
        }
        const exportData: string[][] = [];
        exportData.push(exportHeaders);
        selectedGolfersList.sort((c1, c2) => this.contactsComparator(c1, c2)).forEach((golfer) => {
            const exportRow: string[] = [];
            const teeName = golfer!.tee === null || golfer!.tee === undefined ? 'Default' : golfer!.tee.name;
            exportRow.push(golfer?.email || '');
            exportRow.push(golfer?.firstName || '');
            exportRow.push(golfer?.lastName || '');
            exportRow.push(golfer?.gender || '');
            exportRow.push(!!golfer?.handicapIndex ? String(golfer!.handicapIndex) : '0.0');
            if (withTees) {
                exportRow.push(teeName);
            }
            exportData.push(exportRow);
        });
        return exportData;
    }

    private updateGolferFeePaidStatus = (eventId: string, userId: string) => async (feePaid: boolean) => {
        await Backend.update(Backend.golferDb(eventId), { id: userId, feePaid } as Contact);
    };

    private setAutoScheduleSave = async (autoSchedule: AutoSchedule) => {
        const { eventData } = this.props;
        const hideProgress = showProgress('setAutoScheduleSave');
        try {
            await Backend.update(Backend.eventsDb, { id: eventData.event.id, autoSchedule });
            if (autoSchedule === 'ON') {
                await scheduleGroupsAndSave(eventData.event, eventData, 'random', 'random', false);
            }
        } finally {
            hideProgress();
        }
    }

    private refreshGHINHandicaps = async () => {
        const { userAware, classes } = this.props;
        if (userAware.hasPro) {
            showGhinRefreshAlert(classes, this.doRefreshHandicaps)
        } else {
            this.setState({ proBanner: true });
        }
    }

    private doRefreshHandicaps = async () => {
        const { classes } = this.props;
        const { event, golfersAggregated } = this.props.eventData;
        refreshGHINHandicaps(event, Array.from(golfersAggregated.values()))
            .then(() => showGhinRefreshSuccessAlert(classes))
            .catch(e => showGhinRefreshWarningAlert(classes))
    }


    private Buttons = () => {
        const { classes, userAware, eventData } = this.props;
        const { selectedGolfers, handleGolferExport, addGolfersMenuOpen, selectMenuOpen, actionMenuOpen } = this.state;
        const { event, competitions, golfersAggregated } = this.getStaff();
        const contacts = Array.from(golfersAggregated.values());
        const isNetCompetitionPresent = competitions.some(competition => isMainCompetitionScoring(competition.scoring, true) && isNetMode(competition.scoring.mode));
        const notAllHandicapsPresent = isNetCompetitionPresent && contacts.some(golfer => golfer.handicapIndex === undefined);
        const zeroHandicapNotificationNeeded = notAllHandicapsPresent && isNetCompetitionPresent;
        const withHandicapId = contacts.some(golfer => Boolean(golfer.handicapId));
        const fileName = event.name.replace(' ', '-') + '-' + Utils.formatDateDashed2(event.date);
        const exportFile = `${fileName}-golfers.csv`;
        const exportData = handleGolferExport ? this.exportGolferData() : '';
        const golfersCount =
            (golfersAggregated.size > 0 ? Utils.withS(golfersAggregated.size, 'golfer') : '') +
            (selectedGolfers.size > 0 ? ', ' + selectedGolfers.size + ' selected' : '');
        const golfersWithGhinId = Array.from(golfersAggregated.values()).filter((g) => g.handicapId?.length ?? 0 > 0);
        const ghinsToUpdate = golfersWithGhinId.filter((g) => !eventData.ghinsMap.has(g.handicapId!) || eventData.ghinsMap.get(g.handicapId!)!.handicapIndex !== g.handicapIndex).length;
        return <>
            <ListItem className={classes.listItem}>
                <ButtonBar style={{ width: '100%' }}>
                    <ButtonGroup ref={this.addMenuRef}>
                        <AppButton
                            color="secondary" onClick={this.handleImportClick}>
                            <PlusIcon sx={{ width: '.7em', height: '.7em' }} className={classes.leftButtonIcon} /> Add
                        </AppButton>
                        <AppButton
                            color={addGolfersMenuOpen ? 'info' : 'secondary'}
                            onClick={this.handleImportClick}
                            sx={{ width: '10px', margin: '0px', padding: '0px' }}>
                            {addGolfersMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </AppButton>
                    </ButtonGroup>
                    <Menu
                        open={this.state.addGolfersMenuOpen}
                        anchorEl={this.addMenuRef.current}
                        onClose={this.handleImportClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                        <MenuItem onClick={this.handleAddGolferClick}>New golfer</MenuItem>
                        <MenuItem onClick={this.handleAddFromRosterClick}>From past events</MenuItem>
                        <MenuItem onClick={this.handleAddFromSpreadsheet}>Import from spreadsheet</MenuItem>
                    </Menu>
                    <AppButton color="info" onClick={this.handleInviteClick}>
                        Invite
                    </AppButton>
                    <AppButton
                        color="info"
                        ref={this.selectMenuRef}
                        sx={{ paddingRight: '8px' }}
                        onClick={this.handleSelectClick}>
                        Select
                        <ArrowDropDownIcon className={classes.rightButtonIcon} />
                    </AppButton>
                    {withHandicapId && <AppButton color="info" onClick={this.refreshGHINHandicaps} disabled={ghinsToUpdate === 0}>
                        {!userAware.hasPro && <ProBadge small mr={4} />}
                        {userAware.hasPro && <RefreshIcon sx={{ width: '.7em', height: '.7em' }} className={classes.leftButtonIcon} />}
                        Handicaps
                        {ghinsToUpdate === 0 ? "" : ` (${ghinsToUpdate})`}
                    </AppButton>}
                    <Menu open={selectMenuOpen}
                        anchorEl={this.selectMenuRef.current}
                        onClose={this.handleSelectClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                        <MenuItem onClick={this.handleSelectAll}>All</MenuItem>
                        <MenuItem onClick={this.handleSelectNone}>None</MenuItem>
                    </Menu>
                    {selectedGolfers.size > 0 && <>
                        <AppButton color="info" ref={this.exportMenuRef} onClick={this.handleActionsClick}>
                            Actions
                            <ArrowDropDownIcon className={classes.rightButtonIcon} />
                        </AppButton>
                        <Menu open={actionMenuOpen}
                            anchorEl={this.exportMenuRef.current}
                            onClose={this.handleActionsClose}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                            <CSVLink data={exportData} filename={exportFile} style={{ textDecoration: 'none' }}>
                                <MenuItem color="default" onClick={this.handleGolferExport}>Export</MenuItem>
                            </CSVLink>
                            <MenuItem onClick={this.handleActionNewEmail}>Send email</MenuItem>
                            {event.type !== 'multiday' &&
                                <MenuItem onClick={this.handleActionChangeTee}>Change tee</MenuItem>}
                            <MenuItem onClick={this.handleActionsDelete}>Delete</MenuItem>
                        </Menu>
                    </>}
                    <Typography noWrap color="textSecondary">
                        {golfersCount}
                    </Typography>
                    {zeroHandicapNotificationNeeded &&
                        <InfoElement iconColor={AppColors.webWarning} contentStyle={{ fontSize: 12, lineHeight: '150%', color: '#6D6D71' }}>
                            Some golfers do not have a Handicap index.0 handicap will be used for net scoring calculation.
                        </InfoElement>}
                    <AppButton color="info"
                        style={{ marginLeft: 'auto', marginRight: 0 }}
                        onClick={this.printGolferList}>
                        <ProBadge small mr={4} />
                        Print Golfers List
                    </AppButton>
                </ButtonBar>
            </ListItem>
        </>;
    }

    private Golfers = () => {
        const { classes, eventData } = this.props;
        const { event, rounds, competitions, acceptedInvites, golfersAggregated } = this.getStaff();
        const { selectedGolfers, selectedAll, payments, sortCol, asc } = this.state;
        const contactDetails = Array.from(golfersAggregated.values());
        const sameNameGolfersIdsSet = getSameNameGolfersIds(contactDetails);
        const isNetCompetitionPresent = competitions.some(competition => isMainCompetitionScoring(competition.scoring, true) && isNetMode(competition.scoring.mode));
        const notAllHandicapsPresent = isNetCompetitionPresent && contactDetails.some(golfer => golfer.handicapIndex === undefined);
        const zeroHandicapNotificationNeeded = notAllHandicapsPresent && isNetCompetitionPresent;
        const paymentColumnEnabled = contactDetails.some(c => !(c.feePaid || (c.paymentId && payments?.get(c.paymentId))));
        const [sendPaymentDialogOpened, setSendPaymentDialogOpened] = React.useState(false);
        const unpaidContacts = payments ? contactDetails.filter(c => (!c.paymentId || !payments?.get(c.paymentId)) && !c.feePaid) : undefined;
        const [contactsToEmail, setContactsToEmail] = React.useState<ContactDetails[] | undefined>(undefined);
        const scheduleLabel = event.type === 'multiday' ? ' Auto-Schedule for round 1' : 'Auto schedule';
        const emailVariant = EmailVariant.eventUpcomingPayment;
        const sendMessages = async (contacts: Array<Contact>) => {
            setSendPaymentDialogOpened(false);
            await this.handleSendMessage(contacts, emailVariant);
        };
        const withTees = Opts.showAllGolferTees && contactDetails.findIndex(hasContactTees) >= 0;
        const eventOrRounds = rollEvents(event, rounds);
        const golfersWithGhinId = Array.from(golfersAggregated.values()).filter((g) => g.handicapId?.length ?? 0 > 0);
        const ghinsToUpdate = golfersWithGhinId.filter((g) => !eventData.ghinsMap.has(g.handicapId!) || eventData.ghinsMap.get(g.handicapId!)!.handicapIndex !== g.handicapIndex).length;
        const indexNoticeText = ghinsToUpdate === 0 ? "Handicap is up to date for all golfers" : `Handicap is out of date for ${ghinsToUpdate} golfers`
        return (
            <div className={classes.listRootGrey}>
                <List disablePadding>
                    <Spacing height={8} />
                    <List className={classes.listItemHeaderWhite}>
                        <Container>
                            <Item xs={10}>
                                <Typography sx={{ paddingLeft: '16px' }}>
                                    <Switch checked={event.autoSchedule === 'ON'} onChange={e => this.setAutoScheduleSave(e.target.checked ? 'ON' : 'OFF')} />
                                    {scheduleLabel} &nbsp;
                                    <NoticeElement>
                                        Golfers will be added to the schedule in the order of joining the event. Edit, create or customize via the scheduling tab.
                                    </NoticeElement>
                                </Typography>
                            </Item>
                        </Container>
                    </List>
                    <Spacing />
                    <this.Buttons />
                    <ContactItemHeader
                        roundsNum={eventOrRounds.length}
                        selectedAll={selectedAll}
                        selectedAny={selectedGolfers.size > 0}
                        selectAll={this.selectAllGolfers}
                        showPaymentInfo={event.paymentSettings?.enabled}
                        paymentColumnEnabled={paymentColumnEnabled}
                        onRequestClick={unpaidContacts?.length ? () => setSendPaymentDialogOpened(true) : () => { }}
                        withTee={withTees}
                        indexNoticeText={indexNoticeText}
                        sortCol={sortCol}
                        asc={asc}
                        onSortColumn={this.handleSortGolfers}
                    />
                    {contactDetails.sort(this.contactsComparator).map(golferAggregated =>
                        <ContactItem
                            key={golferAggregated.id}
                            withTee={withTees}
                            contact={golferAggregated}
                            eventOrRounds={eventOrRounds}
                            clickHandler={this.handleGolferRowClick}
                            cbClickHandler={this.handleGolferCbChange}
                            selected={selectedGolfers.has(golferAggregated.id)}
                            withHomeCourseOrCity={sameNameGolfersIdsSet.has(golferAggregated.id)}
                            zeroHandicapIconAllowed={zeroHandicapNotificationNeeded}
                            ghin={golferAggregated.handicapId ? eventData.ghinsMap.get(golferAggregated.handicapId) : undefined}
                            linkedFromApp={acceptedInvites.has(golferAggregated.id) && !acceptedInvites.get(golferAggregated.id)!.till}
                            onRevokeSuccess={() => acceptedInvites.delete(golferAggregated.id)}
                            showPaymentInfo={event.paymentSettings?.enabled}
                            handleFeePaidStatusUpdate={this.updateGolferFeePaidStatus(event.id, golferAggregated.id)}
                            feePaid={golferAggregated.feePaid}
                            handleSendMessage={contact => this.handleSendMessage([contact], emailVariant)}
                            paymentInfo={golferAggregated.paymentId ? payments?.get(golferAggregated.paymentId) : undefined} />
                    )}
                    <Spacing height={8} />
                </List>
                {sendPaymentDialogOpened && <SendPaymentMessageDialog
                    unpaidParticipants={unpaidContacts}
                    notification={false}
                    paymentSettings={event.paymentSettings}
                    showEmail={(contactsToEmail) => setContactsToEmail(contactsToEmail)}
                    handleSend={sendMessages}
                    handleClose={() => setSendPaymentDialogOpened(false)} />}
                {contactsToEmail?.length && <GolfersEmailDialog
                    open
                    eventData={eventData}
                    emailVariant={emailVariant}
                    handleCancel={() => setContactsToEmail(undefined)}
                    handleSent={() => {
                        this.handleActionNewEmailSent();
                        setSendPaymentDialogOpened(false);
                    }}
                    initialGolfers={contactsToEmail!} />}
            </div>
        );
    }

    private handleSendMessage = async (contactsToSend: Contact[], variant: EmailVariant) => {
        const { eventData } = this.props;
        const { golferScores, teamScores, reportedScores, reportedTeamScores, distances } = this.state;
        await sendPaymentMessage(contactsToSend, eventData, golferScores, teamScores, reportedScores,
            reportedTeamScores, distances, variant);
    };

    private handleSendInvite = async (recipients: ContactDetails[]) => {
        const { eventData } = this.props;
        const email = {
            subject: `Tournament invitation!`,
            replyTo: eventData.adminEmail ?? '',
            recipients: recipients,
            text: ''
        }
        await sendInviteEmail(email, eventData);
        this.setState({ inviteDialogOpen: false, inviteEmailDialogOpen: false })
    };

    private handleCloseProPlan = (name?: AccountActions) => {
        const { userAware } = this.props;
        this.setState({ proBanner: false });
        accountAction(userAware, name);
    }

    render() {
        const { event } = this.getStaff();
        const { eventData, userAware } = this.props;
        const { golfersAggregated, acceptedInvites, rounds } = eventData;
        const { editedContactDetails, importResult, selectedGolfers, importingFromRoster, actionNewEmailOpen, actionChangeTeeOpen, onRevokeSuccess,
            importingFromSpreadsheet, inviteRecipients, inviteEmailDialogOpen, inviteDialogOpen, proBanner } = this.state;
        const selectedGolfersList = Array.from(golfersAggregated.values()).filter(golfer => selectedGolfers.has(golfer.id)).sort(compareContacts);
        const paymentsEnabled = Boolean(event.paymentSettings?.enabled);
        return <>
            {editedContactDetails && <EditContactDialog
                open
                event={event}
                rounds={rounds}
                hasPro={!!userAware.hasPro}
                actionMode={editedContactDetails.id ? 'edit' : 'add'}
                initialContact={editedContactDetails}
                saveToEvent={this.handleContactChanged}
                handleClose={this.handleCloseEditDialog}
                deleteFromEvent={this.handleContactDeleted}
                linkedFromApp={acceptedInvites.has(editedContactDetails.id) && !acceptedInvites.get(editedContactDetails.id)!.till}
                onRevokeSuccess={onRevokeSuccess} />}
            {actionNewEmailOpen && <GolfersEmailDialog
                open
                eventData={eventData}
                emailVariant={EmailVariant.default}
                handleCancel={this.handleActionNewEmailCanceled}
                handleSent={this.handleActionNewEmailSent}
                initialGolfers={selectedGolfersList} />}
            {(actionChangeTeeOpen && event.type !== 'multiday') && <SelectTeesComponent
                open
                variant="dialog"
                eventOrRound={event}
                onClose={() => this.setState({ actionChangeTeeOpen: false })}
                onSelectTees={(_event, menTee, womenTee) => this.onSelectTees(selectedGolfersList, menTee, womenTee)}
                selectedGolfers={selectedGolfersList} />}
            {importingFromRoster && <AddGolfersFromRosterDialog
                open
                event={event}
                eventData={eventData}
                hiddenGolfersMode={false}
                handleAddGolfers={this.handleAddFromRoster}
                handleCancel={this.handleCancelFromRoster} />}
            {importingFromSpreadsheet && <ImportContactDialog
                open={importingFromSpreadsheet}
                importResult={this.handleImportResults}
                handleCancel={() => this.setState({ importingFromSpreadsheet: false })} />}
            {inviteEmailDialogOpen && <InvitesEmailDialog
                open
                event={event}
                eventData={eventData}
                handleCancel={this.handleReviewInviteClose}
                handleSent={this.handleInviteClose}
                initialInvites={inviteRecipients?.map(r => { return { email: r.email ?? '' } as ContactInvite }) ?? []} />}
            {inviteDialogOpen && <InviteGolfersDialog
                open
                event={event}
                eventData={eventData}
                handleAddToEvent={(contact) => saveContact(event, contact, ACTION_ADD_FROM_ROSTER)}
                handleSendInvite={this.handleSendInvite}
                handleCancel={this.handleInviteClose} />}
            {!!importResult && <ImportReviewDialog
                event={event}
                loadedData={importResult}
                handleClose={this.handleFinishImportResults} />}
            {proBanner &&
                <ProPlanDialog handleClose={this.handleCloseProPlan} />}
            <this.Golfers />
            <TeesProvider eventOrRound={event} course={event.course} onResult={this.teesLoaded} ref={this.teesLoader} />
            <FirebaseDataComponent query={Backend.eventPaymentsDb(event.id)} onMap={this.onEventsPaymentsInfo} />
            {paymentsEnabled && <>
                <FirebaseDataComponent query={Backend.golferTeamScoresDb(event.id)} onMap={this.onTeamScores} />
                <FirebaseDataComponent query={Backend.golferScoresDb(event.id)} onMap={this.onGolferScores} />
                <FirebaseDataComponent query={Backend.reportedGolferScoresDb(event.id)} onMap={this.onReportedGolferScores} />
                <FirebaseDataComponent query={Backend.reportedTeamScoresDb(event.id)} onMap={this.onReportedTeamScores} />
                <FirebaseDataComponent query={Backend.golferDistancesDb(event.id)} onMap={this.onDistances} />
            </>}
        </>;
    }
}

export default function(props: Omit<Props, 'classes' | 'userAware'>) {
    const classes = useAppStyles();
    const userAware = useUserAware();
    return <GolfersList classes={classes} userAware={userAware} {...props} />;
}
