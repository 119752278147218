import * as React from 'react';
import Divider from '@mui/material/Divider';
import { DialogActions, DialogContent, DialogContentText, Grid, Grow, IconButton, List, ListItem, ListItemButton, MenuItem, MenuList, Paper, Popper, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import PersonIcon from '@mui/icons-material/Person';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DropDownArrowIcon from '@mui/icons-material/ArrowDropDown';
import { CSVLink } from 'react-csv';
import { formatDateDashed2, Func, range } from '../../../util/utility';
import * as Backend from '../../../util/firebase';
import {
    appScoringStarted, Competition, Contact, ContactRoundsScores, ContactScoringState, Distance, DistanceValue, EventBase, EventData, getRoundsCompetitions, getTee, getTotalHoles,
    GolferGroup, hasEmptyTees, hasFirstRoundCompetition, hasTees, isDistanceScoring, isGrossMode, isGrossPayouts, isMainCompetitionScoring, isNetMode, isNetPayouts, isSkinsScoring, isStablefordScoringOrMode,
    isTeamFormat, isTeamFormatExceptBB, rollEvents, ScoringData, ScoringFormatDistance, ScoringFormatSkins, ScoringFormatTeams, sortCompetitions, Team, teeTimeName, Units, WinnerInfo, getEventStaff
} from '../../../types/EventTypes';
import { fullName, getSameNameGolfersIds, golferShortTeamNameArray, golfersOfTeam } from '../../../contact/Contact';
import EditScoreDialog from './EditScoreDialog';
import AddResultDialog from './AddResultDialog';
import {
    clearDistance, clearScores, competitionHasScores, competitionSummary, createCompetition, createSideGame, deleteCompetition, elog, formatDistance, genderFromEvent, getDistanceScores, getScoresWinners,
    getSkinsScores, HolesScoringState, isFullScoresCompetition, isFullScoresCompetitions,
    isPayoutsExceedGolfers, liveScoresName, processSkins, saveCompetitions, SkinsScoringState, updateCompetitionPos, updateEventAppCompetition, updateGolferDistance
} from '../../Event';
import { getFlightName } from '../../TeeTimes';
import * as Scoring from '../../../scoring/scoring';
import LabeledField from '../../../common/form/LabeledField';
import ButtonBar from '../../../common/components/ButtonBar';
import AppButton from '../../../common/components/AppButton';
import MessageDialog from '../../../common/dialog/MessageDialog';
import { InfoElement, pushUrl, showAlert, showAlertProps } from '../../../redux/ReduxConfig';
import CompetitionSettingsDialog from './CompetitionSettingsDialog';
import { ButtonBadge, Container, EditIcon, Item, ItemS, Label, ListTitle, Spacing } from '../../../common/Misc';
import SelectLiveScoresDialog from '../common/SelectLiveScoresDialog';
import SelectGolfersDialog from '../common/SelectGolfersDialog';
import SelectWinnersDialog from '../common/SelectWinnersDialog';
import { styles, useAppStyles } from '../../../styles';
import { withProgress } from '../../../util/ProgressPromise';
import { XSMobileDialog } from "../../../common/dialog/MobileDialog";
import ReorderCompetitionsDialog from "./ReorderCompetitionsDialog";
import { SkinsDetailedInfo } from "../../../public/Standings";
import { InfoIcon, SettingsIcon } from '../../../common/Icons';
import { AppColors } from '../../../main/Theme';
import { exportCompetitionsData, exportMoneyListData, exportSideGamesData, getScores } from './ExportUtils';
import ScoreLoader from './ScoreLoader';
import CourseSelectionDialog from "../settings/general/course/CourseSelectionDialog";

type HeaderProps = {
    showEditIcon: boolean;
    scoring: ScoringData;
    scoreRounds?: Array<number>;
};

type RowProps = {
    showEditIcon: boolean;
    scoring: ScoringData;
    onClick: Func<void>;
    skinsGrossScore?: number;
    skinsNetScore?: number;
    sameNameGolfersIdsSet: Set<string>;
    golfers: Map<string, Contact>;
    contactRoundsScores: ContactRoundsScores;
};

const HeaderScore = (props: HeaderProps) => {
    const { showEditIcon, scoring, scoreRounds } = props;
    const classes = useAppStyles();
    const isNet = isNetMode(scoring.mode);
    const isGross = isGrossMode(scoring.mode);
    const isStableford = isStablefordScoringOrMode(scoring);
    const rightIcon = <EditIcon invisible />;
    const skins = isSkinsScoring(scoring);
    const colNums = scoreRounds
        ? scoreRounds.length + 1 + +showEditIcon
        : +(!isStableford && isNet) + +(isStableford && isGross) + +(isStableford && isNet) + +(skins && isGross) + +(skins && isNet) + +showEditIcon;
    return <>
        <ListItem className={classes.listItemHeaderWhite}>
            <Container wrap="nowrap">
                {scoreRounds && <>
                    <Item xs={12 - colNums}>Name</Item>
                    {scoreRounds.map(scoreRound => <Item key={scoreRound} xs={1}>R{scoreRound}</Item>)}
                    <Item xs={1}>Total</Item>
                </>}
                {!scoreRounds && <>
                    <Item xs={2} md={1}>Time</Item>
                    <Item xs={11 - colNums} md={12 - colNums}>Name</Item>
                    <Item xs={1}>Thru</Item>
                    <Item xs={1} md={1}>Gross</Item>
                    {!isStableford && isNet && <Item xs={1} md={1}>Net</Item>}
                    {isStableford && isGross && <Item xs={1} md={1}>Pts</Item>}
                    {isStableford && isNet && <Item xs={1} md={1}>Pts Net</Item>}
                    {skins && <Item xs={1} md={1}>Skins{isNet && isGross ? ' Gross' : ''}</Item>}
                    {skins && isNet && isGross && <Item xs={1} md={1}>Skins Net</Item>}
                </>}
                {showEditIcon && <Item xs={1} placeRight noWrap>{rightIcon}</Item>}
            </Container>
        </ListItem>
        <Divider />
        <Divider />
    </>;
};

const GolferNames = (props: {
    names: Array<string>;
    winnerIn?: Array<string>,
    sameNameGolfersIdsSet: Set<string>,
    golfer: Contact
}) => {
    const { names, winnerIn, sameNameGolfersIdsSet, golfer } = props;
    const classes = useAppStyles();
    return <Container>
        {names.map((name, idx) =>
            <ItemS key={idx}>
                <PersonIcon className={classes.textIcon} />
                <Label>
                    {name}
                    <span className={classes.homeCourseOrCity}>
                        {golfer.homeCourseOrCity && sameNameGolfersIdsSet.has(golfer.id) ? ` (${golfer.homeCourseOrCity})` : ''}
                    </span>
                </Label>
            </ItemS>)}
        {winnerIn && <Grid item>&nbsp;*Winner by tie-breaker ({winnerIn.join(',')})</Grid>}
    </Container>;
};

const TeamNames = (props: {
    names: Array<string>;
    winnerIn?: Array<string>,
    sameNameGolfersIdsSet: Set<string>,
    golfers: Map<string, Contact>,
    team: Team
}) => {
    const { names, winnerIn, sameNameGolfersIdsSet, golfers, team } = props;
    const classes = useAppStyles();
    const contactIds = golfersOfTeam(team, golfers).map(c => c.id);
    return <Container>
        {names.map((name, idx) =>
            <ItemS key={idx}>
                <PersonIcon className={classes.textIcon} />
                <Label>
                    {name}
                    <span className={classes.homeCourseOrCity}>
                        {sameNameGolfersIdsSet.has(contactIds[idx]) && golfers.get(contactIds[idx])?.homeCourseOrCity ? ` (${golfers.get(contactIds[idx])?.homeCourseOrCity})` : ''}
                    </span>
                </Label>
            </ItemS>)}
        {winnerIn && <Grid item>&nbsp;*Winner by tie-breaker ({winnerIn.join(',')})</Grid>}
    </Container>;
};

const RowGolferScore = (props: RowProps) => {
    const classes = useAppStyles();
    const {
        showEditIcon,
        scoring,
        contactRoundsScores,
        sameNameGolfersIdsSet,
        skinsGrossScore,
        skinsNetScore,
        onClick
    } = props;
    const firstScore = contactRoundsScores.scoringStates[0]!;
    const { teeTime, winnerIn, contactInfo, player, isReported } = firstScore;
    if ('contactIds' in player) {
        return null;
    }
    const roundsNum = contactRoundsScores.scoringStates.length;
    const hasRounds = roundsNum > 1;
    const isNet = isNetMode(scoring.mode);
    const isGross = isGrossMode(scoring.mode);
    const isStableford = isStablefordScoringOrMode(scoring);
    const teeName = teeTimeName(teeTime);
    const golferDisStatus = (score?: ContactScoringState) => score?.contactInfo.withdrawn ? 'WD' : score?.contactInfo.disqualified ? 'DQ' : undefined;
    const scoreThru = (score?: ContactScoringState) => score?.holes === getTotalHoles(score?.eventOrRound.holesType) ?
        (hasRounds ? '' : 'F') :
        (score?.holes ? (hasRounds ? 'thru ' : '') + score.holes : '');
    const scoreGross = (score?: ContactScoringState) => golferDisStatus(score) ?? Scoring.formatTotalAndRelativeScore(roundsNum, score?.total, score?.relativeTotal);
    const scoreNet = (score?: ContactScoringState) => golferDisStatus(score) ?? Scoring.formatTotalAndRelativeScore(roundsNum, score?.net, score?.relativeNet);
    const scorePts = (score?: ContactScoringState) => golferDisStatus(score) ?? score?.stableford;
    const scoreNetPts = (score?: ContactScoringState) => golferDisStatus(score) ?? score?.stablefordNet;
    const Name = <div className={classes.flexCenteredRow21px}>
        <GolferNames names={[fullName(player, 15, 25)]} winnerIn={winnerIn}
            sameNameGolfersIdsSet={sameNameGolfersIdsSet} golfer={player} />
        {contactInfo.handicapIndex == null && isNet &&
            <InfoIcon className={classes.smallerShiftedIcon} htmlColor={AppColors.webWarning} />}
    </div>;
    const skins = isSkinsScoring(scoring);
    const colNums = hasRounds
        ? contactRoundsScores.scoringStates.length + 1 + +showEditIcon
        : +(!isStableford && isNet) + +(isStableford && isGross) + +(isStableford && isNet) + +(skins && isGross) + +(skins && isNet) + +showEditIcon;
    return <>
        <ListItemButton className={isReported ? classes.listItemGreen : classes.listItem} onClick={onClick}>
            <Container wrap="nowrap">
                {hasRounds && <>
                    <Item xs={12 - colNums} noWrap>{Name}</Item>
                    {contactRoundsScores.scoringStates.map((score, idx) =>
                        <Item xs={1} noWrap key={idx}>
                            {isNet ? scoreNet(score) : scoreGross(score)}<br />{scoreThru(score)}
                        </Item>)}
                    <Item xs={1}>{(isNet ? contactRoundsScores.net : contactRoundsScores.total) || '-'}</Item>
                </>}
                {!hasRounds && <>
                    <Item xs={2} md={1} noWrap fontFamily="roboto">{teeName}</Item>
                    <Item xs={11 - colNums} md={12 - colNums} noWrap>{Name}</Item>
                    <Item xs={1} noWrap>{scoreThru(firstScore)}</Item>
                    <Item xs={1} md={1} noWrap>{scoreGross(firstScore)}</Item>
                    {!isStableford && isNet && <Item xs={1} md={1} noWrap>{scoreNet(firstScore)}</Item>}
                    {isStableford && isGross && <Item xs={1} md={1} noWrap>{scorePts(firstScore)}</Item>}
                    {isStableford && isNet && <Item xs={1} md={1} noWrap>{scoreNetPts(firstScore)}</Item>}
                    {skins && isGross && <Item xs={1} noWrap>{skinsGrossScore ?? 0}</Item>}
                    {skins && isNet && <Item xs={1} noWrap>{skinsNetScore ?? 0}</Item>}
                </>}
                {showEditIcon && <Item xs={1} placeRight noWrap><EditIcon /></Item>}
            </Container>
        </ListItemButton>
        <Divider />
    </>;
};

export const RowTeamScore = (props: RowProps) => {
    const classes = useAppStyles();
    const {
        showEditIcon,
        scoring,
        contactRoundsScores,
        sameNameGolfersIdsSet,
        golfers,
        skinsGrossScore,
        skinsNetScore,
        onClick
    } = props;
    const firstScore = contactRoundsScores.scoringStates[0]!;
    const { teeTime, winnerIn, contactInfo, player, isReported } = firstScore;
    if ('lastName' in player) {
        return null;
    }
    const roundsNum = contactRoundsScores.scoringStates.length;
    const hasRounds = roundsNum > 1;
    const isNet = isNetMode(scoring.mode);
    const isGross = isGrossMode(scoring.mode);
    const isStableford = isStablefordScoringOrMode(scoring);
    const teeName = teeTimeName(teeTime);
    const teamStatus = contactInfo.withdrawn ? 'WD' : contactInfo.disqualified ? 'DQ' : undefined;
    const scoreThru = (score?: ContactScoringState) => score?.holes === getTotalHoles(score?.eventOrRound.holesType) ?
        (hasRounds ? '' : 'F') :
        (score?.holes ? (hasRounds ? 'thru ' : '') + score.holes : '');
    const scoreGross = (score?: ContactScoringState) => teamStatus ?? Scoring.formatTotalAndRelativeScore(roundsNum, score?.total, score?.relativeTotal);
    const scoreNet = (score?: ContactScoringState) => teamStatus ?? Scoring.formatTotalAndRelativeScore(roundsNum, score?.net, score?.relativeNet);
    const scorePts = (score?: ContactScoringState) => teamStatus ?? score?.stableford;
    const scoreNetPts = (score?: ContactScoringState) => teamStatus ?? score?.stablefordNet;
    const skins = skinsGrossScore != null || skinsNetScore != null;
    const Name = <TeamNames names={golferShortTeamNameArray(player, golfers, false)} winnerIn={winnerIn}
        sameNameGolfersIdsSet={sameNameGolfersIdsSet} golfers={golfers} team={player} />;
    const colNums = hasRounds
        ? contactRoundsScores.scoringStates.length + 1 + +showEditIcon
        : +(!isStableford && isNet) + +(isStableford && isGross) + +(isStableford && isNet) + +(skins && isGross) + +(skins && isNet) + +showEditIcon;
    return <>
        <ListItemButton className={isReported ? classes.listItemGreen : classes.listItem} onClick={onClick}>
            <Grid container wrap="nowrap">
                {hasRounds && <>
                    <Item xs={12 - colNums} noWrap>{Name}</Item>
                    {contactRoundsScores.scoringStates.map((score, idx) =>
                        <Item xs={1} noWrap key={idx}>
                            {scoreGross(score)}<br />{scoreThru(score)}
                        </Item>)}
                    <Item
                        xs={1}>{contactRoundsScores.total || '-'}<br />{contactRoundsScores.holes ? `thru ${contactRoundsScores.holes}` : ''}
                    </Item>
                </>}
                {!hasRounds && <>
                    <Item xs={2} md={1} noWrap variant="body2">{teeName}</Item>
                    <Item xs={11 - colNums} md={12 - colNums} noWrap>{Name}</Item>
                    <Item xs={1} noWrap>{scoreThru(firstScore)}</Item>
                    <Item xs={1} noWrap>{scoreGross(firstScore)}</Item>
                    {!isStableford && isNet && <Item xs={1} md={1} noWrap>{scoreNet(firstScore)}</Item>}
                    {isStableford && isGross && <Item xs={1} md={1} noWrap>{scorePts(firstScore)}</Item>}
                    {isStableford && isNet && <Item xs={1} md={1} noWrap>{scoreNetPts(firstScore)}</Item>}
                    {skins && isGross && <Item xs={1} noWrap>{skinsGrossScore ?? 0}</Item>}
                    {skins && isNet && <Item xs={1} noWrap>{skinsNetScore ?? 0}</Item>}
                </>}
                {showEditIcon && <Item xs={1} placeRight noWrap><EditIcon /></Item>}
            </Grid>
        </ListItemButton>
        <Divider />
    </>;
};

const KPHeader = () => {
    const classes = useAppStyles();
    const rightIcon = <EditIcon invisible />;
    return <>
        <ListItem className={classes.listItemHeaderWhite}>
            <Grid container wrap="nowrap">
                <Item xs={1} variant="body2" noWrap>Hole</Item>
                <Item xs={8} variant="body2" noWrap>Winner</Item>
                <Item xs={2} variant="body2" noWrap>Distance</Item>
                <Item xs={1} placeRight noWrap>{rightIcon}</Item>
            </Grid>
        </ListItem>
        <Divider />
        <Divider />
    </>;
};

const KPRow = (props: {
    onClick: Func<void>,
    units?: Units,
    sameNameGolfersIdsSet: Set<string>
} & HolesScoringState) => {
    const { units, hole, contacts, score, onClick, sameNameGolfersIdsSet } = props;
    const classes = useAppStyles();
    const rightIcon = <EditIcon />;
    const decorations = classes.listItem;
    const contact: Contact | undefined = contacts[0];
    return <>
        <ListItemButton className={decorations} onClick={onClick}>
            <Grid container wrap="nowrap">
                <Item xs={1} noWrap>{hole + 1}</Item>
                <ItemS xs={8} noWrap>
                    {contact ?
                        <React.Fragment>
                            <PersonIcon className={classes.textIcon} />
                            <Label>
                                {fullName(contact)}
                            </Label>
                            <span className={classes.homeCourseOrCity}>
                                {contact?.homeCourseOrCity && sameNameGolfersIdsSet.has(contact.id) ? ` (${contact.homeCourseOrCity})` : ''}
                            </span>
                        </React.Fragment> :
                        <Label className={classes.notSelected}>
                            {'Not selected'}
                        </Label>}
                </ItemS>
                <Item xs={2} noWrap>{formatDistance(units, score)}</Item>
                <Item xs={1} placeRight noWrap>{rightIcon}</Item>
            </Grid>
        </ListItemButton>
        <Divider />
    </>;
};

const LDHeader = () => {
    const classes = useAppStyles();
    const rightIcon = <EditIcon invisible />;
    return <>
        <ListItem className={classes.listItemHeaderWhite}>
            <Grid container wrap="nowrap">
                <Item xs={1} variant="body2" noWrap>Hole</Item>
                <Item xs={10} variant="body2" noWrap>Winner</Item>
                <Item xs={1} placeRight noWrap>{rightIcon}</Item>
            </Grid>
        </ListItem>
        <Divider />
        <Divider />
    </>;
};

const LDRow = (props: { onClick: Func<void>, sameNameGolfersIdsSet: Set<string> } & HolesScoringState) => {
    const { onClick, hole, contacts, sameNameGolfersIdsSet } = props;
    const classes = useAppStyles();
    const rightIcon = <EditIcon />;
    const decorations = classes.listItem;
    const contact: Contact | undefined = contacts[0];
    return <>
        <ListItemButton className={decorations} onClick={onClick}>
            <Grid container wrap="nowrap">
                <Item xs={1} noWrap>{hole + 1}</Item>
                <ItemS xs={10} noWrap>
                    {contact ?
                        <>
                            <PersonIcon className={classes.textIcon} />
                            <Label>
                                {fullName(contact)}
                            </Label>
                            <span className={classes.homeCourseOrCity}>
                                {contact?.homeCourseOrCity && sameNameGolfersIdsSet.has(contact.id) ? ` (${contact.homeCourseOrCity})` : ''}
                            </span>
                        </> :
                        <Label className={classes.notSelected}>
                            {'Not selected'}
                        </Label>}
                </ItemS>
                <Item xs={1} placeRight noWrap>{rightIcon}</Item>
            </Grid>
        </ListItemButton>
        <Divider />
    </>;
};

interface EditingContactScoring extends ContactScoringState {
    competition: Competition;
    golfers: Map<string, Contact>;
    teams: Map<string, Team>;
    skinsGross?: SkinsScoringState[];
    skinsNet?: SkinsScoringState[];
    readonlyScores: boolean;
    mainCompetition: Competition
};

interface State /*extends ScoresData*/ {
    editingCompetition?: {
        eventOrRound: EventBase,
        competition: Competition,
        competitions: Array<Competition>,
        mainCompetition: Competition,
        golfers: Map<string, Contact>,
        teams: Map<string, Team>,
        groups: Array<GolferGroup>,
        isNew: boolean,
        hasScores: boolean,
        allRounds: boolean
    };
    editingScore?: EditingContactScoring;
    editingLD?: {
        eventOrRound: EventBase,
        competition: Competition,
        golfers: Map<string, Contact>,
        hole: number,
        golfer?: Contact,
        distances: Map<string, Distance>
    };
    editingKP?: {
        eventOrRound: EventBase,
        competition: Competition,
        golfers: Map<string, Contact>,
        hole: number,
        golfer?: Contact,
        distances: Map<string, Distance>,
        distance?: DistanceValue
    };
    editingWinners?: { eventOrRound: EventBase, competitions: Array<Competition> };
    teamScoring: boolean;
    hasNet: boolean;
    hasStableford: boolean;
    openLiveScoresDialog?: boolean;
    courseNotSelectedMessage?: boolean;
    exportMenuOpen: boolean;
    exportCompetitions: boolean;
    exportSideGames: boolean;
    exportMoneyList: boolean;
    alertDialogOpen: boolean;
    anchorExport?: EventTarget & HTMLElement;
    openCompetitionsReordering?: boolean;
    totalMode: boolean;
    courseDialogOpened?: boolean;
}

type IncompleteScoresAlertDialogProps = { exportCompFile: string; };

type Props = { eventData: EventData; } & WithStyles<typeof styles>;

class ScoreList extends React.Component<Props, State> {
    state: State = {
        teamScoring: false,
        hasNet: false,
        hasStableford: false,
        exportMenuOpen: false,
        exportCompetitions: false,
        exportSideGames: false,
        exportMoneyList: false,
        alertDialogOpen: false,
        totalMode: false
    };

    private readonly scoreLoader: React.RefObject<ScoreLoader> = React.createRef();

    componentDidMount() {
        Backend.trackEvent('view_scoring');
    }

    private eventOrRound() {
        const { eventData } = this.props;
        const { totalMode } = this.state;
        const { event, selectedRound, rounds } = eventData;
        return event.type === 'multiday' ? (!totalMode ? selectedRound : rounds[0]) : event;
    }

    private eventOrFirstRound() {
        const { eventData } = this.props;
        const { event, rounds } = eventData;
        return event.type === 'multiday' ? (rounds.length > 0 ? rounds[0] : undefined) : event;
    }

    private eventOrRounds() {
        const { event, rounds } = this.props.eventData;
        return rollEvents(event, rounds);
    }

    private getRound = (eventOrRoundId?: number | string) => {
        const { rounds } = this.props.eventData;
        if (eventOrRoundId) {
            return typeof eventOrRoundId == 'number' ?
                rounds.find(round => round.roundOrder === eventOrRoundId) :
                rounds.find(round => round.id === eventOrRoundId);
        } else {
            return undefined;
        }
    }

    private getStaff(eventOrRoundId?: number | string) {
        const { eventData } = this.props;
        const eventOrRound = this.getRound(eventOrRoundId) ?? this.eventOrRound() ?? eventData.event;
        return { eventOrRound, ...getEventStaff(eventOrRound.id, eventData) };
    }

    private getScoresData = () => this.scoreLoader.current?.state;

    private IncompleteScoresAlertDialog = ({ exportCompFile }: IncompleteScoresAlertDialogProps) => {
        const { eventData } = this.props;
        const eventOrRound = this.eventOrRound();
        const scoresDate = this.getScoresData();
        if (!eventOrRound || !scoresDate) {
            return;
        }
        return <XSMobileDialog open={this.state.alertDialogOpen}>
            <DialogContent>
                <DialogContentText>
                    The event is still in progress. Some information may be incomplete. Export anyways?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <AppButton onClick={this.handleAlertDialogCancel}>
                    Cancel
                </AppButton>
                <CSVLink data={exportCompetitionsData(eventOrRound, eventData, scoresDate)} filename={exportCompFile}
                    style={{ textDecoration: 'none' }}>
                    <AppButton color='primary' onClick={this.handleAlertDialogCancel}>
                        Export
                    </AppButton>
                </CSVLink>
            </DialogActions>
        </XSMobileDialog>;
    }

    private ExportMenu = () => {
        const { eventData } = this.props;
        const { event, teamsMap, golfersMap, competitionsMap } = eventData;
        const { exportCompetitions, exportSideGames, exportMoneyList, alertDialogOpen } = this.state;
        const eventOrRound = this.eventOrRound();
        if (!eventOrRound) {
            return;
        }
        const teams = teamsMap.get(eventOrRound.id);
        const golfers = golfersMap.get(eventOrRound.id);
        const competitions = competitionsMap.get(eventOrRound.id);
        const scoresDate = this.getScoresData();
        if (!competitions || !golfers || !teams || !scoresDate) {
            return;
        }
        const { golferScores, teamScores, reportedScores } = getScores(eventOrRound.id, scoresDate);
        const fileName = event.name.replace(' ', '-') + '-' + formatDateDashed2(event.date);
        const exportCompFile = `${fileName}-comp-scores.csv`;
        const exportSideFile = `${fileName}-side-scores.csv`;
        const moneyListFile = `${fileName}-money-list.csv`;
        const competitionsData: (string | number)[][] | '' = exportCompetitions ? exportCompetitionsData(eventOrRound, eventData, scoresDate) : '';
        const sideData = exportSideGames ? exportSideGamesData(eventOrRound, eventData, scoresDate) : '';
        const moneyList = exportMoneyList ? exportMoneyListData(eventOrRound, eventData, scoresDate) : '';
        const isFullScores = isFullScoresCompetitions(event, competitions, golferScores, teamScores, reportedScores, golfers, teams);
        return <Popper anchorEl={this.state.anchorExport} transition
            open={this.state.exportMenuOpen && Boolean(this.state.anchorExport)}>
            {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                    <Paper>
                        <ClickAwayListener onClickAway={this.handleExportClose}>
                            <MenuList role="menu">
                                {isFullScores && <CSVLink data={competitionsData} filename={exportCompFile}
                                    style={{ textDecoration: 'none' }}>
                                    <MenuItem onClick={this.handleExportCompetitions}>Competitions scores</MenuItem>
                                </CSVLink>}
                                {!isFullScores &&
                                    <MenuItem onClick={this.handleAlertDialogOpen}>Competitions scores</MenuItem>}
                                {alertDialogOpen && this.IncompleteScoresAlertDialog({ exportCompFile })}
                                <CSVLink data={sideData} filename={exportSideFile} style={{ textDecoration: 'none' }}>
                                    <MenuItem onClick={this.handleExportSideGames}>Side games scores</MenuItem>
                                </CSVLink>
                                <CSVLink data={moneyList} filename={moneyListFile} style={{ textDecoration: 'none' }}>
                                    <MenuItem onClick={this.handleExportMoneyList}>Money list</MenuItem>
                                </CSVLink>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>;
    }

    private handleExportMoneyList = () => {
        this.setState({ exportMenuOpen: false, exportMoneyList: true })
    }
    private handleExportCompetitions = () => {
        this.setState({ exportMenuOpen: false, exportCompetitions: true })
    }
    private handleExportSideGames = () => {
        this.setState({ exportMenuOpen: false, exportSideGames: true })
    }
    private handleExportClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.setState({
        exportMenuOpen: !this.state.exportMenuOpen,
        anchorExport: event.currentTarget
    });
    private handleExportClose = () => this.setState({ exportMenuOpen: false });
    private handleAlertDialogOpen = () => this.setState({ alertDialogOpen: true })
    private handleAlertDialogCancel = () => this.setState({ exportMenuOpen: false, alertDialogOpen: false })
    private handleCloseCompetitionEditDialog = () => this.setState({ editingCompetition: undefined });
    private changeReorderingOpenStatus = (openCompetitionsReordering: boolean) => this.setState({ openCompetitionsReordering });

    private handleBreakTies = () => {
        const { eventOrRound, competitions } = this.getStaff();
        if (!eventOrRound) {
            return;
        }
        this.setState({
            editingWinners: {
                eventOrRound,
                competitions
            }
        });
    }

    private openCompetitionSettings = (competition: Competition) => {
        const { eventData } = this.props;
        const eventOrRound = this.eventOrFirstRound();
        const scoresDate = this.getScoresData();
        if (!eventOrRound || !scoresDate) {
            return;
        }
        const {
            event,
            golfers,
            teams,
            groups,
            competitions,
            mainCompetition
        } = getEventStaff(eventOrRound.id, eventData);
        const { golferScores, teamScores, distances } = getScores(eventOrRound.id, scoresDate);
        const hasScores = competitionHasScores(eventOrRound, competition, competitions, golferScores, teamScores, distances, golfers, teams);
        const allRounds = event.type === 'multiday' && isMainCompetitionScoring(competition.scoring, false);
        this.setState({
            editingCompetition: {
                isNew: false,
                eventOrRound,
                golfers,
                teams,
                groups,
                competition,
                competitions,
                mainCompetition,
                hasScores,
                allRounds
            }
        });
    }

    private doClearAll = () => {
        const eventOrRound = this.eventOrRound();
        if (eventOrRound) {
            clearScores(eventOrRound);
        }
    }

    private handleClearAll = () => {
        showAlert('This will remove all scores for all players. The action can not be undone.', [
            { title: 'Proceed', action: this.doClearAll },
            { title: 'Cancel' }
        ]);
    }

    private addCompetition = () => {
        const { eventData } = this.props;
        const { event } = eventData;
        const events = this.eventOrRounds();
        let allCourses = true;
        events.forEach(e => allCourses &&= !!e.course);
        if (allCourses) {
            const { eventOrRound, golfers, teams, groups, competitions, mainCompetition } = this.getStaff();
            this.setState({
                editingCompetition: {
                    eventOrRound, golfers, teams, groups, competitions, mainCompetition,
                    competition: createCompetition((eventOrRound ?? event).teamSize, competitions),
                    allRounds: event.type === 'multiday',
                    hasScores: false,
                    isNew: true
                }
            });
        } else {
            this.setState({ courseNotSelectedMessage: true });
        }
    }

    private addSideGame = () => {
        const events = this.eventOrRounds();
        let allCourses = true;
        events.forEach(e => allCourses &&= !!e.course);
        if (allCourses) {
            const { eventOrRound, golfers, teams, groups, competitions, mainCompetition } = this.getStaff();
            this.setState({
                editingCompetition: {
                    eventOrRound, golfers, teams, groups, competitions, mainCompetition,
                    competition: createSideGame(competitions),
                    allRounds: false,
                    hasScores: false,
                    isNew: true
                }
            });
        } else {
            this.setState({ courseNotSelectedMessage: true });
        }
    }

    private handleDeleteFromCompetitionEditDialog = (competition: Competition, allRounds: boolean) => {
        showAlert('Permanently delete competition?', [
            { title: 'Cancel' },
            {
                title: 'Delete',
                color: 'secondary',
                action: () => this.tryDeleteEditedCompetition(competition, allRounds)
            },
        ]);
    }

    private saveCompetitionsOrder = (eventOrRound: EventBase, reorderedMainCompetitions: Array<Competition>, reorderedSideGames: Array<Competition>) => {
        const { eventData } = this.props;
        withProgress(updateCompetitionPos(eventData, reorderedMainCompetitions, reorderedSideGames)
            .then(() => updateEventAppCompetition(eventOrRound, reorderedMainCompetitions))
            .then(() => this.changeReorderingOpenStatus(false))
            .catch(() => this.changeReorderingOpenStatus(false)));
    }

    private saveNewCompetitionsOrder = (eventOrRound: EventBase, reorderedMainCompetitions: Array<Competition>, reorderedSideGames: Array<Competition>) => {
        const { eventData } = this.props;
        const { golfers, teams, competitions, mainCompetition } = getEventStaff(eventOrRound.id, eventData);
        const currentAppMainCompetition = eventOrRound.appCompetition;
        if (currentAppMainCompetition && mainCompetition && isTeamFormat(mainCompetition.scoring)) {
            const appRoundStarted = appScoringStarted(golfers, teams, currentAppMainCompetition, mainCompetition);
            const oldAppMainCompIndex = competitions.findIndex(comp => currentAppMainCompetition.id === comp.id);
            const newAppMainCompIndex = reorderedMainCompetitions.findIndex(comp => currentAppMainCompetition.id === comp.id);
            if (appRoundStarted && oldAppMainCompIndex !== newAppMainCompIndex) {
                showAlert('Event round has been already started for some golfers. Applying new competitions order will change the scoring available for other Golf Pad app golfers.' +
                    ' Are you sure you want to save new competitions order?',
                    [{
                        title: 'Cancel'
                    }, {
                        title: 'Proceed',
                        color: 'secondary',
                        action: async () => this.saveCompetitionsOrder(eventOrRound, reorderedMainCompetitions, reorderedSideGames)
                    }]);
                return
            } else {
                return this.saveCompetitionsOrder(eventOrRound, reorderedMainCompetitions, reorderedSideGames);
            }
        } else {
            return this.saveCompetitionsOrder(eventOrRound, reorderedMainCompetitions, reorderedSideGames);
        }
    }

    private updateEventOrRoundScores = (eventOrRound?: EventBase) => this.scoreLoader.current?.updateEventOrRoundScores(eventOrRound);

    private deleteCompetition = async (competition: Competition, allRounds: boolean) => {
        const { eventData } = this.props;
        const { eventOrRound, competitions } = this.getStaff(competition.roundOrder);
        await deleteCompetition(eventOrRound, eventData, competition, allRounds);
        if (competition.id && isMainCompetitionScoring(competition.scoring, true)) {
            const otherCompetitions = competitions.filter(c => c.id !== competition.id);
            await updateEventAppCompetition(eventOrRound, otherCompetitions);
        }
        const details = 'Format: ' + competitionSummary(competition);
        elog(eventOrRound, 'Competition deleted', details, `Id: ${competition.id}`);
    }

    private deleteEditedCompetition = async (competition: Competition, allRounds: boolean) => {
        this.setState({ editingCompetition: undefined },
            async () => {
                await withProgress(this.deleteCompetition(competition, allRounds));
                this.setState({})
            }
        );
    }

    private tryDeleteEditedCompetition = (competition: Competition, allRounds: boolean) => {
        const { eventOrRound, golfers, teams, mainCompetition } = this.getStaff(competition.roundOrder);
        const appMainCompetition = eventOrRound.appCompetition;
        if (appMainCompetition?.id === competition.id && appScoringStarted(golfers, teams, appMainCompetition, mainCompetition)) {
            showAlert('Event round has been already started for some golfers. Deletion of this competition will cause round configuration problems in Golf Pad app.' +
                '  Are you sure you want to delete this competition?', [
                { title: 'Cancel' },
                {
                    title: 'Proceed',
                    color: 'secondary',
                    action: () => this.deleteEditedCompetition(competition, allRounds)
                },
            ]);
        } else {
            this.deleteEditedCompetition(competition, allRounds);
        }
    }

    private handleSaveFromCompetitionDialog = (competition: Competition, competitionOld: Competition, resetScores: boolean, resetDistance: boolean, changes: Map<string, string>, allRounds: boolean) => {
        const { eventData } = this.props;
        const { rounds, eventOrRound, mainCompetition } = this.getStaff(competition.roundOrder);
        const eventOrRounds = allRounds ? rounds : [eventOrRound];
        const gender = genderFromEvent(eventOrRound);
        const scoresDate = this.getScoresData();
        if (!scoresDate) {
            return;
        }
        if (!competition.flights) {
            competition.winners = competition.winners?.filter(w => w.flight === 0) || [];
        } else {
            range(1, competition.flights + 1).forEach(() => competition.winners = competition.winners?.filter(w => w.flight === 0) || []);
        }
        if (competition.scoring.mode === 'gross' || competition.scoring.mode === 'net') {
            competition.winners = competition.winners?.filter(w => w.mode === competition.scoring.mode) || [];
        }
        const isNew = !competition.id;
        const alertDone = () => {
            if (allRounds) {
                const competitionName = Scoring.scoringName(competition, eventOrRound.eventGender, competition.competitionGender);
                if (isNew) {
                    showAlert(<>“{competitionName}” has been added to all rounds.</>);
                } else {
                    showAlert(<>“{competitionName}” has been changed in all rounds.</>);
                }
            }
        }
        if (resetDistance && mainCompetition) {
            const { distances } = getScores(eventOrRound.id, scoresDate);
            const distance = clearDistance(eventOrRound, competitionOld, distances, getTee(eventOrRound, mainCompetition, gender));
            this.setState({ editingCompetition: undefined },
                () => withProgress(saveCompetitions(eventOrRounds, [competition], eventData, changes, resetScores, distance))
                    .then(alertDone));
        } else {
            this.setState({ editingCompetition: undefined },
                () => withProgress(saveCompetitions(eventOrRounds, [competition], eventData, changes, resetScores))
                    .then(alertDone));
        }
    }

    private saveLiveScores = (hideLiveScores: boolean | 'OFF' | 'ON' | 'VERIFIED' | undefined) => {
        const { event } = this.props.eventData;
        Backend.update(Backend.eventsDb, { id: event.id, hideLiveScores })
            .then(() => {
                this.setState({ openLiveScoresDialog: false });
                this.updateEventOrRoundScores();
            });
    }

    private saveWinners = (selectedWinners: Map<string, Array<WinnerInfo>>, competitions: Array<Competition>) => {
        const eventOrRound = this.eventOrRound();
        if (!eventOrRound) {
            return;
        }
        competitions.forEach(competition => competition.winners = selectedWinners.get(competition.id) || []);
        Backend.updateOrAddBatch(Backend.competitionsDb(eventOrRound.id), competitions)
            .then(() => this.setState({ editingWinners: undefined }));
    }

    private handleSelectLDGolfer = (contacts: Array<Contact>) => {
        const { editingLD } = this.state;
        if (!editingLD) {
            return;
        }
        const selectedGolfer = contacts.length > 0 ? contacts[0] : undefined;
        updateGolferDistance(editingLD.eventOrRound, editingLD.competition, editingLD.distances, editingLD.hole, 1, selectedGolfer)
            .then(() => this.setState({ editingLD: undefined }));
    }

    NoCompetitions = () => {
        const { classes } = this.props;
        const { competitionsMap } = this.props.eventData;
        const eventOrRound = this.eventOrRound();
        if (competitionsMap.size > 0 && eventOrRound && !eventOrRound.course) {
            return (
                <List disablePadding>
                    <Typography noWrap align="center">{this.CourseNotSelected()}</Typography>
                </List>
            );
        } else if (competitionsMap.size > 0) {
            return (
                <List disablePadding>
                    <Typography noWrap align="center">No competitions set up yet for this event.</Typography>
                    <Typography noWrap align="center">Click '<span className={classes.boldText}>Add Competition</span>'
                        to get started.</Typography>
                </List>
            );
        } else {
            return (
                <List disablePadding>
                    <Typography noWrap align="center">Loading...</Typography>
                </List>
            );
        }
    }

    CourseNotSelected = () => {
        const { event } = this.props.eventData;
        return <>
            Course is not yet selected. Please select course in
            <IconButton size="small" sx={{ paddingLeft: '4px' }}
                onClick={() => pushUrl(`/events/${event.id}`)}><SettingsIcon /></IconButton>
            Settings before adding a competition or side game.
        </>;
    }

    Competitions = () => {
        const { competitionsMap } = this.props.eventData;
        const { totalMode } = this.state;
        let allCompetitions = new Array<Competition>();
        if (totalMode) {
            competitionsMap.forEach(roundCompetition => allCompetitions = allCompetitions.concat(roundCompetition));
        } else {
            const eventOrRound = this.eventOrRound();
            if (eventOrRound) {
                allCompetitions = competitionsMap.get(eventOrRound.id) ?? [];
            }
        }
        const competitionsMain = sortCompetitions(allCompetitions.filter(competition => isMainCompetitionScoring(competition.scoring, false)), totalMode);
        const competitionsSideGames = sortCompetitions(allCompetitions.filter(competition => !isMainCompetitionScoring(competition.scoring, false)), totalMode);
        /*
                {sortCompetitions(allCompetitions, totalMode).map((c, idx) =>
                    <ListItem key={idx}>order_{c.order} round_{c.roundOrder} id_{c.id} - {c.scoring.format} {c.scoring.mode} {isFirstRoundCompetition(c)}={+isFirstRoundCompetition(c)} </ListItem>)}
        */
        return <>
            {competitionsMain.length === 0 && this.NoCompetitions()}
            {competitionsMain.map((competition, idx) => <this.Competition key={idx} competition={competition}
                allCompetitions={allCompetitions} />)}
            {competitionsSideGames.length > 0 && <ListTitle text={'Side Games'} />}
            {competitionsSideGames.map((competition, idx) => <this.Competition key={idx} competition={competition}
                allCompetitions={allCompetitions} />)}
        </>;
    }

    private showPlayerScoresInfo = (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const { classes } = this.props;
        showAlert(<>
            Golfers can post scores using the Golf Pad app on their phones. Scores submitted by golfers are highlighted
            in green.
            You can review and verify the scores by opening each golfer's or team's scorecard.
            Any scores entered by you (the organizer) will supersede golfer-submitted scores. <a
                href="https://support.golfpadgps.com/a/solutions/articles/6000245736" target="blank"
                className={classes.linkBlue}>Learn more</a>
        </>);
    }

    Competition = (params: { competition: Competition, allCompetitions: Array<Competition> }) => {
        const { competition, allCompetitions } = params;
        const { totalMode } = this.state;
        const {
            eventOrRound,
            golfers,
            teams,
            groups,
            competitions,
            mainCompetition
        } = this.getStaff(competition.roundOrder);
        const { classes } = this.props;
        const scoresDate = this.getScoresData();
        if (totalMode && hasFirstRoundCompetition(competition, allCompetitions)) {
            return null;
        }
        const { golferScores, teamScores, reportedScores, reportedTeamScores } = getScores(eventOrRound.id, scoresDate);
        let noTeesMessage = '';
        let allHandicapIndexesPresent = true;
        if (isMainCompetitionScoring(competition.scoring, true)) {
            if (!hasTees(eventOrRound, competition)) {
                noTeesMessage = 'Tee not selected';
            }
            if (competition.scoring.mode !== 'gross') {
                allHandicapIndexesPresent = Array.from(golfers.values()).every(contact => contact.handicapIndex != null);
            }
        }
        let payoutsExceedGolfers = '';
        if (isMainCompetitionScoring(competition.scoring, true) && isPayoutsExceedGolfers(competition, golfers, teams)) {
            payoutsExceedGolfers = isTeamFormat(competition.scoring) ? 'The number of payouts exceeds the number of teams' : 'The number of payouts exceeds the number of golfers';
        }
        let isReportedScores = false;
        const teamFormatExceptBB = isTeamFormatExceptBB(competition.scoring, mainCompetition);
        if (isMainCompetitionScoring(competition.scoring, true)) {
            if (teamFormatExceptBB) {
                reportedTeamScores.forEach(rts => isReportedScores ||= Scoring.markAsReported(teamScores.get(rts.id), rts));
            } else {
                reportedScores.forEach(rs => isReportedScores ||= Scoring.markAsReported(golferScores.get(rs.id), rs));
            }
        }
        const competitionName = Scoring.scoringName(competition, eventOrRound.eventGender, competition.competitionGender, true);
        const isGross = isGrossMode(competition.scoring.mode);
        const isSkins = isSkinsScoring(competition.scoring);
        const isNet = isNetMode(competition.scoring.mode);
        const tee = getTee(eventOrRound, mainCompetition ?? competition, genderFromEvent(eventOrRound));
        let skinsScores: ContactScoringState[] = [];
        let isFullScoresComp = false;
        let skinsMixed = false;
        if (isSkins) {
            skinsScores = Scoring.golferHoleScores(eventOrRound, competition, 0, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, mainCompetition);
            isFullScoresComp = isFullScoresCompetition(eventOrRound, competition, golferScores, teamScores, reportedScores, golfers, teams);
            skinsMixed = !!competitions.find(comp => comp.scoring.format === ScoringFormatTeams.best_ball);
        }
        const scoringTitle = (
            <Container>
                <InfoElement icon={<SettingsIcon color="action" />} rightIcon
                    onCLick={() => this.openCompetitionSettings(competition)}>
                    {totalMode && getRoundsCompetitions(competition, allCompetitions).length === 1 &&
                        <Typography variant="subtitle1" className={classes.listTitle}>
                            Round {competition.roundOrder}
                        </Typography>}
                    <Typography variant="subtitle1" className={classes.listTitle + ' ' + classes.uppercaseText}>
                        {competitionName}
                    </Typography>
                </InfoElement>
                {!!noTeesMessage && <InfoElement onCLick={() => this.setState({ courseDialogOpened: true })}
                    icon={<ReportProblemIcon htmlColor={AppColors.errorColor} />}
                    contentStyle={{ fontSize: 12, color: AppColors.errorColor }}>
                    {noTeesMessage}
                </InfoElement>}
                {!!payoutsExceedGolfers && <InfoElement icon={<ReportProblemIcon htmlColor={AppColors.errorColor} />}
                    contentStyle={{ fontSize: 12, color: AppColors.errorColor }}>
                    {payoutsExceedGolfers}
                </InfoElement>}
                {!allHandicapIndexesPresent &&
                    <InfoElement iconColor={AppColors.webWarning} contentStyle={{ fontSize: 12, color: `#6D6D71` }}>
                        Some golfers do not have a Handicap index. 0 handicap will be used for net scoring calculation.
                    </InfoElement>}
                <Typography className={classes.marginLeftAuto}>
                    {isSkins &&
                        <AppButton color='info' className={classes.marginLeftAuto} onClick={() => showAlertProps({
                            appBarLabel: 'Skins summary',
                            maxWidth: 'lg',
                            buttons: [{ title: 'Close' }],
                            content: <div className={classes.minWidth640}>
                                {isNet && <SkinsDetailedInfo
                                    competition={Scoring.netCompetition(competition)}
                                    event={eventOrRound}
                                    teams={teams}
                                    golfers={golfers}
                                    skinsMixed={skinsMixed}
                                    skins={getSkinsScores(eventOrRound, Scoring.netCompetition(competition), skinsScores, tee)}
                                    sameNameGolfersIdsSet={getSameNameGolfersIds(Array.from(golfers.values()))}
                                    withWinnings={isFullScoresComp && isNetPayouts(competition)} />}
                                {isNet && <Spacing backgroundColor={AppColors.white} />}
                                {isGross && <SkinsDetailedInfo
                                    competition={Scoring.grossCompetition(competition)}
                                    event={eventOrRound}
                                    teams={teams}
                                    golfers={golfers}
                                    skinsMixed={skinsMixed}
                                    skins={getSkinsScores(eventOrRound, Scoring.grossCompetition(competition), skinsScores, tee)}
                                    sameNameGolfersIdsSet={getSameNameGolfersIds(Array.from(golfers.values()))}
                                    withWinnings={isFullScoresComp && isGrossPayouts(competition)} />}
                                {isGross && <Spacing backgroundColor={AppColors.white} />}
                            </div>
                        })}>Skins summary</AppButton>}
                    {isSkins && isReportedScores && <span className={classes.marginLeft10} />}
                    {isReportedScores && <Typography variant="caption" className={classes.marginLeftAuto}><span
                        className={classes.legendIconGreen} />
                        <span style={{ display: 'inline', marginTop: '-5px', fontSize: '0.875rem' }}>
                            Golfer submitted scores
                            <IconButton size='small' onClick={this.showPlayerScoresInfo}>
                                <InfoIcon style={{ fontSize: '1em' }} />
                            </IconButton>
                        </span>
                    </Typography>}
                </Typography>
            </Container>
        );
        return <>
            <List disablePadding className={classes.listRootGrey}>
                {scoringTitle}
                {isDistanceScoring(competition.scoring) ?
                    this.DistanceCompetition({ competition }) :
                    this.ScoreCompetition({ competition, allCompetitions })}
                <Spacing height={8} />
            </List>
            <Spacing backgroundColor={AppColors.white} />
        </>;
    }

    ScoreCompetition = (params: { competition: Competition, allCompetitions: Array<Competition> }) => {
        const { competition, allCompetitions } = params;
        if (!competition.flights) {
            return this.ScoreCompetitionFlight(competition, 0, allCompetitions);
        } else {
            return <>
                {range(1, competition.flights + 1).map(flight => this.ScoreCompetitionFlight(competition, flight, allCompetitions))}
            </>;
        }
    }

    ScoreCompetitionFlight = (competition: Competition, flight: number, allCompetitions: Array<Competition>) => {
        const { eventOrRound, golfers, teams, mainCompetition } = this.getStaff(competition.roundOrder);
        const { totalMode } = this.state;
        const calculatedScoresMap = this.getScoresData()?.calculatedScoresMap;
        const roundsCompetitions = totalMode ? getRoundsCompetitions(competition, allCompetitions) : [competition];
        const scoreRounds = roundsCompetitions.length > 1 ? roundsCompetitions.map(rc => rc.roundOrder!) : undefined;
        const calculatedScores = calculatedScoresMap?.get(eventOrRound.id);
        if (!calculatedScoresMap || !calculatedScores || !mainCompetition || !calculatedScores.has(competition.id)) {
            return null;
        }
        const isNet = isNetMode(competition.scoring.mode);
        const roundsScores = totalMode ?
            Scoring.combineCompetitionScores(roundsCompetitions, flight, calculatedScoresMap) :
            Scoring.getCompetitionScores(roundsCompetitions, flight, calculatedScoresMap);
        competition.winners = getScoresWinners(competition, calculatedScores);
        const flightName = flight > 0 ?
            <Typography noWrap style={{ marginBottom: '0.35em', marginTop: '0.35em' }} variant="subtitle2">
                {getFlightName(flight, competition.flightsNaming).toUpperCase()}
            </Typography> : null;
        const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
        const isGross = isGrossMode(competition.scoring.mode);
        let skinsGrossMap = new Map<string, number>();
        let skinsNetMap = new Map<string, number>();
        let skinsGross: SkinsScoringState[] = [];
        let skinsNet: SkinsScoringState[] = [];
        const roundIdx = 0;//isRound(eventOrRound) ? eventOrRound.roundOrder - 1 : 0;
        const selectedContactState = roundsScores[roundIdx];
        if (isSkinsScoring(competition.scoring)) {
            if (isGross) {
                const grossCompetition = Scoring.grossCompetition(competition);
                skinsGross = getSkinsScores(eventOrRound, grossCompetition, selectedContactState, getTee(eventOrRound, mainCompetition, genderFromEvent(eventOrRound), undefined));
                processSkins(skinsGross, skinsGrossMap, grossCompetition, !isNet, selectedContactState);
            }
            if (isNet) {
                const netCompetition = Scoring.netCompetition(competition);
                skinsNet = getSkinsScores(eventOrRound, netCompetition, selectedContactState, getTee(eventOrRound, mainCompetition, genderFromEvent(eventOrRound), undefined));
                processSkins(skinsNet, skinsNetMap, netCompetition, true, selectedContactState, isGross ? skinsGrossMap! : undefined);
            }
        }
        const readonlyScores = competition.scoring.format === ScoringFormatSkins.skins_team && mainCompetition?.scoring.format === ScoringFormatTeams.best_ball;
        if (isTeamFormat(competition.scoring)) {
            return <List disablePadding key={competition.id + '-' + flight}>
                {flightName}
                <HeaderScore showEditIcon={!totalMode} scoring={competition.scoring} scoreRounds={scoreRounds} />
                {roundsScores[0].map((score, idx) => <RowTeamScore
                    key={idx}
                    golfers={golfers}
                    showEditIcon={!totalMode}
                    scoring={competition.scoring}
                    contactRoundsScores={Scoring.contactRoundsScoresOf(score.uniqueId, roundsScores)}
                    onClick={() => {
                        if (!totalMode) {
                            const editingScore = {
                                competition,
                                golfers,
                                teams, ...score,
                                skinsGross,
                                skinsNet,
                                readonlyScores,
                                mainCompetition
                            };
                            this.setState({ editingScore })
                        }
                    }}
                    sameNameGolfersIdsSet={sameNameGolfersIdsSet}
                    skinsGrossScore={skinsGrossMap && (skinsGrossMap.get(score.contactId) ?? 0)}
                    skinsNetScore={skinsNetMap && (skinsNetMap.get(score.contactId) ?? 0)}
                />)}
            </List>;
        } else {
            return <List disablePadding key={competition.id + '-' + flight}>
                {flightName}
                <HeaderScore showEditIcon={!totalMode} scoring={competition.scoring} scoreRounds={scoreRounds} />
                {roundsScores[0].map((score, idx) => <RowGolferScore
                    key={idx}
                    golfers={golfers}
                    showEditIcon={!totalMode}
                    scoring={competition.scoring}
                    contactRoundsScores={Scoring.contactRoundsScoresOf(score.uniqueId, roundsScores)}
                    onClick={() => {
                        if (!totalMode) {
                            const editingScore = {
                                competition,
                                golfers,
                                teams, ...score,
                                skinsGross,
                                skinsNet,
                                readonlyScores,
                                mainCompetition
                            };
                            this.setState({ editingScore });
                        }
                    }}
                    sameNameGolfersIdsSet={sameNameGolfersIdsSet}
                    skinsGrossScore={skinsGrossMap && (skinsGrossMap.get(score.contactId) ?? 0)}
                    skinsNetScore={skinsNetMap && (skinsNetMap.get(score.contactId) ?? 0)}
                />)}
            </List>;
        }
    }

    DistanceCompetition = (params: { competition: Competition }) => {
        const { competition } = params;
        if (competition.scoring.format === ScoringFormatDistance.longest_drive) {
            return this.LDCompetition({ competition });
        } else {
            return this.KPCompetition({ competition });
        }
    }

    LDCompetition = (params: { competition: Competition }) => {
        const { competition } = params;
        const { eventOrRound, golfers, mainCompetition } = this.getStaff(competition.roundOrder);
        const scoresDate = this.getScoresData();
        if (!mainCompetition || !scoresDate) {
            return null;
        }
        const { distances } = getScores(eventOrRound.id, scoresDate);
        const gender = genderFromEvent(eventOrRound);
        const distanceInfo = getDistanceScores(eventOrRound, competition, golfers, distances, getTee(eventOrRound, mainCompetition, gender, undefined));
        const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
        return <List disablePadding>
            <LDHeader />
            {distanceInfo.map(distanceProp =>
                <LDRow key={distanceProp.hole} {...distanceProp}
                    sameNameGolfersIdsSet={sameNameGolfersIdsSet}
                    onClick={() => this.setState({
                        editingLD: {
                            eventOrRound,
                            competition,
                            distances,
                            golfers,
                            hole: distanceProp.hole,
                            golfer: distanceProp.contacts ? distanceProp.contacts[0] : undefined
                        }
                    })}
                />)}
        </List>;
    }

    KPCompetition = (params: { competition: Competition }) => {
        const { units } = this.props.eventData;
        const { competition } = params;
        const { eventOrRound, golfers, mainCompetition } = this.getStaff(competition.roundOrder);
        const scoresDate = this.getScoresData();
        if (!mainCompetition || !scoresDate) {
            return null;
        }
        const { distances } = getScores(eventOrRound.id, scoresDate);
        const gender = genderFromEvent(eventOrRound);
        const distanceInfo = getDistanceScores(eventOrRound, competition, golfers, distances, getTee(eventOrRound, mainCompetition, gender, undefined));
        const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
        return <List disablePadding>
            <KPHeader />
            {distanceInfo.map(distanceProp => <KPRow key={distanceProp.hole} {...distanceProp} units={units}
                sameNameGolfersIdsSet={sameNameGolfersIdsSet}
                onClick={() => this.setState({
                    editingKP: {
                        eventOrRound,
                        competition,
                        distances,
                        golfers,
                        hole: distanceProp.hole,
                        distance: distanceProp.score ? distanceProp.score : undefined,
                        golfer: distanceProp.contacts ? distanceProp.contacts[0] : undefined
                    }
                })}
            />)}
        </List>;
    }

    render() {
        const { eventData, classes } = this.props;
        const { event, rounds, selectedRound, setSelectedRound, competitionsMap } = eventData;
        const calculatedScoresMap = this.getScoresData()?.calculatedScoresMap;
        const {
            editingCompetition, editingScore, editingLD, editingKP, openLiveScoresDialog, editingWinners,
            courseNotSelectedMessage, openCompetitionsReordering, totalMode, courseDialogOpened
        } = this.state;
        const multiday = event.type === 'multiday';
        const eventOrRound = this.eventOrRound();
        const competitionsToReorder = competitionsMap.get(eventOrRound?.id ?? '') ?? [];
        let ties = 0;
        const calculatedScores = calculatedScoresMap?.get(selectedRound?.id ?? event.id);
        if (!totalMode && calculatedScores) {
            calculatedScores.forEach(s => s.competitionWinnersGross.forEach(w => ties += w.length > 1 ? 1 : 0));
            calculatedScores.forEach(s => s.competitionWinnersNet.forEach(w => ties += w.length > 1 ? 1 : 0));
        }
        const reorderButton = <AppButton
            color="info"
            style={{ marginLeft: 'auto', marginRight: 0 }}
            disabled={competitionsToReorder.length === 0}
            onClick={() => this.changeReorderingOpenStatus(true)}>
            Reorder
        </AppButton>;
        return (
            <div>
                <ScoreLoader eventData={eventData} ref={this.scoreLoader} updated={() => this.setState({})} />
                <div className={classes.listRootGrey}>
                    <List disablePadding>
                        <LabeledField label={'Live scores'} itemClass={classes.listItem}
                            value={liveScoresName(event.hideLiveScores)}
                            edit={() => this.setState({ openLiveScoresDialog: true })} />
                    </List>
                    <Spacing height={8} />
                    {multiday && <>
                        <ButtonBar>
                            {rounds.map(round => <AppButton
                                key={round.roundOrder}
                                className={classes.eventRoundButton}
                                color={!totalMode && round.roundOrder === selectedRound?.roundOrder ? "primary" : "info"}
                                onClick={() => {
                                    this.setState({ totalMode: false });
                                    setSelectedRound(round);
                                }}>
                                Round {round.roundOrder}
                                <ButtonBadge
                                    invisible={!hasEmptyTees(round)}
                                    selected={!totalMode && round.roundOrder === selectedRound?.roundOrder} />
                            </AppButton>)}
                            {rounds.length > 0 && <AppButton
                                className={classes.eventRoundButton}
                                color={totalMode ? "primary" : "info"}
                                onClick={() => this.setState({ totalMode: true })}>
                                Total
                            </AppButton>}
                            {reorderButton}
                        </ButtonBar>
                    </>}
                    <ButtonBar>
                        <AppButton color="secondary" onClick={this.addCompetition}>Add Competition</AppButton>
                        <AppButton color="secondary" onClick={this.addSideGame} disabled={totalMode}>Add Side
                            Game</AppButton>
                        <AppButton color="info" onClick={this.handleExportClick}>
                            Export<DropDownArrowIcon className={classes.rightButtonIcon} />
                        </AppButton>
                        {this.ExportMenu()}
                        <AppButton color="info" onClick={this.handleBreakTies} disabled={ties === 0}>Break
                            Ties</AppButton>
                        <AppButton color="info" onClick={this.handleClearAll} disabled={totalMode}>Clear All</AppButton>
                        {!multiday && reorderButton}
                    </ButtonBar>
                </div>
                <this.Competitions />
                {editingLD && <SelectGolfersDialog
                    competition={editingLD.competition}
                    golfers={editingLD.golfers}
                    label={'Select winner' + (editingLD.competition.roundOrder ? ` - Round ${editingLD.competition.roundOrder}` : '')}
                    selectMode={'single-or-none'}
                    handleSelect={this.handleSelectLDGolfer}
                    handleCancel={() => this.setState({ editingLD: undefined })}
                    selectedGolferIds={editingLD.golfer ? [editingLD.golfer.id] : []} />}
                {editingKP && <AddResultDialog
                    open
                    golfers={editingKP.golfers}
                    competition={editingKP.competition}
                    eventOrRound={editingKP.eventOrRound}
                    distance={editingKP.distance}
                    distances={editingKP.distances}
                    hole={editingKP.hole}
                    initialGolfer={editingKP.golfer}
                    close={() => this.setState({ editingKP: undefined })} />}
                {editingCompetition && <CompetitionSettingsDialog
                    open
                    allRounds={editingCompetition.allRounds}
                    event={event}
                    eventOrRound={editingCompetition.eventOrRound}
                    teams={editingCompetition.teams}
                    groups={editingCompetition.groups}
                    golfers={editingCompetition.golfers}
                    hasScores={editingCompetition.hasScores}
                    competitions={editingCompetition.competitions}
                    competition={editingCompetition.competition}
                    isNewCompetition={editingCompetition.isNew}
                    mainCompetition={editingCompetition.mainCompetition}
                    handleSave={this.handleSaveFromCompetitionDialog}
                    handleClose={this.handleCloseCompetitionEditDialog}
                    handleDelete={this.handleDeleteFromCompetitionEditDialog} />}
                {courseNotSelectedMessage && <MessageDialog
                    open
                    title="Course not selected"
                    onClose={() => this.setState({ courseNotSelectedMessage: undefined })}
                    content={this.CourseNotSelected()} />}
                {editingScore && <EditScoreDialog
                    open
                    {...editingScore}
                    eventRoot={event}
                    rounds={rounds}
                    readonly={editingScore.readonlyScores}
                    mainCompetition={editingScore.mainCompetition}
                    close={() => this.setState({ editingScore: undefined })} />}
                {openLiveScoresDialog && <SelectLiveScoresDialog
                    open
                    save={this.saveLiveScores}
                    close={() => this.setState({ openLiveScoresDialog: false })}
                    hideLiveScores={event.hideLiveScores} />}
                {calculatedScores && editingWinners && <SelectWinnersDialog
                    open
                    eventOrRound={editingWinners.eventOrRound}
                    competitions={editingWinners.competitions}
                    calculatedScores={calculatedScores}
                    save={selectedWinners => this.saveWinners(selectedWinners, editingWinners.competitions)}
                    close={() => this.setState({ editingWinners: undefined })} />}
                {openCompetitionsReordering && eventOrRound && <ReorderCompetitionsDialog
                    open
                    competitions={competitionsToReorder}
                    handleClose={() => this.changeReorderingOpenStatus(false)}
                    handleSave={(reorderedMainCompetitions, reorderedSideGames) => this.saveNewCompetitionsOrder(eventOrRound, reorderedMainCompetitions, reorderedSideGames)} />}
                {courseDialogOpened && eventOrRound && <CourseSelectionDialog
                    open
                    eventOrRound={eventOrRound}
                    eventData={eventData}
                    onClose={() => this.setState({ courseDialogOpened: false })}
                    preselectedHolesType={eventOrRound.holesType}
                />}
            </div>
        );
    }
}

export default withStyles(styles)(ScoreList);
