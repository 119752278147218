import * as React from 'react';
import { FirebaseDocComponent } from '../common/WithData';
import { ErrorPage } from '../main/ErrorBoundary';
import Standings from './Standings';
import { Event, Portal } from '../types/EventTypes';
import { showAlert } from "../redux/ReduxConfig";
import { WithStyles } from '@mui/styles';
import { useAppStyles, styles } from '../styles';
import * as Backend from "../util/firebase";
import * as Public from './Public';
import { Urls } from "../util/config";
import { isEventOver } from "../event/Event";
import { useParams } from 'react-router-dom';

type LeaderboardInfoProps = { eventId: string } & WithStyles<typeof styles>;

interface State {
    event?: Event;
    portal?: Portal;
    notificationShown: boolean;
}

class LeaderboardInfoImpl extends React.Component<LeaderboardInfoProps, State> {

    private readonly supportArticleLink: JSX.Element =
        <a className={this.props.classes.linkBlue}
            href={Urls.whatIsLLLink}
            rel='noopener noreferrer'
        >Learn more</a>;

    private readonly landingPageLink: JSX.Element =
        <a className={this.props.classes.linkBlue}
            href={Urls.landingPageLink}
            rel='noopener noreferrer'
        >Learn more</a>;

    public state: State = {
        notificationShown: false,
    };

    private setEvent = (event: Event) => this.setState({ event });
    private setPortal = (portal: Portal) => this.setState({ portal });

    render() {
        const { classes, eventId } = this.props;
        const { event, portal, notificationShown } = this.state;
        let content;
        if (!event?.exists) {
            const err = 'Event not found: ' + event?.id;
            content = <ErrorPage error={err} errorInfo={""} noheader />;
        } else {
            const startingAlertString: string = isEventOver(event) ?
                'This leaderboard has been completed.'
                : 'Trying to join the leaderboard? Please download the Golf Pad app on your phone and open the link there.';
            const alertFragment = <>
                <span className={classes.spanStyle}>{startingAlertString}</span>
                <br />
                <br />
                <span className={classes.spanStyle}>{this.supportArticleLink}&nbsp;about Live Leaderboards in Golf Pad.</span>
            </>;
            if (!notificationShown && !event.deleted) {
                showAlert(alertFragment, [{ title: 'CLOSE', color: 'primary', action: () => this.setState({ notificationShown: true }) },]);
            }
            if (portal && (event.type === 'leaderboard' || event.leaderboard)) {
                content = event.deleted ?
                    <div className={classes.midScreenRoot}>
                        <h1>The leaderboard you are trying to access has been deleted.</h1>
                        <br />
                        <p>Click here to {this.landingPageLink} about Golf Pad Live Leaderboards.</p>
                    </div> :
                    <>
                        <Public.Header event={event} portal={portal} tabs={['standings',]} />
                        <Standings event={event} rounds={[]} competitionsMap={new Map()} loadedRounds={0} loadedCompetitions={0} setSelectedRound={() => { }} />
                    </>;
            }
        }
        return <>
            <div className={classes.pubRoot}>{content}</div>
            <FirebaseDocComponent
                docReference={Backend.eventFields(eventId)}
                onDoc={doc => this.setEvent(Backend.fromEntity<Event>(doc))} />
            <FirebaseDocComponent
                docReference={Backend.portalFields(eventId)}
                onDoc={doc => this.setPortal(Backend.fromEntity<Portal>(doc))} />
        </>;
    }
}

function eventProvider() {
    const { id } = useParams<'id'>();
    const classes = useAppStyles();
    return id ? <LeaderboardInfoImpl classes={classes} eventId={id} /> : null;
    //return id ? withDataItemFromUrl<Event, {}>(eventsDb, id)(withStyles(styles)(LeaderboardInfoImpl)) : null;
}

const LeaderboardInfo = eventProvider;

export default LeaderboardInfo;
//export default withStyles(styles)(withDataItemFromUrl<Event, {}>(eventsDb)(LeaderboardInfo));
