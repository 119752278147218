import * as React from 'react';
import { DialogContent, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { SMMobileDialog } from 'src/common/dialog/MobileDialog';
import DialogAppBar from 'src/common/dialog/DialogAppBar';
import AppButton from 'src/common/components/AppButton';
import GolfpadEventsLogo from 'src/common/Logo';
import { FlexCenter } from 'src/common/Misc';
import { AppColors } from 'src/main/Theme';
import { Urls } from 'src/util/config';

const dlgStyles = (_: Theme) => {
    return {
        textFirst: {
            backdropFilter: 'blur(5px)',
            borderRadius: '12px',
            lineHeight: '200%',
            fontWeight: 500,
            fontSize: 24
        },
        textSecond: {
            backdropFilter: 'blur(5px)',
            borderRadius: '10px',
            lineHeight: '200%',
            fontWeight: 500,
            fontSize: 20,
            paddingRight: '4px'
        },
        text: {
            backdropFilter: 'blur(5px)',
            borderRadius: '8px',
            lineHeight: '150%',
            fontWeight: 500,
            fontSize: 16
        },
        learn: {
            backdropFilter: 'blur(5px)',
            borderRadius: '8px',
            lineHeight: '150%',
            fontWeight: 500,
            fontSize: 16,
            color: AppColors.webBlue400
        },
        ok: { minWidth: '70px', margin: '16px' }
    }
};

const useStyles = makeStyles(dlgStyles);

const showWidth = 300;
const showHeight = 100;
const showCount = 200;

class Particle {
    public x = 0;
    public y = 0;
    public vx = 0;
    public vy = 0;
    public r = 0;
    public color = '';
    constructor() {
        this.initParticle(showWidth / 2, showHeight / 2);
    }
    public initParticle(x: number, y: number) {
        this.x = x;
        this.y = y;
        const d = Math.random() * 5;
        const a = Math.random() * 2 * Math.PI;
        this.vx = d * Math.sin(a);
        this.vy = d * Math.cos(a);
        this.r = 0.5 + Math.random() * 1;
        this.color = Particle.randomColor();
    }
    public step() {
        this.x += this.vx;
        this.y += this.vy;
    }
    public static random(): Particle {
        return new Particle();
    }
    public static randomColor(): string {
        return `rgb(${(Math.random() * 255).toFixed(0)}, ${(Math.random() * 255).toFixed(0)}, ${(Math.random() * 255).toFixed(0)})`;
    }
    public static step(particles: Array<Particle>) {
        particles.forEach(p => p.step());
    }
    public static initParticles(particles: Array<Particle>, x: number, y: number) {
        particles.forEach(p => p.initParticle(x, y));
    }
}

function Particles() {
    const [stepCount, setStepCount] = React.useState(0);
    const [particles] = React.useState(Array.from(Array(showCount).keys()).map(_ => Particle.random()));
    React.useEffect(() => {
        const stepTimer = setInterval(() => {
            let newStepCount = stepCount + 1;
            if (newStepCount >= 3 * 30) {
                newStepCount = 0;
                Particle.initParticles(particles, Math.random() * showWidth, Math.random() * showHeight);
            } else {
                Particle.step(particles);
            }
            setStepCount(newStepCount);
        }, 1000 / 30);
        return () => clearInterval(stepTimer);
    }, [stepCount]);
    return (
        <span style={{ position: 'absolute', left: 0, top: 48, width: '100%', height: '80%' }}>
            <svg width="100%" height="100%" viewBox={`0 0 ${showWidth} ${showHeight}`} fill="none">
                {particles.map((p, idx) => <circle key={idx} cx={p.x} cy={p.y} r={p.r} fill={p.color} />)}
            </svg>
        </span>
    );
}

export function EventsProCongratDialog({ onClose }: { onClose: VoidFunction }) {
    const classes = useStyles();
    return (
        <SMMobileDialog open fullWidth onClose={onClose} maxWidth={'md'}>
            <DialogAppBar bar close={onClose} label={'Events Pro'} toolbarBarStyle={{ padding: 0, marginLeft: 16, marginRight: 16 }} />
            <DialogContent>
                <Particles />
                <FlexCenter>
                    <Typography className={classes.textFirst}>Congratulations on upgrading</Typography>
                </FlexCenter>
                <FlexCenter>
                    <Typography className={classes.textSecond}>to</Typography>
                    <GolfpadEventsLogo hasPro inv />
                </FlexCenter>
                <FlexCenter>
                    <Typography className={classes.text}>All the platform features are now available to you!</Typography>
                </FlexCenter>
                <FlexCenter>
                    <Typography className={classes.text}>Explore all Golf Pad Events Pro features —</Typography>
                </FlexCenter>
                <FlexCenter>
                    <a className={classes.learn} href={Urls.learnMoreEventsPro} target="_blank" rel='noopener noreferrer'>Learn more</a>
                </FlexCenter>
                <FlexCenter>
                    <AppButton className={classes.ok} color="secondary" onClick={onClose}>Ok</AppButton>
                </FlexCenter>
            </DialogContent>
        </SMMobileDialog>
    );
}
