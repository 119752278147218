import * as React from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DialogActions, Radio, Typography, Grid, DialogContent } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { withProgress } from 'src/util/ProgressPromise';
import { Course, Facility, SimpleCourse, Tee, HolesType, EventData, getCourseName, HOLES_18, EventBase } from 'src/types/EventTypes';
import { updateEventCourse } from 'src/event/Event';
import { XSMobileDialog } from 'src/common/dialog/MobileDialog';
import DialogAppBar from 'src/common/dialog/DialogAppBar';
import AppButton from 'src/common/components/AppButton';
import EditTeesListDialog from 'src/event/tabs/common/EditTeesListDialog';
import { useAppStyles, styles } from 'src/styles';
import { CourseSetupPage } from 'src/event/list/CourseSetupPage';
import { Spacing } from 'src/common/Misc';

interface CourseViewProps {
    course: SimpleCourse;
    setAsOut: (course: SimpleCourse) => void;
    setAsIn: (course: SimpleCourse) => void;
    outNine: boolean;
    inNine: boolean;
}

export const CourseViewHeaderLong = withStyles(styles)((props: WithStyles<typeof styles>) => {
    return (
        <Grid container>
            <Grid item xs={2} className={props.classes.childrenCenteredLeft}><Typography>FRONT</Typography></Grid>
            <Grid item xs={2} className={props.classes.childrenCenteredLeft}><Typography>BACK</Typography></Grid>
            <Grid item xs={2} className={props.classes.childrenCenteredLeft}><Typography /></Grid>
        </Grid>
    );
});

export const CourseViewHeaderShort = withStyles(styles)((props: WithStyles<typeof styles>) => {
    return (
        <Grid container>
            <Grid item xs={4} className={props.classes.childrenCenteredLeft}><Typography>PLAY THIS NINE</Typography></Grid>
            <Grid item xs={2} className={props.classes.childrenCenteredLeft}><Typography /></Grid>
        </Grid>
    );
});

export const CourseViewLong = (props: CourseViewProps) => {
    const classes = useAppStyles();
    return (
        <Grid container>
            <Grid item xs={2} className={classes.childrenCenteredLeft + ' ' + classes.clickable}
                onClick={() => props.setAsOut(props.course)}>
                <Radio color="secondary" checked={props.outNine} />
            </Grid>
            <Grid item xs={2} className={classes.childrenCenteredLeft + ' ' + classes.clickable}
                onClick={() => props.setAsIn(props.course)}>
                <Radio color="secondary" checked={props.inNine} />
            </Grid>
            <Grid item xs={2} className={classes.childrenCenteredLeft + ' ' + classes.clickable}
                onClick={() => { props.setAsOut(props.course); props.setAsIn(props.course); }}>
                <Typography style={{ marginLeft: -30 }}>{props.course.name}</Typography>
            </Grid>
        </Grid>
    );
};

export const CourseViewShort = (props: CourseViewProps) => {
    const classes = useAppStyles();
    return (
        <Grid container>
            <Grid item xs={1} className={classes.childrenCenteredLeft + ' ' + classes.clickable}
                onClick={() => props.setAsIn(props.course)}>
                <Radio color="secondary" checked={props.inNine} />
            </Grid>
            <Grid item xs={2} className={classes.childrenCenteredLeft + ' ' + classes.clickable}
                onClick={() => props.setAsIn(props.course)}>
                <Typography>{props.course.name}</Typography>
            </Grid>
        </Grid>
    );
};

interface CourseSelectionDialogProps extends DialogProps {
    eventOrRound: EventBase;
    eventData: EventData;
    preselectedFacility?: Facility;
    preselectedHolesType?: HolesType;
}

interface State {
    course?: Course;
    holesType?: HolesType;
    teeMen?: Tee;
    teeWomen?: Tee;
    lastCourseName: string;
    editingTees?: boolean;
}

type Props = CourseSelectionDialogProps & WithStyles<typeof styles>;

class CourseSelectionDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { eventOrRound, preselectedHolesType } = this.props;
        const course = eventOrRound.course;
        const teeMen = eventOrRound.teeMen;
        const teeWomen = eventOrRound.teeWomen;
        const lastCourseName = getCourseName(course);
        const holesType = preselectedHolesType ?? eventOrRound.holesType ?? HOLES_18;
        this.state = {
            course,
            holesType,
            teeMen,
            teeWomen,
            lastCourseName
        };
    }

    private readonly courseSetupPageRef: React.RefObject<CourseSetupPage> = React.createRef();

    public roundCourseIsMissing = () => this.courseSetupPageRef.current?.courseIsMissing();
    public closePopups = () => this.courseSetupPageRef.current?.closePopups();

    private handleEditScorecard = () => this.setState({ editingTees: true });
    private handleCloseEditScorecard = () => this.setState({ editingTees: false });
    private courseUpdated = (course?: Course, holesType?: HolesType, teeMen?: Tee, teeWomen?: Tee) =>
        this.setState({ course, holesType, teeMen, teeWomen });

    private handleSave = () => {
        const { eventOrRound } = this.props;
        const { course } = this.state;
        if (course) {
            const newName = getCourseName(course);
            const curName = getCourseName(eventOrRound.course);
            const clearTee = curName !== newName;
            this.save(clearTee, course);
        } else {
            this.save(false);
        }
    }

    private save = (clearTee: boolean, course?: Course) => {
        const { eventOrRound, eventData } = this.props;
        const { holesType, teeMen, teeWomen } = this.state;
        withProgress(updateEventCourse(eventOrRound, eventData, clearTee, course, holesType, teeMen, teeWomen))
            .then(this.handleClose);
    }

    private handleClose = () => {
        if (this.closePopups()) {
            return;
        }
        const close = this.props.onClose;
        if (close) {
            close({} as React.SyntheticEvent<any>, 'escapeKeyDown');
        }
    }

    render() {
        const { classes, eventOrRound } = this.props;
        const { course, editingTees } = this.state;
        const saveDisabled = this.roundCourseIsMissing();
        return <>
            <XSMobileDialog open={this.props.open} onClose={this.handleClose} maxWidth="sm">
                <DialogAppBar label="Course" close={this.handleClose} />
                <DialogContent>
                    <CourseSetupPage
                        visible
                        coursesActivated
                        eventOrRound={eventOrRound}
                        course={eventOrRound.course}
                        holesType={eventOrRound.holesType}
                        defaultMenTee={eventOrRound.teeMen?.id}
                        defaultWomenTee={eventOrRound.teeWomen?.id}
                        courseUpdated={this.courseUpdated}
                        ref={this.courseSetupPageRef}
                    />
                    <Spacing />
                    <AppButton color="info" style={{ width: 122 }} onClick={this.handleEditScorecard} disabled={saveDisabled}>
                        <EditIcon sx={{ width: '.7em' }} /> &nbsp; View/edit
                    </AppButton>
                </DialogContent>
                <DialogActions>
                    <AppButton color="info" className={classes.iconButton} onClick={this.handleClose}>Cancel</AppButton>
                    <AppButton color="secondary" className={classes.iconButton} onClick={this.handleSave} disabled={saveDisabled}>Save</AppButton>
                </DialogActions>
            </XSMobileDialog>
            {editingTees && course && <EditTeesListDialog course={course} eventOrRound={eventOrRound} close={this.handleCloseEditScorecard} />}
        </>;
    }
}

export default withStyles(styles)(CourseSelectionDialog);
