import * as React from 'react';
import { Func, array } from '../../../util/utility';
import * as Backend from '../../../util/firebase';
import { EventBase, EventData, Competition, ReportedScore, ScoresData, Score, Distance, MAX_HOLES, CalculatedGrossNetWinnersMapsByFlight, rollEvents, getEventStaff } from '../../../types/EventTypes';
import { FirebaseDataComponent } from '../../../common/WithData';
import * as Scoring from '../../../scoring/scoring';
import { getScores } from './ExportUtils';

interface Props {
    eventData: EventData;
    updated: Func;
}

export default class ScoreLoader extends React.Component<Props, ScoresData> {
    state: ScoresData = {
        distancesMap: new Map(),
        golferScoresMap: new Map(),
        teamScoresMap: new Map(),
        reportedScoresMap: new Map(),
        reportedTeamScoresMap: new Map(),
        calculatedScoresMap: new Map(),
    };

    componentDidUpdate(prevProps: Readonly<{ eventData: EventData; }>) {
        const { golfersMap, teamsMap, groupsMap, competitionsMap } = this.props.eventData;
        if (golfersMap.size !== prevProps.eventData.golfersMap.size ||
            teamsMap.size !== prevProps.eventData.teamsMap.size ||
            groupsMap.size !== prevProps.eventData.groupsMap.size ||
            competitionsMap.size !== prevProps.eventData.competitionsMap.size) {
            this.updateEventOrRoundScores();
        }
    }

    private onGolferScores = (eventOrRound: EventBase, golferScores: Map<string, Score>) => {
        const { golferScoresMap } = this.state;
        golferScoresMap.set(eventOrRound.id, golferScores);
        this.setState({ golferScoresMap }, () => this.updateEventOrRoundScores(eventOrRound));
    }

    private onTeamScores = (eventOrRound: EventBase, teamScores: Map<string, Score>) => {
        const { teamScoresMap } = this.state;
        teamScoresMap.set(eventOrRound.id, teamScores);
        this.setState({ teamScoresMap }, () => this.updateEventOrRoundScores(eventOrRound));
    }

    private onReportedGolferScores = (eventOrRound: EventBase, reportedScores: Map<string, ReportedScore>) => {
        const { reportedScoresMap } = this.state;
        reportedScoresMap.set(eventOrRound.id, reportedScores);
        this.setState({ reportedScoresMap }, () => this.updateEventOrRoundScores(eventOrRound));
    }

    private onReportedTeamScores = (eventOrRound: EventBase, reportedTeamScores: Map<string, ReportedScore>) => {
        const { reportedTeamScoresMap } = this.state;
        reportedTeamScoresMap.set(eventOrRound.id, reportedTeamScores);
        this.setState({ reportedTeamScoresMap }, () => this.updateEventOrRoundScores(eventOrRound));
    }

    private onDistances = (eventOrRound: EventBase, distances: Map<string, Distance>) => {
        const { distancesMap } = this.state;
        distances.forEach(distance => distance.lengths ||= array(MAX_HOLES, 0));
        distancesMap.set(eventOrRound.id, distances);
        this.setState({ distancesMap });
    }

    public updateEventOrRoundScores = (eventOrRound?: EventBase) => {
        const { eventData, updated } = this.props;
        const { event, rounds, competitionsMap } = eventData;
        const { calculatedScoresMap } = this.state;
        if (eventOrRound) {
            const competitions = competitionsMap.get(eventOrRound.id);
            if (competitions) {
                this.updateScores(eventOrRound, competitions, calculatedScoresMap);
            }
        } else {
            rollEvents(event, rounds).forEach(e => this.updateScores(e, competitionsMap.get(e.id) ?? [], calculatedScoresMap));
        }
        this.setState({ calculatedScoresMap }, updated);
    }

    private updateScores = (eventOrRound: EventBase, competitions: Array<Competition>, calculatedScoresMap: Map<string, Map<string, CalculatedGrossNetWinnersMapsByFlight>>) => {
        const { eventData } = this.props;
        const { teams, golfers, groups, mainCompetition } = getEventStaff(eventOrRound.id, eventData);
        const { golferScores, teamScores, reportedScores, reportedTeamScores } = getScores(eventOrRound.id, this.state);
        let calculatedScores = calculatedScoresMap.get(eventOrRound.id) ?? new Map<string, CalculatedGrossNetWinnersMapsByFlight>();
        calculatedScoresMap.set(eventOrRound.id, calculatedScores);
        const competitionScores = Scoring.getGolferScores(eventOrRound, competitions, golfers, teams, groups, golferScores, teamScores, reportedScores, reportedTeamScores, mainCompetition, false);
        competitionScores.forEach((competitionScore, competitionId) => calculatedScores.set(competitionId, competitionScore));
    }

    render() {
        const { eventData } = this.props;
        const { event, rounds } = eventData;
        const events = rollEvents(event, rounds);
        return <>
            {events.map(eventOrRound => <FirebaseDataComponent<Score>
                key={eventOrRound.id}
                name="golferScores"
                queryPath={eventOrRound.id}
                query={Backend.golferScoresDb(eventOrRound.id)}
                onMap={scores => this.onGolferScores(eventOrRound, scores)} />)}
            {events.map(eventOrRound => <FirebaseDataComponent<ReportedScore>
                key={eventOrRound.id}
                name="reportedGolferScores"
                queryPath={eventOrRound.id}
                query={Backend.reportedGolferScoresDb(eventOrRound.id)}
                onMap={scores => this.onReportedGolferScores(eventOrRound, scores)} />)}
            {events.map(eventOrRound => <FirebaseDataComponent<Score>
                key={eventOrRound.id}
                name="teamScores"
                queryPath={eventOrRound.id}
                query={Backend.golferTeamScoresDb(eventOrRound.id)}
                onMap={scores => this.onTeamScores(eventOrRound, scores)} />)}
            {events.map(eventOrRound => <FirebaseDataComponent<ReportedScore>
                key={eventOrRound.id}
                name="reportedTeamScores"
                queryPath={eventOrRound.id}
                query={Backend.reportedTeamScoresDb(eventOrRound.id)}
                onMap={scores => this.onReportedTeamScores(eventOrRound, scores)} />)}
            {events.map(eventOrRound => <FirebaseDataComponent<Distance>
                key={eventOrRound.id}
                name="distances"
                queryPath={eventOrRound.id}
                query={Backend.golferDistancesDb(eventOrRound.id)}
                onMap={distances => this.onDistances(eventOrRound, distances)} />)}
        </>;
    }
}
