import * as React from 'react';

export function GolfPadProgress({ size }: { size?: string }) {
    return (
        <span style={{ width: size ?? '120px', height: size ?? '120px', display: 'inline-block' }}>
            <svg width="100%" height="100%" viewBox="0 0 60 60" style={{ display: 'block' }}>
                <svg viewBox="20 20 40 40">
                    <circle
                        cx="40" cy="40" r="18"
                        fill="rgba(255,255,255,0.8)"
                        strokeWidth="1.2"
                        stroke="#729fcf"
                        strokeLinecap="round"
                        strokeDasharray="0,200"
                        strokeDashoffset="0"
                    >
                        <animate
                            dur="3s"
                            attributeName="stroke-dasharray"
                            values="1,200;400,200"
                            repeatCount="indefinite" />
                        <animate
                            dur="3s"
                            attributeName="stroke-dashoffset"
                            values="0;-113"
                            repeatCount="indefinite" />
                        <animateTransform
                            dur="3s"
                            attributeName="transform"
                            type="rotate"
                            from="0 40 40"
                            to="360 40 40"
                            repeatCount="indefinite" />
                    </circle>
                    <circle
                        cx="40" cy="40" r="19"
                        fill="none"
                        strokeWidth="0.7"
                        stroke="#1ce700"
                        strokeLinecap="round"
                        strokeDasharray="1,5"
                        strokeDashoffset="0"
                    >
                        <animate
                            dur="4s"
                            attributeName="stroke-dasharray"
                            values="0,58;58,0;0,58"
                            repeatCount="indefinite" />
                        <animateTransform
                            dur="4s"
                            attributeName="transform"
                            type="rotate"
                            from="0,40,40"
                            to="-360,40,40"
                            repeatCount="indefinite" />
                    </circle>
                </svg>
                <svg x="22" y="22" width="16" height="16" viewBox="0 0 150 150" fill="none" stroke="white">
                    <path
                        fill="#025987"
                        strokeWidth="4"
                        d="M138.021 28.125H113.194V18.3594C113.194 15.1855 110.569 12.5 107.465 12.5H42.5347C39.1927 12.5 36.8056 15.1855 36.8056 18.3594V28.125H11.9792C8.63715 28.125 6.25 30.8105 6.25 33.9844V47.6562C6.25 56.4453 11.5017 65.4785 20.8116 72.3144C28.4505 77.9297 37.5217 81.3477 47.0703 82.5684C54.7092 95.2637 63.5417 100.391 63.5417 100.391V117.969H52.0833C43.4896 117.969 36.8056 123.096 36.8056 131.641V134.57C36.8056 136.279 37.9991 137.5 39.6701 137.5H110.33C111.762 137.5 113.194 136.279 113.194 134.57V131.641C113.194 123.096 106.272 117.969 97.9167 117.969H86.4583V100.391C86.4583 100.391 95.0521 95.2637 102.691 82.5684C112.24 81.3477 121.311 77.9297 128.95 72.3144C138.26 65.4785 143.75 56.4453 143.75 47.6562V33.9844C143.75 30.8105 141.124 28.125 138.021 28.125ZM29.8828 59.6191C23.9149 55.4687 21.5278 50.5859 21.5278 47.6562V43.75H36.8056C37.0443 51.8066 38.2378 58.8867 39.9089 64.9902C36.0894 63.5254 32.7474 61.8164 29.8828 59.6191ZM128.472 47.6562C128.472 51.8066 124.175 56.6895 119.878 59.6191C117.014 61.8164 113.672 63.5254 109.852 64.9902C111.523 58.8867 112.717 51.8066 112.956 43.75H128.472V47.6562Z"
                    />
                </svg>
            </svg>
        </span>
    );
}
